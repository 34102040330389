import React from 'react'
import { FormattedMessage } from 'react-intl'

interface StatisticCardProps {
  category?: string
  statisticFigure: number
  statisticUnit: string
  strapline: string
  content?: React.ReactNode
  footnote?: string
  greyBorder?: boolean
  footnoteContent?: React.ReactNode
}

export const StatisticCard: React.FC<StatisticCardProps> = ({
  category,
  statisticFigure,
  statisticUnit,
  strapline,
  content,
  footnote,
  greyBorder,
  footnoteContent,
}) => {
  return (
    <div
      className={`max-w-card-width-mobile sm:max-w-card-width-desktop mx-auto pt-6 px-6 pb-8 bg-white rounded-2xl ${
        greyBorder && 'border-gray-200 border-2'
      }`}
    >
      {category && (
        <div className="py-2 px-6 bg-highlight-surface rounded-full inline-block">
          <p className="text-lg">
            <FormattedMessage id={category} />
          </p>
        </div>
      )}
      <p className="mt-4 text-display-2xl font-light">
        {statisticFigure}
        <span className="text-2xl leading-relaxed">{statisticUnit}</span>
      </p>
      <p className="text-xl leading-relaxed font-light">
        <FormattedMessage id={strapline} />
      </p>
      {content && <div className="mt-6">{content}</div>}
      {footnote && (
        <p className="text-xs leading-4 light mt-8">
          <FormattedMessage id={footnote} />
        </p>
      )}
      {footnoteContent && (
        <div className="mt-8">
          <hr className="solid" />
          <div className="mt-6">{footnoteContent}</div>
        </div>
      )}
    </div>
  )
}
