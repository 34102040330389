import React, { useEffect, useState } from 'react'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { InfoOnlyScreen } from 'components/screen-templates/InfoOnlyScreen'

import { Quizzes } from 'redux/quiz/types'
import { FormattedMessage } from 'react-intl'
import { ContextAwareText } from '../core/text/ContextAwareText'

type Summary = {
  text: string
  images: { url: string; alt: string }[]
}

export type HealthSummaryScreenProps = {
  descriptionAboveImage: string
  descriptionBelowImage: string
  descriptionAbovePrediction: string
  image: string
  buttonText: string
  bloodSugarAndFatRiskSummary: Summary
  diabetesRiskSummary: Summary
  bloodFatRiskSummary: Summary
}

type Props = HealthSummaryScreenProps & {
  onNextClick: onNextClickType
  onBackClick?: onBackClickCallback
  quiz: Quizzes
}
const getRiskSummary = ({
  quiz,
  bloodFatRiskSummary,
  bloodSugarAndFatRiskSummary,
  diabetesRiskSummary,
}: Props): Summary | null => {
  const diabetesRisk = quiz.diabetes_or_prediabetes === 'true' || quiz.diabetes_in_family === 'true'
  const bloodPressureRisk = quiz.blood_pressure_diagnosed === 'true' || quiz.blood_pressure_medication === 'true'
  const cardioVascularRisk =
    quiz.cardiovascular_disease_diagnosed_or_high_cholesterol === 'true' ||
    quiz.cardiovascular_disease_in_family === 'true'
  const bloodFatRisk = cardioVascularRisk || bloodPressureRisk

  if (diabetesRisk && bloodFatRisk) {
    return bloodSugarAndFatRiskSummary
  } else if (diabetesRisk) {
    return diabetesRiskSummary
  } else if (bloodFatRisk) {
    return bloodFatRiskSummary
  }
  return null
}

export const HealthSummaryTitle: React.FC<Props> = (props) => {
  const [riskSummary, setRiskSummary] = useState(getRiskSummary(props))
  useEffect(() => {
    setRiskSummary(getRiskSummary(props))
  }, [props.quiz])

  return (
    <>
      <p className="light mb-4 text-zoe-secondary">{props.descriptionAbovePrediction}</p>
      <div className="text-xl leading-26px sm:text-2xl light">
        {riskSummary && (
          <>
            <FormattedMessage id={riskSummary.text} />
            <div className="flex justify-center mt-8">
              {riskSummary.images.map((image, index) => (
                <img key={index} src={image.url} alt={image.alt} className="h-blood-icons" />
              ))}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export const HealthSummaryScreen: React.FC<Props> = (props) => {
  return (
    <InfoOnlyScreen
      screenTitle={<HealthSummaryTitle {...props} />}
      descriptionAboveImage={<ContextAwareText text={props.descriptionAboveImage} quiz={props.quiz} />}
      image={props.image}
      descriptionBelowImage={<ContextAwareText text={props.descriptionBelowImage} quiz={props.quiz} />}
      buttonText={props.buttonText}
      onNextClick={props.onNextClick}
      onBackClick={props.onBackClick}
    />
  )
}
