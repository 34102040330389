import React, { useState } from 'react'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { ContextAwareText } from '../core/text/ContextAwareText'
import { RadioButton } from '../core/fields/RadioButton'
import * as Yup from 'yup'
import { FormScreen, FormValues } from '../screen-templates/FormScreen'
import { Menoscale, Quizzes } from '../../redux/quiz/types'
import { DSSpacer } from '@zoe/ds-web'
import { CustomAgeInput } from '../core/fields/CustomAgeInput'
import { HeightScreenBody, heightValidationSchema } from './HeightScreen'
import { simpleWeightValidationSchema } from './WeightScreen'
import { WeightScreenFormBody } from '../screen-components/WeightScreenFormBody'
import { getDefaultWeightUnitByCountry, getFormattedWeightValues } from '../../libs/weight/weight-helpers'
import { getDefaultHeightUnitByCountry, getFormattedHeightValues } from '../../libs/height/height-helpers'
import { selectQuizCountry } from '../../redux/quiz/slice'
import { useSelector } from 'react-redux'

type Question = {
  question: string
  questionKey: keyof Menoscale
  options: Option[]
}

type Option = {
  label: string
  value: string
}

export type MenoScaleAboutMeScreenProps = {
  title: string
  description: string
  questions: Question[]
}

type Props = MenoScaleAboutMeScreenProps & {
  onNextClick: onNextClickType
  onBackClick: onBackClickCallback
  quiz: Quizzes
}

export const MenoScaleAboutMeScreen: React.FC<Props> = (props: Props) => {
  const quizCountry = useSelector(selectQuizCountry)
  const defaultHeightUnit = getDefaultHeightUnitByCountry(quizCountry ?? 'GB')
  const defaultWeightUnit = getDefaultWeightUnitByCountry(quizCountry ?? 'GB')
  const [selectedHeightUnit, setSelectedHeightUnit] = useState<string>(defaultHeightUnit)

  const formBody = props.questions.map((question, index) => (
    <React.Fragment key={index}>
      <div className="mb-6 mt-6">
        <ContextAwareText text={question.question} quiz={props.quiz} />
      </div>
      {question.options.map((option, optionIndex) => (
        <React.Fragment key={optionIndex}>
          <RadioButton
            key={optionIndex}
            name={question.questionKey}
            id={option.value}
            value={option.value}
            label={option.label}
          />
          {optionIndex < question.options.length - 1 && <div className="mx-1" />}
        </React.Fragment>
      ))}
    </React.Fragment>
  ))

  const handleOnNextClick = (values?: FormValues) => {
    if (values) {
      const valuesFormattedForWeight = getFormattedWeightValues(values)
      const valuesFormattedForHeight = getFormattedHeightValues(valuesFormattedForWeight)
      props.onNextClick(valuesFormattedForHeight)
    } else {
      props.onNextClick(values)
    }
  }

  const validationSchemaEntries = props.questions.map((question) => [question.questionKey, Yup.string().required()])

  return (
    <FormScreen
      screenTitle={props.title}
      description={props.description}
      buttonText="Continue"
      form={{
        body: (
          <>
            <div>Your age</div>
            <DSSpacer size={8} direction="vertical" />
            <CustomAgeInput name="age" />
            <DSSpacer size={16} direction="vertical" />
            <div className="w-3/4 min-w-80">
              What is your height?
              <DSSpacer size={8} direction="vertical" />
              <HeightScreenBody selectedUnit={selectedHeightUnit} onSelect={setSelectedHeightUnit} />
              <DSSpacer size={16} direction="vertical" />
            </div>
            <div className="w-3/4 min-w-80">
              What is your weight?
              <DSSpacer size={8} direction="vertical" />
              <WeightScreenFormBody defaultWeightUnit={defaultWeightUnit} />
            </div>
            {formBody}
          </>
        ),
        initialValues: {
          age: '',
          height_ft: '',
          height_in: '',
          height_cm: '',
          height_unit: defaultHeightUnit,
          weight_kg: '',
          weight_lbs: '',
          weight_st_lbs: '',
          weight_st: '',
          weight_unit: defaultWeightUnit,
        },
        validationSchema: Yup.object({
          ...Object.fromEntries(validationSchemaEntries),
          ...heightValidationSchema,
          ...simpleWeightValidationSchema,
          age: Yup.number()
            .typeError('Age must be a number')
            .min(18, 'Age must be at least 18')
            .max(120, 'Age must be at most 120'),
        }),
      }}
      onNextClick={handleOnNextClick}
    />
  )
}
