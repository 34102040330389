import { formatISO } from 'date-fns'
import { SupportedCountries } from 'libs/geoLocation'
import { HeightUnit, WeightGoal, WeightPlanType, WeightUnit } from 'libs/weight/types'
import { Answer } from './slice'
import { QuizVersion } from '../../config/types'

export interface Quiz {
  id: string
  quiz_country?: SupportedCountries
  v4: QuizV4
  v5: QuizV5
  v5b: QuizV5
  menoscale: Menoscale
}

// Common

export type Sex = 'female' | 'male'

export type Gender = 'female' | 'male' | 'pnts' | 'other'

export const ALL_AGE_RANGES = ['under_20', '20s', '30s', '40s', '50s', '60s', '70s', 'above_80'] as const
export type AgeRange = typeof ALL_AGE_RANGES[number]

export const ALL_HEALTH_GOALS = [
  'ageing',
  'diabetes',
  'heart_health',
  'strength',
  'mental_health',
  'gut_health',
  'high_blood_pressure',
  'other',
] as const
export type HealthGoal = typeof ALL_HEALTH_GOALS[number]

export const ALL_HEALTH_GOALS_V5 = [
  'aging',
  'gut_health',
  'heart_health',
  'strength',
  'brain_health',
  'diabetes',
  'immune_system',
  'other',
] as const
export type HealthGoalV5 = typeof ALL_HEALTH_GOALS_V5[number]

export const ALL_HEALTH_GOALS_V5_1 = [
  'energy',
  'sleep',
  'mood',
  'hunger',
  'digestion',
  'menopause',
  'healthy_weight',
] as const

export type HealthGoalV5_1 = typeof ALL_HEALTH_GOALS_V5_1[number]

export const weightGoalAnswers = (weightGoal: WeightGoal): Answer[] => [
  {
    question_key: 'goal_type',
    answer: weightGoal.type || 'weight_loss',
  },
  {
    question_key: 'goal_date',
    answer: formatISO(weightGoal.endDate, {
      representation: 'date',
    }),
  },
  ...(weightGoal?.endWeight?.value
    ? [
        {
          question_key: 'goal_weight',
          answer: weightGoal.endWeight.value.toString(),
        } as Answer,
      ]
    : []),
  ...(weightGoal.weightPlan
    ? [
        {
          question_key: 'weight_plan',
          answer: JSON.stringify(
            weightGoal.weightPlan.map(({ date, weight }) => {
              return {
                weight: weight,
                date: formatISO(date, { representation: 'date' }),
              }
            }),
          ),
        } as Answer,
      ]
    : []),
]

type LifeEvent =
  | 'covid'
  | 'marriage_or_relationships'
  | 'pregnancy'
  | 'busier_work_family_life'
  | 'stress_and_mental_health'
  | 'none'
export type LifeEvents = LifeEvent[]

export type HealthCondition = 'eating_disorder' | 'gut_disease' | 'sensitive_gut' | 'heart_attack' | 'none'
export type HealthConditions = HealthCondition[]

export const ALL_KNOWN_WEIGHT_CHOICES = ['true', 'false', 'approximately'] as const
export type KnowYourWeight = typeof ALL_KNOWN_WEIGHT_CHOICES[number]

export const ALL_DIETS = ['1_3', '4_6', '7+'] as const
export type Diet = typeof ALL_DIETS[number]

export const ALL_FOOD_HABITS = [
  'dont_snack',
  'one_or_two_snacks',
  'snack_or_graze_throughout',
  'intermittent_fasting',
  'often_snack',
] as const
export type FoodHabits = typeof ALL_FOOD_HABITS[number]

export const ALL_HUNGER_LEVELS = ['all_the_time', 'often', 'not'] as const
export type HungerLevel = typeof ALL_HUNGER_LEVELS[number]

export const ALL_KIDS_UNDER_18 = ['none', '1', '2', '3+'] as const
export type KidsUnder18 = typeof ALL_KIDS_UNDER_18[number]

export const ALL_BOOL_CHOICES = ['true', 'false']
export type Bool = typeof ALL_BOOL_CHOICES[number]

export const ALL_DIET_COUNTS = ['5_or_fewer', '6_20', '20+', 'none', '1_5', '6+'] as const
export type DietCount = typeof ALL_DIET_COUNTS[number]

export const ALL_SLEEP_CHOICES = ['easy', 'okay', 'trouble'] as const
export type Sleep = typeof ALL_SLEEP_CHOICES[number]

export const ALL_GUT_HEALTH_CHOICES = ['not_much', 'a_bit', 'passionate'] as const
export type GutHealth = typeof ALL_GUT_HEALTH_CHOICES[number]

export const ALL_ENERGY_CHOICES = ['never', 'sometimes', 'often'] as const
export type Energy = typeof ALL_ENERGY_CHOICES[number]

export const ALL_MOBILE_CHOICES = ['ios', 'android', 'other'] as const
export type Mobile = typeof ALL_MOBILE_CHOICES[number]

export type DietaryFat = 'low_fat' | 'not_sure' | 'healthy_fat'
// Received via WebFlow query param e.g. ?flow=
export type LandingPageRedirectQueryParams = {
  flow?:
    | 'health'
    | 'weight'
    | 'feel-good'
    | 'best-version'
    | 'in-control'
    | 'feel-confident'
    | 'be-there'
    | 'look-my-best'
}

// Enable HEAR_ABOUT_US_OPTIONS to be used as type and as array. "as const" does not allow iterating through the array.
export const HEAR_ABOUT_US_OPTIONS = [
  'online',
  'influencer',
  'zoe_study',
  'publication',
  'search_engine',
  'tv_radio',
  'referral',
  'zoe_podcast',
  'podcast',
  'ms_gut_shot_partnership',
  'ask_zoe_app',
  'daily_30',
  'waitrose',
  'food_for_life_cookbook',
  'other',
] as [
  'online',
  'influencer',
  'zoe_study',
  'publication',
  'search_engine',
  'tv_radio',
  'referral',
  'zoe_podcast',
  'podcast',
  'ms_gut_shot_partnership',
  'ask_zoe_app',
  'daily_30',
  'waitrose',
  'food_for_life_cookbook',
  'other',
]
export type HearAboutUs = typeof HEAR_ABOUT_US_OPTIONS[number]

export const MENOPAUSE_OPTIONS = [
  'premenopausal',
  'perimenopausal',
  'postmenopausal',
  'do_not_know',
  'prefer_not_to_say',
] as ['premenopausal', 'perimenopausal', 'postmenopausal', 'do_not_know', 'prefer_not_to_say']
export type Menopause = typeof MENOPAUSE_OPTIONS[number]

export const ONLINE_ADS_OPTIONS = ['facebook', 'instagram', 'tiktok', 'youtube', 'other'] as [
  'facebook',
  'instagram',
  'tiktok',
  'youtube',
  'other',
]
export type OnlineAds = typeof ONLINE_ADS_OPTIONS[number]

export const LENGTH_OF_TIME_OPTIONS = [
  'less_than_one_month',
  'one_to_two_months',
  'three_to_six_months',
  'seven_to_twelve_months',
  'more_than_one_year',
] as ['less_than_one_month', 'one_to_two_months', 'three_to_six_months', 'seven_to_twelve_months', 'more_than_one_year']
export type LengthOfTime = typeof LENGTH_OF_TIME_OPTIONS[number]

// Quiz Schemas

export type QuizTags = {
  quiz_version?: QuizVersion
  landing_page_selection?: LandingPageRedirectQueryParams['flow']
  marketing?: Bool
  goal_weight?: number
  goal_date?: string
  goal_type?: WeightPlanType
  weight_plan?: string
  user_device_os?: string
}

type MenoscaleSlider = '0' | '1' | '3' | '5'

export type QuizBase = QuizTags & {
  height_ft: number
  height_in: number
  height_cm: number
  height_unit: HeightUnit
  sex: Sex
  gender: Gender
  gender_other?: string | null
  age_range: AgeRange
  hunger_level: HungerLevel
  taken_antibiotics: Bool
  sleep: Sleep
  energy: Energy
  pregnant?: Bool
  plants_in_diet: Diet
  diabetes_or_prediabetes: Bool
  diabetes_in_family: Bool
  cardiovascular_disease_diagnosed_or_high_cholesterol: Bool
  cardiovascular_disease_in_family: Bool
  diet_count: DietCount
  health_condition: HealthConditions | HealthCondition
  blood_pressure_diagnosed: Bool
  blood_pressure_medication: Bool
  location: Bool
  life_event: LifeEvents
  weight_lbs?: number
  weight_kg?: number
  ideal_weight_lbs?: number
  ideal_weight_kg?: number
  gut_health: GutHealth
  menopause: Menopause
  length_of_time: LengthOfTime
  know_your_weight: KnowYourWeight
  weight_unit: WeightUnit
  health_goal: HealthGoal | HealthGoalV5
  health_goal_other?: string | null
  kids_under_18: KidsUnder18
  kids_under_5?: Bool
  has_weight_goal: Bool
  hear_about_us?: HearAboutUs
  hear_about_us_influencer?: string
  hear_about_us_podcast?: string
  hear_about_us_publication?: string
  hear_about_us_online?: OnlineAds
  hear_about_us_other?: string
  target_event_date_weight_loss?: string
  has_flexible_timeline?: string
}

export type MenoscaleConditions = Partial<Menoscale> & {
  quiz_country?: SupportedCountries | 'false'
}

export type V5Conditions = Partial<QuizBase> & {
  has_target_date_weight_loss?: Bool
  is_frequently_hungry?: Bool
  quiz_country?: SupportedCountries | 'false'
  is_country_supported?: Bool
  available_in_location?: Bool
  has_blood_sugar_or_blood_fast_risk?: Bool
  weight_plan?: any
}

export type Conditions = V5Conditions | MenoscaleConditions

export type Menoscale = QuizBase & {
  age: string
  menopause?: Menopause
  menopause_hormonal_treatment?: 'currently_taking' | 'taken_in_past' | 'never_taken'
  hot_flushes?: MenoscaleSlider
  night_sweats?: MenoscaleSlider
  palpitations?: MenoscaleSlider
  joint_and_muscular_discomfort?: MenoscaleSlider
  weight_gain?: MenoscaleSlider
  loss_of_interest_in_sex?: MenoscaleSlider
  bladder_problems?: MenoscaleSlider
  vaginal_dryness?: MenoscaleSlider
  declining_skin_quality?: MenoscaleSlider
  declining_hair_quality?: MenoscaleSlider
  irritability?: MenoscaleSlider
  low_mood_depression_mood_swings?: MenoscaleSlider
  anxiety?: MenoscaleSlider
  sleep_problems?: MenoscaleSlider
  headaches?: MenoscaleSlider
  brain_fog?: MenoscaleSlider
  memory_problems?: MenoscaleSlider
  tiredness_and_fatigue?: MenoscaleSlider
  decreased_physical_strength?: MenoscaleSlider
  digestive_symptoms?: MenoscaleSlider
  menoscale_science_consent?: Bool
  menoscale_score?: string
  menoscale_main_symptoms?: string
}

export type QuizV4 = QuizBase & {
  food_habits: FoodHabits
  dietary_fat: DietaryFat
}
export type QuizV5 = QuizBase

export type Quizzes = QuizV4 | QuizV5 | Menoscale
