import { RootState } from './rootReducer'

export default {
  0: (state: RootState): any => state,
  1: (state: RootState): any => {
    return {
      quiz: {
        ...state.quiz,
        v3b: {},
      },
    }
  },
  2: (state: RootState): any => {
    return {
      quiz: {
        ...state.quiz,
        v3c: {},
      },
    }
  },
  3: (state: RootState): any => {
    return {
      quiz: {
        ...state.quiz,
      },
    }
  },
  4: (state: RootState): any => {
    return {
      ...state,
      quiz: {
        id: state.quiz.id,
        v4: {},
      },
    }
  },
  5: (state: RootState): any => {
    return {
      ...state,
      quiz: {
        id: state.quiz.id,
        v4: state.quiz.v4,
        'no-test-trial': {},
      },
    }
  },
  6: (state: RootState): any => {
    return {
      ...state,
      quiz: {
        id: state.quiz.id,
        v4: state.quiz.v4,
      },
    }
  },
  7: (state: RootState): any => {
    return {
      ...state,
      quiz: {
        id: state.quiz.id,
        v4: state.quiz.v4,
        v5: {},
      },
    }
  },
  8: (state: RootState): any => {
    return {
      ...state,
      quiz: {
        id: state.quiz.id,
        v4: state.quiz.v4,
        v5: {},
        v5b: {},
      },
    }
  },
}
