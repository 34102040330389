import React from 'react'
import { appConfig } from '../../appConfig'

type LogoHeaderProps = {
  className?: string
}

export const LogoHeader: React.FC<LogoHeaderProps> = ({ className = '' }) => {
  return (
    <div className={`h-logo-header flex justify-center items-center ${className}`}>
      <div className="flex justify-star w-form-width-mobile sm:w-form-width-desktop sm:justify-start">
        <img src={`${appConfig.publicUrl}/img/logos/zoe-logo.svg`} alt="ZOE logo" className="h-zoe-tm-logo" />
      </div>
    </div>
  )
}
