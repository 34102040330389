import React from 'react'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { TestimonialTemplate, Testimony } from 'components/screen-templates/TestimonialTemplate'

export type TestimonialScreenProps = {
  title: string
  buttonText?: string
  testimonies: Testimony[]
}

type Props = TestimonialScreenProps & {
  onNextClick: onNextClickType
  onBackClick?: onBackClickCallback
}

export const TestimonialScreen: React.FC<Props> = ({ title, buttonText, onNextClick, onBackClick, testimonies }) => {
  return (
    <TestimonialTemplate
      title={title}
      buttonText={buttonText ?? 'Continue'}
      testimonies={testimonies}
      onNextClick={onNextClick}
      onBackClick={onBackClick}
    />
  )
}
