import type { ChangeEvent } from 'react'
import React, { useMemo, useState } from 'react'
import { styled } from 'styled-components'

const StyledSliderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`

const StyledRangeInput = styled.input.attrs({ type: 'range' })`
  -webkit-appearance: none;
  appearance: none;
  background: var(--colour-surface-white);
  border-radius: 100px;
  height: 12px;
  margin: 0;
  outline: none;
  position: relative;
  width: 100%;
  z-index: 1;

  &::-webkit-slider-runnable-track {
    background: var(--colour-surface-white);
    border-radius: 100px;
    cursor: pointer;
    height: 12px;
    width: 100%;
  }

  &::-moz-range-track {
    background: var(--colour-surface-white);
    border-radius: 100px;
    cursor: pointer;
    height: 12px;
    width: 100%;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    background: var(--colour-surface-aubergine-bold);
    border-radius: 50%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    height: 24px;
    position: relative;
    top: -6px;
    transition: transform 0.1s ease;
    width: 24px;
    z-index: 5;
  }

  &::-moz-range-thumb {
    background: var(--colour-surface-aubergine-bold);
    border-radius: 50%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    height: 24px;
    position: relative;
    transition: transform 0.1s ease;
    width: 24px;
    z-index: 5;
  }

  &:hover::-webkit-slider-thumb {
    transform: scale(1.1);
  }

  &:hover::-moz-range-thumb {
    transform: scale(1.1);
  }
`

const StyledDotsBackground = styled.div`
  border-radius: 100px;
  display: flex;
  left: 0;
  height: 12px;
  justify-content: space-between;
  padding: 0 6px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  z-index: 2;
`

const StyledDot = styled.div`
  background-color: var(--colour-text-secondary-inverse);
  border-radius: 50%;
  height: 4px;
  margin: 4px 4px 0 4px;
  width: 4px;
  z-index: 2;

  &:hover {
    transform: scale(1.1);
  }
`

const StyledUntouchedRangeDot = styled.div`
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  border: 10px solid rgba(226, 226, 226, 1);
  border-radius: 50%;
  background: rgba(226, 226, 226, 1);
  cursor: pointer;
  height: 24px;
  left: calc(50% - 12px);
  position: absolute;
  width: 24px;
  z-index: 5;
  pointer-events: none;
  transition: transform 0.1s ease;
`

export interface CustomRangeSliderProps {
  min: number
  max: number
  step: number
  name: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
}

export const CustomRangeSlider: React.FC<CustomRangeSliderProps> = ({
  min,
  max,
  step,
  name,
  onChange,
}: CustomRangeSliderProps) => {
  const [value, setValue] = useState<string>('')
  const [touched, setTouched] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e)
    setValue(e.target.value)
    setTouched(true)
  }
  const background = useMemo(() => {
    const percentage = (((parseInt(value) ?? 0) - min) / (max - min)) * 100
    return touched
      ? `linear-gradient(to right, var(--colour-surface-aubergine-bold) ${percentage}%, transparent ${percentage}%)`
      : 'var(--colour-surface-white)'
  }, [value, touched, min, max])
  return (
    <StyledSliderWrapper onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <StyledDotsBackground style={{ background }}>
        {[...Array(max - min + 1)].map((_, index) => (
          <StyledDot key={index} />
        ))}
      </StyledDotsBackground>
      {!touched && <StyledUntouchedRangeDot style={{ transform: isHovered ? 'scale(1.1)' : 'scale(1)' }} />}
      <StyledRangeInput max={max} min={min} name={name} onChange={handleChange} step={step} value={value} />
    </StyledSliderWrapper>
  )
}
