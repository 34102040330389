import { useField, useFormikContext } from 'formik'
import React from 'react'

import { FieldError } from './FieldError'
import { FieldLabel } from './FieldLabel'

export interface InputFieldProps extends React.ComponentProps<'input'> {
  label?: React.ReactNode
  name: string // needed for useField(props)
}

export const InputField: React.FC<InputFieldProps> = ({ label, ...props }) => {
  const [field, meta] = useField(props)
  const context = useFormikContext()

  const onChangeValidation = async (e: React.ChangeEvent<HTMLInputElement>) => {
    field.onChange(e) // manually tell Formik to update the field value, then check overall Form validation
    await context.validateForm({
      ...(context.values as any),
      [props.name]: e.target.value, // ensures validation with the new value
    })
  }

  return (
    <div className="flex flex-col" data-testid={`${props.name}-div`}>
      {label && (
        <FieldLabel
          htmlFor={props.id || props.name}
          className="light mb-2"
          data-testid={`${props.name}-${label}-label`}
        >
          {label}
        </FieldLabel>
      )}
      <input
        className="appearance-none bg-background-secondary h-input-field px-6 py-5 border-none rounded-none focus:outline-none"
        {...field} // ensure onChange comes after ...field as it overwrites default properties
        {...props}
        onChange={onChangeValidation}
        data-testid={`${props.name}-input`}
      />
      {(meta.touched || meta.value?.toString().length >= 2) && meta.error && <FieldError>{meta.error}</FieldError>}
    </div>
  )
}
