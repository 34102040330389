import React, { useMemo } from 'react'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { Quizzes } from 'redux/quiz/types'
import { ProgressLoadingScreen } from 'components/screen-templates/ProgressLoadingScreen'
import {
  getWeightGoal,
  lbsLeftOverFromTotalLbs,
  stoneFromTotalLbs,
  weightRange,
} from '../../../libs/weight/weightCalculations'
import { FormattedMessage, useIntl } from 'react-intl'

export type ProgressScreenProps = {
  title: string
  titleAboveMedia: string
  description: string
  image: string
  imageAlt: string
  footnote: string
  buttonText: string
  otherImages: string[]
}

type Props = ProgressScreenProps & {
  onBackClick?: onBackClickCallback
  onNextClick: onNextClickType
  quiz: Quizzes
}

const ScreenTitle: React.FC<Props> = ({ quiz, title }) => {
  const intl = useIntl()
  const { gender, age_range } = quiz
  const weightGoal = useMemo(() => quiz && getWeightGoal(quiz), [quiz])
  const weightPlanType = weightGoal?.type || 'weight_loss'
  const weightUnitStone = quiz.weight_unit === 'stone' && weightPlanType === 'weight_loss' ? '_stone' : ''
  const { lowerWeight, upperWeight } = weightRange(quiz)

  return (
    <div className="text-left">
      <h2 className="light mb-4">{title}</h2>
      <div className="bg-surface-teal-subtle p-24 rounded-lg text-left mb-8 mt-8 text-xl light">
        <FormattedMessage
          id={`quiz.science.${weightPlanType}${weightUnitStone}.plan_advice.v5`}
          values={{
            gender: intl.formatMessage({ id: `quiz.gender.${gender}.display_plural` }),
            ageRange: intl.formatMessage({
              id: `quiz.age_range.${age_range}`,
            }),
            lowerWeight: lowerWeight?.value,
            upperWeight: upperWeight?.value,
            ...(weightPlanType === 'weight_maintenance' && {
              healthGoal: intl.formatMessage({
                id: `quiz.health_goal.${quiz.health_goal}.short.v5`,
              }),
            }),
            lowerWeightStone: lowerWeight?.value && stoneFromTotalLbs(lowerWeight.value),
            lowerWeightLbs: lowerWeight?.value && lbsLeftOverFromTotalLbs(lowerWeight.value),
            upperWeightStone: upperWeight?.value && stoneFromTotalLbs(upperWeight.value),
            upperWeightLbs: upperWeight?.value && lbsLeftOverFromTotalLbs(upperWeight.value),
            unit: quiz.weight_unit || 'lbs',
          }}
        />
      </div>
    </div>
  )
}

export const ProgressScreen: React.FC<Props> = (props) => {
  return (
    <ProgressLoadingScreen
      screenTitle={<ScreenTitle {...props} />}
      titleAboveMedia={props.titleAboveMedia}
      descriptionAboveMedia={props.description}
      image={props.image}
      imageAlt={props.imageAlt}
      footnote={props.footnote}
      otherImages={props.otherImages}
      buttonText={props.buttonText}
      onNextClick={props.onNextClick}
      onBackClick={props.onBackClick}
    />
  )
}
