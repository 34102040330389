import React, { ReactNode, useEffect } from 'react'
import { FormScreen } from '../../screen-templates/FormScreen'
import { onBackClickCallback, onNextClickType } from 'components/types'
import * as Yup from 'yup'
import { Menoscale, Quizzes } from '../../../redux/quiz/types'
import { Slider } from '../fields/Slider'
import { DSSpacer } from '@zoe/ds-web'
import { useFormikContext } from 'formik'

type Question = {
  questionKey: keyof Menoscale
  question: string
}

const SlidersGroup = ({ children }: { children: ReactNode }) => {
  const context = useFormikContext()
  const hasErrors = context.errors && Object.keys(context.errors).length > 0

  useEffect(() => {
    if (context.submitCount > 0 && hasErrors) {
      const keys = Object.keys(context.errors)
      const firstErrorKey = keys[0]
      const firstErrorElement = document.getElementById(firstErrorKey)
      if (firstErrorElement && firstErrorElement.scrollIntoView) {
        firstErrorElement.scrollIntoView({ behavior: 'smooth' })
      }
    }
  }, [context.submitCount, hasErrors])
  return <>{children}</>
}

export type MultipleSlidersScreenProps = {
  title: string
  buttonText?: string
  questions: Question[]
  sliderOptionValues: string[]
  labels: string[]
  requiredFieldMissingMessage: string
}

type Props = MultipleSlidersScreenProps & {
  onBackClick?: onBackClickCallback
  onNextClick: onNextClickType
  quiz: Quizzes
}

export const MultipleSlidersScreen: React.FC<Props> = ({
  title,
  buttonText,
  questions,
  onNextClick,
  onBackClick,
  sliderOptionValues,
  labels,
  requiredFieldMissingMessage,
}) => {
  const formBody = questions.map((question, index) => {
    return (
      <div key={index} id={question.questionKey}>
        <Slider
          name={question.questionKey}
          values={sliderOptionValues}
          label={question.question}
          sliderLabels={labels}
        />
        <DSSpacer size={12} direction="vertical" />
      </div>
    )
  })
  const validationSchemaEntries = questions.map((question) => [
    question.questionKey,
    Yup.string().required(requiredFieldMissingMessage),
  ])

  return (
    <FormScreen
      screenTitle={title}
      buttonText={buttonText ?? 'Continue'}
      forceEnableButton={true}
      valueMapper={(values) => {
        const map = sliderOptionValues
        const entries = Object.entries(values)
        const newEntries = entries.map(([key, value]) => [key, map[parseInt(value)]])
        return Object.fromEntries(newEntries)
      }}
      form={{
        body: <SlidersGroup>{formBody}</SlidersGroup>,
        validationSchema: Yup.object(Object.fromEntries(validationSchemaEntries)),
      }}
      onNextClick={onNextClick}
      onBackClick={onBackClick}
    />
  )
}
