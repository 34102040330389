import React from 'react'
import { appConfig } from 'appConfig'

type StarRatingProps = {
  rating: number
}

const RATING_POSITION = [
  '',
  '',
  '-148px -0px',
  '-10px -0px',
  '-10px -34px',
  '-286px -0px',
  '-286px -34px',
  '-148px -34px',
  '-148px -68px',
  '-10px -68px',
  '-286px -68px',
]

export const TrustpilotStarRating: React.FC<StarRatingProps> = ({ rating }: StarRatingProps) => {
  const ratingPosition = RATING_POSITION[Math.round(rating * 2)]

  return (
    <div className="trustpilot-star-rating">
      {ratingPosition && (
        <div>
          <span
            className="star-rating"
            style={{
              background: `url('${appConfig.publicUrl}/img/trustpilot/star-ratings.png') no-repeat`,
              backgroundPosition: ratingPosition,
            }}
          />
        </div>
      )}
    </div>
  )
}
