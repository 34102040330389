import { Answer, QuestionKey } from 'redux/quiz/slice'
import { FormValues } from './screen-templates/FormScreen'
import { Conditions } from '../redux/quiz/types'

export const formValuesToAnswers = (values: FormValues): Answer[] =>
  Object.entries(values).map(([question_key, answer]) => ({
    question_key: question_key as QuestionKey,
    answer,
  }))

export const evaluateCondition = (rules: Conditions | undefined, data: Partial<Conditions>): boolean => {
  if (!rules) return true
  let key: keyof typeof rules
  for (key in rules) {
    const rule = rules[key]
    const value = data[key]

    if (rule !== undefined) {
      if (value === undefined) {
        return false
      }
      const valueAsArray = Array.isArray(value) ? value : [value]
      const ruleAsArray = Array.isArray(rule) ? rule : [rule]
      const isValid = valueAsArray.some((v) => ruleAsArray.includes(v))
      if (!isValid) {
        return false
      }
    }
  }
  return true
}
