import { AgeRange, Sex } from 'redux/quiz/types'

const parasitePrevalence = {
  under_20: {
    male: 0.119565,
    female: 0.132948,
  },
  '20s': {
    male: 0.119565,
    female: 0.132948,
  },
  '30s': {
    male: 0.193103,
    female: 0.112957,
  },
  '40s': {
    male: 0.214765,
    female: 0.179954,
  },
  '50s': {
    male: 0.181818,
    female: 0.159494,
  },
  '60s': {
    male: 0.230769,
    female: 0.177778,
  },
  '70s': {
    male: 0.25134106,
    female: 0.19348507,
  },
  above_80: {
    male: 0.25134106,
    female: 0.19348507,
  },
}

export const getParasitePrevalence = (ageRange: AgeRange, sex: Sex): number => parasitePrevalence[ageRange][sex] * 100
