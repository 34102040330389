import React from 'react'

export type LogoDividerProps = React.ComponentProps<'div'> & { logo: string }

export const LogoDivider: React.FC<LogoDividerProps> = React.memo(({ logo, ...props }) => (
  <div className="flex items-center" {...props}>
    <hr className="my-6 border-background-tertiary flex-1" />
    <img width={64} height={40} src={`${logo}`} alt="Divider logo" className="h-logo-divider-length px-3" />
    <hr className="my-6 border-background-tertiary flex-1" />
  </div>
))
