import React from 'react'

import { TrustpilotStarRating } from './TrustpilotStarRating'
import { appConfig } from 'appConfig'

export const Trustpilot: React.FC = () => {
  return (
    <div className="trustpilot-container">
      <p>
        We are rated <strong>Excellent</strong>.
      </p>
      <TrustpilotStarRating rating={4.3} />
      <div style={{ display: 'block', margin: '10px' }}>
        <img src={`${appConfig.publicUrl}/img/logos/trustpilot-logo.svg`} alt="Trustpilot logo" />
      </div>
    </div>
  )
}
