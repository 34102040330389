import React from 'react'
import { useField, useFormikContext } from 'formik'
import { FieldError } from './FieldError'

export interface CheckboxProps extends React.ComponentProps<'input'> {
  label: React.ReactNode
  name: string // needed for useField(props)
  value: string
}

export const Checkbox: React.FC<CheckboxProps> = ({ ...props }) => {
  const [field, meta, helpers] = useField(props)
  const context = useFormikContext()
  const { setValue } = helpers

  const clickHandler = async () => {
    const set = new Set(field.value)
    if (props.value === 'none') {
      set.clear()
      set.add(props.value)
    } else if (set.has(props.value)) {
      set.delete(props.value)
    } else {
      set.delete('none')
      set.add(props.value)
    }

    setValue(Array.from(set))

    await context.validateForm({
      ...(context.values as any),
      [props.name]: Array.from(set), // ensures validation with the new value
    })
  }

  return (
    <div
      className={`flex-1 highlight-label-selected mb-4 ${props.className}`}
      data-testid={`${props.name}-${props.value}-div`}
    >
      <input
        {...field}
        type="checkbox"
        checked={field.value?.includes(props.value)}
        className={`fixed pointer-events-none opacity-0 ${
          !field.value && !field.value?.includes(props.value) ? 'indeterminate' : ''
        }`}
        onClick={clickHandler}
        data-testid={`${props.name}-${props.value}-input`}
      />
      <label
        className="block p-4 border-solid border-2 border-background-secondary bg-background-secondary hover:border-zoe-primary"
        htmlFor={props.value}
        onClick={clickHandler}
        data-testid={`${props.name}-${props.value}-label`}
      >
        {props.label || props.value}
      </label>
      {meta.touched && meta.error && <FieldError>{meta.error}</FieldError>}
    </div>
  )
}
