import { configureStore as _configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import {
  createMigrate,
  FLUSH,
  getStoredState,
  PAUSE,
  PERSIST,
  PersistConfig,
  persistReducer,
  persistStore,
  PURGE,
  purgeStoredState,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import * as Sentry from '@sentry/react'

import { __DEV__ } from 'appConfig'
import rootReducer from 'redux/rootReducer'
import migrations from './migrations'
import storage from './storage'

export const configureStore = (key = 'root'): any => {
  const persistConfig: PersistConfig<any> = {
    key,
    storage,
    throttle: 500,
    version: 4,
    migrate: async (state, currentVersion) => {
      if (!state) {
        try {
          const previousConfig: PersistConfig<any> = {
            key,
            storage,
          }

          const previousState = await getStoredState(previousConfig)
          if (previousState) {
            const newState = createMigrate(migrations as any, {
              debug: __DEV__,
            })(previousState as any, currentVersion)
            purgeStoredState(previousConfig)
            return newState
          }
        } catch (error) {
          console.error(error)
        }
      }

      return createMigrate(migrations as any, { debug: __DEV__ })(state, currentVersion)
    },
  }

  // purgeStoredState(persistConfig)

  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    stateTransformer: (state) => {
      const transformedState = {
        user_id: state.quiz.id,
      }

      return transformedState
    },
  })

  const store = _configureStore({
    reducer: persistedReducer,
    middleware: [
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }),
    ],
    devTools: __DEV__,
    enhancers: [sentryReduxEnhancer],
  }) as any

  const persistor = persistStore(store)

  return { store, persistor }
}
