import React from 'react'
import { Quizzes } from 'redux/quiz/types'
import { HealthStatCardContent } from './HealthStatCardContent'
import { Quote, QuoteCard } from './QuoteCard'
import { StatisticCard } from './StatisticCard'
import { WeightStatisticCard } from './WeightStatisticCard'
import { ZoeScoresStatCardContent } from './ZoeScoresStatCardContent'

const DAILY_MAIL_QUOTE: Quote = {
  quoteTextId: 'quiz.advice.weight_loss.quote',
  author: 'Alexandra Heminsley',
  authorRole: 'Writer',
  logo: '/img/logos/daily-mail.svg',
  logoAlt: 'From the Daily Mail.',
}

const HEALTH_STATS = [
  {
    category: 'health',
    statisticFigure: 61,
    statisticUnit: '%',
    strapline: 'quiz.advice.weight_loss.statistic.health.strapline',
    content: (
      <HealthStatCardContent
        title={'quiz.advice.weight_loss.statistic.health.content.title'}
        text={'quiz.advice.weight_loss.statistic.health.content.item_'}
      />
    ),
    footnote: 'quiz.advice.weight_loss.statistic.survey.footnote',
  },
  {
    category: 'long_term',
    statisticFigure: 76,
    statisticUnit: '%',
    strapline: 'quiz.advice.weight_loss.statistic.long_term.strapline',
    content: (
      <img
        src={`/img/quiz/long-term-zoe-vs-diet.svg`}
        alt="ZOE versus diets chart over the long term"
        className="w-full"
      />
    ),
    footnote: 'quiz.advice.weight_loss.statistic.survey.footnote',
  },
  {
    category: 'hunger',
    statisticFigure: 69,
    statisticUnit: '%',
    strapline: 'quiz.advice.weight_loss.statistic.hunger.strapline',
    content: (
      <img
        src={`/img/quiz/hunger-before-after-zoe.svg`}
        alt="Hunger level chart before and after ZOE program"
        className="w-full"
      />
    ),
    footnote: 'quiz.advice.weight_loss.statistic.survey.footnote',
  },
]

const ZOE_SCORES_STAT = {
  category: 'zoe_scores',
  statisticFigure: 85,
  statisticUnit: '%',
  strapline: 'quiz.advice.weight_loss.statistic.zoe_scores.strapline',
  content: <ZoeScoresStatCardContent />,
}

const renderHealthStatsCards = () => {
  return HEALTH_STATS.map((statistic, index) => {
    return (
      <StatisticCard
        key={`${statistic.category}_${index}`}
        category={statistic.category}
        statisticFigure={statistic.statisticFigure}
        statisticUnit={statistic.statisticUnit}
        strapline={statistic.strapline}
        content={statistic.content}
        footnote={statistic.footnote}
      />
    )
  })
}

type Advice1BodyProps = {
  quiz: Quizzes
}

export const Advice1Body: React.FC<Advice1BodyProps> = ({ quiz }) => {
  return (
    <div className="grid gap-y-4">
      <WeightStatisticCard quiz={quiz} />
      {renderHealthStatsCards()}
      <QuoteCard quote={DAILY_MAIL_QUOTE} />

      <StatisticCard
        category={ZOE_SCORES_STAT.category}
        statisticFigure={ZOE_SCORES_STAT.statisticFigure}
        statisticUnit={ZOE_SCORES_STAT.statisticUnit}
        strapline={ZOE_SCORES_STAT.strapline}
        content={ZOE_SCORES_STAT.content}
      />
    </div>
  )
}
