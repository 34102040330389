import React from 'react'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { weightPlanTypeFromQuiz } from 'libs/weight/weightCalculations'
import { Quizzes } from 'redux/quiz/types'
import { MultiImagesConditionalScreen } from 'components/screen-templates/MultiImagesConditionalScreen'
import { WeightByDateHeading } from 'components/screen-components/WeightByDateHeading'
import { goalAnswersToBeSubmitted } from 'data/goalAnswersToBeSubmitted'
import { Advice1Footer } from 'components/screen-components/Advice1Footer'
import { Advice1Body } from 'components/screen-components/Advice1Body'
import { FormattedMessage, useIntl } from 'react-intl'

export type Advice1ScreenProps = {
  buttonText: string
}

type Props = Advice1ScreenProps & {
  onNextClick: onNextClickType
  onBackClick: onBackClickCallback
  quiz: Quizzes
}
export const Advice1Screen: React.FC<Props> = ({ buttonText, onBackClick, onNextClick, quiz }) => {
  const intl = useIntl()
  return (
    <MultiImagesConditionalScreen
      conditionalUpon={'weight_plan'}
      condition={weightPlanTypeFromQuiz(quiz)}
      conditionalScreen1={{
        screenType: 'cards',
        title: <FormattedMessage id="quiz.advice.title.v5" />,
        subTitle: <FormattedMessage id="quiz.advice.weight_loss.sub_title" />,
        body: <Advice1Body quiz={quiz} />,
        footer: (
          <Advice1Footer buttonText={buttonText} onNextClick={() => onNextClick(goalAnswersToBeSubmitted(quiz, 0))} />
        ),
        onNextClick,
      }}
      conditionalScreen2={{
        screenType: 'multiImagesInfoOnly',
        screenTitle: <FormattedMessage id="quiz.advice.title.v5" />,
        descriptionAboveDynamicImageStatic: <FormattedMessage id="quiz.advice.weight_maintenance.sub_title" />,
        descriptionAboveDynamicImageDynamic: <WeightByDateHeading quiz={quiz} daysOffset={0} />,
        image1: '/img/quiz/long-term-health-with-weight-line-3b.png',
        image1Alt: intl.formatMessage({ id: 'quiz.advice.long_term_health.image.alt' }),
        descriptionBelowImage1Static: <FormattedMessage id="quiz.advice.long_term_health.plan_explanation.v5" />,
        descriptionBelowImage2Static: <FormattedMessage id="quiz.advice.study" />,
        hideInterimCallToAction: true,
        showDivider: true,
        dividerSectionText: <FormattedMessage id="quiz.advice.journals" />,
        otherImages: [
          '/img/logos/nature-medicine.svg',
          '/img/logos/science-magazine.svg',
          '/img/logos/lancet-magazine.svg',
        ],
        buttonText,
        goalAnswersToBeSubmitted: goalAnswersToBeSubmitted(quiz, 0),
        onNextClick: onNextClick,
        onBackClick,
      }}
    />
  )
}
