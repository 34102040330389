import classNames from 'classnames'
import React, { ReactNode } from 'react'

type Props = {
  backgroundColor?: string
  padding?: string
  children: ReactNode
  className?: string
}
export const BackgroundCard: React.FC<Props> = (props) => {
  return (
    <div
      style={{
        maxWidth: '854px',
        minHeight: '400px',
        width: '100%',
        padding: props.padding ?? '48px',
        backgroundColor: props.backgroundColor,
      }}
      className={classNames(
        'flex flex-col text-center justify-center items-center rounded-none md:rounded-3xl',
        props.className ?? '',
      )}
    >
      {props.children}
    </div>
  )
}
