import { useField, useFormikContext } from 'formik'
import React from 'react'
import { CustomRangeSlider } from './CustomRangeSlider'
import { DSFlex, DSSpacer, DSText } from '@zoe/ds-web'
import { ContextAwareText } from '../text/ContextAwareText'
import { FieldError } from './FieldError'

export interface SliderProps extends React.ComponentProps<'input'> {
  label: string
  sliderLabels: string[]
  name: string
  values: string[]
}

export const Slider: React.FC<SliderProps> = ({ ...props }) => {
  const [field, meta] = useField(props)
  const context = useFormikContext()

  const onChangeValidation = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = Math.round(parseFloat(e.target.value))
    e.target.value = val.toString()
    field.onChange(e)
    await context.validateForm({
      ...(context.values as any),
      [props.name]: val,
    })
  }

  const shouldDisplayError = meta.error && context.submitCount > 0
  const style = shouldDisplayError ? { border: '1px solid rgba(189, 61, 36, 1)' } : {}
  return (
    <>
      <div className="bg-cream-muted rounded-lg p-4" style={style}>
        <div className={`light text-base-secondary`}>
          <DSText variant="fluid-paragraph-300">
            <ContextAwareText text={props.label} />
          </DSText>
        </div>
        <DSSpacer size={16} direction="vertical" />
        <div className="flex justify-evenly">
          <CustomRangeSlider
            {...field}
            {...props}
            name={props.name}
            step={0.5}
            min={0}
            max={props.values.length - 1}
            onChange={onChangeValidation}
          />
        </div>
        {props.sliderLabels && (
          <>
            <DSSpacer size={8} direction="vertical" />
            <DSFlex direction="row" justifyContent="space-between">
              {props.sliderLabels.map((label, index) => (
                <DSText key={index} variant="fluid-label-100">
                  {label}
                </DSText>
              ))}
            </DSFlex>
          </>
        )}
      </div>
      {shouldDisplayError && (
        <div className="flex w-full">
          <FieldError>{meta.error}</FieldError>
        </div>
      )}
    </>
  )
}
