import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { appConfig } from 'appConfig'
import { Configuration, QuizApi } from 'clients/quiz-service'
import { RootState } from 'redux/rootReducer'
import { Bool, Menoscale, Quiz, QuizV4, QuizV5, Quizzes } from './types'
import { v4 as uuid } from 'uuid'
import { AppDispatch } from 'App'
import { SupportedCountries } from 'libs/geoLocation'
import { QuizVersion } from '../../config/types'

export type UpdateQuizAction = {
  quiz: Partial<QuizV4>
  version: QuizVersion
  userId?: string
}

export type UpdateCountryAction = {
  quiz_country?: SupportedCountries
}

export const quizApi = new QuizApi(new Configuration(), appConfig.quizServiceApiBaseUri)
const initialState = {
  id: uuid(),
  v4: {},
  v5: {},
  v5b: {},
  menoscale: {},
} as Quiz

const quizSlice = createSlice({
  name: 'quiz',
  initialState: initialState,
  reducers: {
    updateQuiz(state, action: PayloadAction<UpdateQuizAction>) {
      const { quiz, version, userId } = action.payload

      const oldState = state[version] ? state : initialState
      const newId = userId ?? oldState.id
      const newState = {
        ...oldState,
        id: newId,
        [version]: {
          ...oldState[version],
          ...quiz,
        },
      }
      // we got an full quiz state update at once, don't do consistency clean-ups
      if (userId) {
        return newState
      }

      if (newState[version].know_your_weight !== oldState[version].know_your_weight) {
        delete newState[version].weight_lbs
        delete newState[version].weight_kg
      }
      if (newState[version].has_weight_goal !== oldState[version].has_weight_goal) {
        delete newState[version].ideal_weight_lbs
        delete newState[version].ideal_weight_kg
      }
      if (
        newState[version].weight_unit !== oldState[version].weight_unit ||
        newState[version].weight_lbs !== oldState[version].weight_lbs ||
        newState[version].weight_kg !== oldState[version].weight_kg ||
        newState[version].ideal_weight_kg !== oldState[version].ideal_weight_kg
      ) {
        delete newState[version].goal_weight
        delete newState[version].goal_date
        delete newState[version].goal_type
        delete newState[version].weight_plan
      }
      return newState
    },
    updateQuizCountry(state, action: PayloadAction<UpdateCountryAction>) {
      const { quiz_country } = action.payload

      if (!quiz_country) {
        return state
      }

      return {
        ...state,
        quiz_country,
      }
    },
  },
})

// Actions
export const { updateQuiz, updateQuizCountry } = quizSlice.actions

// Async Actions

export type QuestionKey = keyof QuizV5 | keyof Menoscale
export type AnswerValue = string | number | string[] | Bool

export interface Answer {
  question_key: QuestionKey
  answer: AnswerValue
}

export interface Answers {
  quiz_version: QuizVersion
  answers: Answer[]
}

// Helpers

const mapAnswersToQuizUpdate = (answers: Answer[]) =>
  answers.reduce<Partial<Quizzes>>(
    (result, { question_key, answer }) => Object.assign(result, { [question_key]: answer }),
    {},
  )

const sanitiseAnswersForRequest = (answers: Answer[]) =>
  answers.map(({ question_key, answer }) => ({
    question_key,
    answer: answer === 'string' ? answer : answer.toString(),
  }))

export const submitAnswers = createAsyncThunk<unknown, Answers, { dispatch: AppDispatch; state: RootState }>(
  'quiz/answers',
  async ({ quiz_version, answers }: Answers, { dispatch, getState }) => {
    await dispatch(
      updateQuiz({
        quiz: mapAnswersToQuizUpdate(answers),
        version: quiz_version,
      }),
    )

    try {
      const answersRequest = {
        user_id: getState().quiz.id,
        quiz_version: quiz_version,
        quiz_country: getState().quiz.quiz_country,
        answers: sanitiseAnswersForRequest(answers),
      }
      await quizApi.answers(answersRequest)
    } catch (err) {
      console.error(`Failed to submit answer: ${err}`)
    }

    return getState().quiz
  },
)

// Selectors
const s = (state: RootState) => state.quiz

export const selectQuiz = (state: RootState): Quiz => s(state)
export const selectQuizCountry = (state: RootState): SupportedCountries | undefined => s(state).quiz_country

export default quizSlice.reducer
