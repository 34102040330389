import { QuizOverrideEvent } from '../experiments'

// add experiment names here (i.e ExperimentNames = 'experiment_name' | 'another_experiment_name')
export type ExperimentNames = 'progress-bar-sqrt'

// Each experiment can be associated with an array of overrides
// that will be applied to the quiz's base configuration
type ExperimentOverrides = Record<ExperimentNames, QuizOverrideEvent[]>

export const ALL_EXPERIMENTS: ExperimentOverrides = {
  'progress-bar-sqrt': [],
}
