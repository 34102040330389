import React, { useMemo, useState } from 'react'
import { FormScreen, FormValidationSchema, FormValues } from 'components/screen-templates/FormScreen'
import * as Yup from 'yup'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { Quizzes } from 'redux/quiz/types'
import { lowerHealthyBMIWeightForHeightForUnit } from 'libs/weight/weightCalculations'
import { MAX_ALLOWED_WEIGHT_KG, MAX_ALLOWED_WEIGHT_LBS, WeightUnit } from 'libs/weight/types'
import { Answer } from 'clients/quiz-service'
import { IntlShape, useIntl } from 'react-intl'
import { MIN_TEST_BMI } from 'libs/weight/bmi'
import { WeightScreenFormBody } from '../screen-components/WeightScreenFormBody'
import { getFormattedWeightValues } from '../../libs/weight/weight-helpers'

export const WEIGHT_SCREEN_INITIAL_VALUES = {
  weight_kg: '',
  weight_lbs: '',
  weight_st_lbs: '',
  weight_st: '',
  weight_unit: 'lbs',
}

export type WeightScreenProps = {
  title: string
  description?: string
  buttonText?: string
  defaultWeightUnit: WeightUnit
}

type Props = WeightScreenProps & {
  onBackClick?: onBackClickCallback
  onNextClick: onNextClickType
  quiz: Quizzes
}

export const WeightScreen: React.FC<Props> = ({
  title,
  description,
  buttonText,
  defaultWeightUnit,
  onNextClick,
  onBackClick,
  quiz,
}) => {
  const intl = useIntl()
  const [initialFormValues] = useState({ ...WEIGHT_SCREEN_INITIAL_VALUES, ...{ weight_unit: defaultWeightUnit } })

  const handleOnNextClick = (values?: FormValues | Answer[] | any) => {
    onNextClick(getFormattedWeightValues(values))
  }

  return (
    <FormScreen
      screenTitle={title}
      description={description}
      buttonText={buttonText}
      form={{
        body: <WeightScreenFormBody defaultWeightUnit={defaultWeightUnit} />,
        validationSchema: validationSchema(intl, quiz),
        initialValues: initialFormValues,
      }}
      onNextClick={handleOnNextClick}
      onBackClick={onBackClick}
    />
  )
}

const validationSchema = (intl: IntlShape, quiz: Quizzes): FormValidationSchema => {
  const minWeightLBS = useMemo(() => lowerHealthyBMIWeightForHeightForUnit(quiz, 'lbs', MIN_TEST_BMI), [quiz])
  const minWeightKG = useMemo(() => lowerHealthyBMIWeightForHeightForUnit(quiz, 'kg', MIN_TEST_BMI), [quiz])
  const minWeightStones = useMemo(
    () => ({
      stone: Math.floor(minWeightLBS.value / 14),
      lbs: minWeightLBS.value % 14,
    }),
    [minWeightLBS],
  )

  return Yup.object({
    weight_unit: Yup.string().required(),

    weight_lbs: Yup.number().when('weight_unit', {
      is: (weight_unit) => weight_unit === 'lbs',
      then: Yup.number()
        .required('Please enter your weight')
        .min(
          minWeightLBS.value,
          intl.formatMessage(
            { id: 'quiz.weight.validation.lower_bmi_limit' },
            { minBMI: MIN_TEST_BMI, minWeight: minWeightLBS.value, unit: 'lbs' },
          ),
        )
        .max(
          MAX_ALLOWED_WEIGHT_LBS,
          intl.formatMessage(
            { id: 'quiz.weight_validation.max_weight' },
            { maxWeight: MAX_ALLOWED_WEIGHT_LBS, unit: 'lbs' },
          ),
        ),
      otherwise: Yup.number(),
    }),

    weight_kg: Yup.number().when('weight_unit', {
      is: (weight_unit) => weight_unit === 'kg',
      then: Yup.number()
        .required('Please enter your weight')
        .min(
          minWeightKG.value,
          intl.formatMessage(
            { id: 'quiz.weight.validation.lower_bmi_limit' },
            { minBMI: MIN_TEST_BMI, minWeight: minWeightKG.value, unit: 'kg' },
          ),
        )
        .max(
          MAX_ALLOWED_WEIGHT_KG,
          intl.formatMessage(
            { id: 'quiz.weight_validation.max_weight' },
            { maxWeight: MAX_ALLOWED_WEIGHT_KG, unit: 'kg' },
          ),
        ),
      otherwise: Yup.number(),
    }),

    // Validation for stones and lbs
    weight_st: Yup.number().when('weight_unit', {
      is: (weight_unit) => weight_unit === 'stone',
      then: Yup.number()
        .required('Please enter your weight')
        .test(
          'min-allowed-weight',
          intl.formatMessage(
            {
              id: 'quiz.ideal_weight.validation.lower_bmi_limit_st_lbs',
            },
            { stone: minWeightStones.stone, lbs: minWeightStones.lbs },
          ),
          function () {
            const { weight_st, weight_st_lbs } = this.parent
            const totalLbs = weight_st * 14 + (weight_st_lbs || 0)
            return totalLbs >= minWeightLBS.value
          },
        )
        .test(
          'max-allowed-weight',
          intl.formatMessage(
            { id: 'quiz.weight_validation.max_weight_st_lbs' },
            { stone: Math.floor(MAX_ALLOWED_WEIGHT_LBS / 14), lbs: MAX_ALLOWED_WEIGHT_LBS % 14 },
          ),
          function () {
            const { weight_st, weight_st_lbs } = this.parent
            const totalLbs = weight_st * 14 + (weight_st_lbs || 0)
            return totalLbs <= MAX_ALLOWED_WEIGHT_LBS
          },
        ),
      otherwise: Yup.number(),
    }),

    weight_st_lbs: Yup.number().when('weight_unit', {
      is: (weight_unit) => weight_unit === 'stone',
      then: Yup.number()
        .notRequired()
        .min(0, 'You cannot enter a negative number')
        .max(13, intl.formatMessage({ id: 'quiz.weight_validation.lbs_in_stone' })),
      otherwise: Yup.number(),
    }),
  })
}

export const simpleWeightValidationSchema = {
  weight_unit: Yup.string().required(),

  weight_lbs: Yup.number().when('weight_unit', {
    is: (weight_unit) => weight_unit === 'lbs',
    then: Yup.number()
      .required('Please enter your weight')
      .min(42, 'Your weight must be above 42 lbs')
      .max(MAX_ALLOWED_WEIGHT_LBS, `Your weight must be below ${MAX_ALLOWED_WEIGHT_LBS} lbs`),
    otherwise: Yup.number(),
  }),

  weight_kg: Yup.number().when('weight_unit', {
    is: (weight_unit) => weight_unit === 'kg',
    then: Yup.number()
      .required('Please enter your weight')
      .min(10, 'Your weight must be above 10 kgs')
      .max(MAX_ALLOWED_WEIGHT_KG, `Your weight must be below ${MAX_ALLOWED_WEIGHT_KG} kgs`),
    otherwise: Yup.number(),
  }),

  // Validation for stones and lbs
  weight_st: Yup.number().when('weight_unit', {
    is: (weight_unit) => weight_unit === 'stone',
    then: Yup.number()
      .required('Please enter your weight')
      .test('min-allowed-weight', 'Your weight must be above 3 st', function () {
        const { weight_st, weight_st_lbs } = this.parent
        const totalLbs = weight_st * 14 + (weight_st_lbs || 0)
        return totalLbs >= 42
      })
      .test('max-allowed-weight', 'Your weight must be below 35 st 10 lbs', function () {
        const { weight_st, weight_st_lbs } = this.parent
        const totalLbs = weight_st * 14 + (weight_st_lbs || 0)
        return totalLbs <= MAX_ALLOWED_WEIGHT_LBS
      }),
    otherwise: Yup.number(),
  }),

  weight_st_lbs: Yup.number().when('weight_unit', {
    is: (weight_unit) => weight_unit === 'stone',
    then: Yup.number()
      .notRequired()
      .min(0, 'You cannot enter a negative number')
      .max(13, "There can't be more than 13 lbs in a stone"),
    otherwise: Yup.number(),
  }),
}
