import React, { useContext, useEffect } from 'react'
import { ExperimentNames } from '../config/experiments.config'
import { appConfig } from '../appConfig'

type ExperimentContextType = {
  isExperimentActive: (name: ExperimentNames) => boolean
  getActiveExperiments: () => ExperimentNames[]
}

type ExperimentContextProps = {
  children: React.ReactNode
}

export const ExperimentContext = React.createContext<ExperimentContextType>({
  isExperimentActive: () => false,
  getActiveExperiments: () => [],
})
export const useExperiments = (): ExperimentContextType => useContext(ExperimentContext)

ExperimentContext.displayName = 'ExperimentContext'

export const ExperimentContextProvider: React.FC<ExperimentContextProps> = (props) => {
  const [experiments, setExperiments] = React.useState<ExperimentNames[]>(appConfig.enabledExperiments || [])

  useEffect(() => {
    window.addEventListener('Zoe::enableExperiment', (event: any) => {
      const experimentName = event.detail
      if (!experiments.includes(experimentName)) {
        setExperiments([...experiments, experimentName])
      }
    })
  }, [])

  const isExperimentActive = (name: ExperimentNames) => {
    return experiments.includes(name)
  }

  const getActiveExperiments = () => {
    return experiments
  }

  return (
    <ExperimentContext.Provider value={{ isExperimentActive, getActiveExperiments }}>
      {props.children}
    </ExperimentContext.Provider>
  )
}
