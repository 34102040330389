export const videos = {
  timSpectorHealthV1: {
    video:
      'https://player.vimeo.com/external/473049595.hd.mp4?s=1f49197a23ae395e31c957362b7771c54f55ba96&profile_id=174',
    videoPoster:
      'https://assets.website-files.com/5ecf9ed04752d36d83e7af9a/5f6cba15bb5afc514f2a0d49_desktop-tim-poster.png',
  },
  timSpectorWeightV1: {
    video:
      'https://player.vimeo.com/external/473050762.hd.mp4?s=59225000107cec96a1cf16b59b8807c9bfe4335f&profile_id=174',
    videoPoster:
      'https://assets.website-files.com/5ecf9ed04752d36d83e7af9a/5f6cba15bb5afc514f2a0d49_desktop-tim-poster.png',
  },
  timExplainerShort: {
    video:
      'https://player.vimeo.com/external/591183928.hd.mp4?s=3f5a9974ad50a6216ebfeede1fd5f902e6278b9b&profile_id=174',
    videoPoster:
      'https://assets.website-files.com/5ecf9ed04752d36d83e7af9a/5f6cba15bb5afc514f2a0d49_desktop-tim-poster.png',
  },
  kirstinShort: {
    video: 'https://stream.mux.com/WUOmyUMxwfvwNmfvmspi8qUwJ8h2DBqT/medium.mp4',
    videoPoster: '/img/quiz/how-it-works-thumbnail.png',
  },
}
