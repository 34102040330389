import React from 'react'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { ContextAwareText } from '../text/ContextAwareText'
import { BackButton } from '../buttons/BackButton'
import { appConfig } from '../../../appConfig'

type Section = {
  title: string
  description: string
  items?: string[]
  footer?: string
}

export type ConsentScreenProps = {
  title: string
  questionKey: string
  description: string
  sections: Section[]
  acceptButtonLabel: string
  declineButtonLabel: string
}

type Props = ConsentScreenProps & {
  onNextClick: onNextClickType
  onBackClick: onBackClickCallback
}

export const ConsentScreen: React.FC<Props> = ({
  title,
  questionKey,
  description,
  sections,
  acceptButtonLabel,
  declineButtonLabel,
  onNextClick,
  onBackClick,
}) => {
  const menoscaleFaq = (
    <a
      href={`${appConfig.zoeWebsiteBaseUri}/menoscale#faq`}
      rel="noreferrer"
      target="_blank"
      className="text-zoe-primary font-bold"
    >
      FAQ
    </a>
  )

  return (
    <div>
      <div className="flex items-center justify-center align-center">
        {onBackClick && <BackButton onBackClick={onBackClick} />}
      </div>
      <main className="grid-cols-1">
        <div className="flex justify-center">
          <div className="mt-10 mb-2 mx-6 max-w-info-width-mobile sm:max-w-info-width-desktop">
            <h2 className="mb-4 text-4xl font-medium">{title}</h2>
            <h4 className="text-base-secondary font-light mt-6">
              <ContextAwareText text={description} />
            </h4>
            {sections.map((section, index) => (
              <React.Fragment key={index}>
                <h3 className="text-xl font-normal mt-6 mb-2 font-primary">
                  <ContextAwareText text={section.title} />
                </h3>
                <p className="font-light text-base mt-2">
                  <ContextAwareText text={section.description} additionalTokens={{ menoscaleFaq }} />
                </p>
                {section.items && (
                  <ul className="list-disc pl-6 mt-2">
                    {section.items.map((item, index) => (
                      <li key={index} className="font-light text-base">
                        <ContextAwareText text={item} />
                      </li>
                    ))}
                  </ul>
                )}
                {section.footer && (
                  <p className="font-light text-base mt-2">
                    <ContextAwareText text={section.footer} />
                  </p>
                )}
              </React.Fragment>
            ))}
            <div className="flex mt-4 mb-6 justify-center">
              {/* TODO: Replace with DS button once DSs is available in this repo */}
              <button
                className="box-border appearance-none bg-button-primary hover:bg-button-secondary py-4 px-8 rounded-md cursor-pointer focus:outline-none disabled:bg-zoe-disabled disabled:cursor-not-allowed"
                data-testid="continue-button"
                onClick={() => onNextClick({ [questionKey]: true })}
              >
                {acceptButtonLabel}
              </button>
            </div>
            <div className="flex mt-4 mb-4 justify-center">
              {/* TODO: Replace with DS button once DSs is available in this repo */}
              <button
                className="box-border border-2 appearance-none py-4 px-8 rounded-md cursor-pointer focus:outline-none disabled:bg-zoe-disabled disabled:cursor-not-allowed"
                style={{
                  background: '#fff',
                  borderColor: 'rgba(45, 50, 70, 1)',
                }}
                data-testid="continue-button"
                onClick={() => onNextClick({ [questionKey]: false })}
              >
                {declineButtonLabel}
              </button>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}
