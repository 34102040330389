export type WeightGraphData = {
  weightLossGraph: string | null
  averageWeightLoss: number
  highWeightLoss: number
  heightMin: number
  heightMax: number
  weightMin: number
  weightMax: number
}

export const lbsGraphs = [
  // height < 164
  {
    heightMin: 0,
    heightMax: 164,
    weightMin: 0,
    weightMax: 119,
    weightLossGraph: '/lbs/w<119_h<164.svg',
    averageWeightLoss: 5,
    highWeightLoss: 8,
  },
  {
    heightMin: 0,
    heightMax: 164,
    weightMin: 120,
    weightMax: 149,
    weightLossGraph: '/lbs/w120-149_h<164.svg',
    averageWeightLoss: 11,
    highWeightLoss: 30,
  },
  {
    heightMin: 0,
    heightMax: 164,
    weightMin: 150,
    weightMax: 189,
    weightLossGraph: '/lbs/w150-189_h<164.svg',
    averageWeightLoss: 13,
    highWeightLoss: 47,
  },
  {
    heightMin: 0,
    heightMax: 164,
    weightMin: 190,
    weightMax: 219,
    weightLossGraph: '/lbs/w190-219_h<164.svg',
    averageWeightLoss: 16,
    highWeightLoss: 65,
  },
  {
    heightMin: 0,
    heightMax: 164,
    weightMin: 220,
    weightMax: 999,
    weightLossGraph: '/lbs/w>220_h<164.svg',
    averageWeightLoss: 16,
    highWeightLoss: 23,
  },

  // height 165 - 174
  {
    heightMin: 165,
    heightMax: 174,
    weightMin: 0,
    weightMax: 119,
    weightLossGraph: null,
    averageWeightLoss: 5,
    highWeightLoss: 8,
  },
  {
    heightMin: 165,
    heightMax: 174,
    weightMin: 120,
    weightMax: 149,
    weightLossGraph: '/lbs/w120-149_h165-174.svg',
    averageWeightLoss: 9,
    highWeightLoss: 10,
  },
  {
    heightMin: 165,
    heightMax: 174,
    weightMin: 150,
    weightMax: 189,
    weightLossGraph: '/lbs/w150-189_h165-174.svg',
    averageWeightLoss: 12,
    highWeightLoss: 50,
  },
  {
    heightMin: 165,
    heightMax: 174,
    weightMin: 190,
    weightMax: 219,
    weightLossGraph: '/lbs/w190-219_h165-174.svg',
    averageWeightLoss: 16,
    highWeightLoss: 45,
  },
  {
    heightMin: 165,
    heightMax: 174,
    weightMin: 220,
    weightMax: 999,
    weightLossGraph: '/lbs/w>220_h165-174.svg',
    averageWeightLoss: 20,
    highWeightLoss: 30,
  },

  // height 175 - 184
  {
    heightMin: 175,
    heightMax: 184,
    weightMin: 0,
    weightMax: 119,
    weightLossGraph: null,
    averageWeightLoss: 5,
    highWeightLoss: 8,
  },
  {
    heightMin: 175,
    heightMax: 184,
    weightMin: 120,
    weightMax: 149,
    weightLossGraph: null,
    averageWeightLoss: 7,
    highWeightLoss: 25,
  },
  {
    heightMin: 175,
    heightMax: 184,
    weightMin: 150,
    weightMax: 189,
    weightLossGraph: '/lbs/w150-189_h175-184.svg',
    averageWeightLoss: 12,
    highWeightLoss: 25,
  },
  {
    heightMin: 175,
    heightMax: 184,
    weightMin: 190,
    weightMax: 219,
    weightLossGraph: '/lbs/w190-219_h175-184.svg',
    averageWeightLoss: 15,
    highWeightLoss: 25,
  },
  {
    heightMin: 175,
    heightMax: 184,
    weightMin: 220,
    weightMax: 999,
    weightLossGraph: '/lbs/w>220_h175-184.svg',
    averageWeightLoss: 16,
    highWeightLoss: 30,
  },

  // height > 185
  {
    heightMin: 185,
    heightMax: 999,
    weightMin: 0,
    weightMax: 119,
    weightLossGraph: null,
    averageWeightLoss: 5,
    highWeightLoss: 8,
  },
  {
    heightMin: 185,
    heightMax: 999,
    weightMin: 120,
    weightMax: 149,
    weightLossGraph: null,
    averageWeightLoss: 14,
    highWeightLoss: 25,
  },
  {
    heightMin: 185,
    heightMax: 999,
    weightMin: 150,
    weightMax: 189,
    weightLossGraph: '/lbs/w150-189_h>185.svg',
    averageWeightLoss: 12,
    highWeightLoss: 14,
  },
  {
    heightMin: 185,
    heightMax: 999,
    weightMin: 190,
    weightMax: 219,
    weightLossGraph: '/lbs/w190-219_h>185.svg',
    averageWeightLoss: 13,
    highWeightLoss: 22,
  },
  {
    heightMin: 185,
    heightMax: 999,
    weightMin: 220,
    weightMax: 999,
    weightLossGraph: '/lbs/w>220_h>185.svg',
    averageWeightLoss: 19,
    highWeightLoss: 30,
  },
]

export const kgsGraphs = [
  // height < 164
  {
    heightMin: 0,
    heightMax: 164,
    weightMin: 0,
    weightMax: 54,
    weightLossGraph: '/kg/w<54_h<164.svg',
    averageWeightLoss: 2.3,
    highWeightLoss: 3.6,
  },
  {
    heightMin: 0,
    heightMax: 164,
    weightMin: 55,
    weightMax: 68,
    weightLossGraph: '/kg/w55-68_h<164.svg',
    averageWeightLoss: 5,
    highWeightLoss: 13.6,
  },
  {
    heightMin: 0,
    heightMax: 164,
    weightMin: 69,
    weightMax: 86,
    weightLossGraph: '/kg/w69-86_h<164.svg',
    averageWeightLoss: 5.9,
    highWeightLoss: 21.3,
  },
  {
    heightMin: 0,
    heightMax: 164,
    weightMin: 87,
    weightMax: 99,
    weightLossGraph: '/kg/w87-99_h<164.svg',
    averageWeightLoss: 7.3,
    highWeightLoss: 29.5,
  },
  {
    heightMin: 0,
    heightMax: 164,
    weightMin: 100,
    weightMax: 999,
    weightLossGraph: '/kg/w>100_h<164.svg',
    averageWeightLoss: 7.3,
    highWeightLoss: 10.4,
  },

  // height 165 - 174
  {
    heightMin: 165,
    heightMax: 174,
    weightMin: 0,
    weightMax: 54,
    weightLossGraph: null,
    averageWeightLoss: 2.3,
    highWeightLoss: 3.6,
  },
  {
    heightMin: 165,
    heightMax: 174,
    weightMin: 55,
    weightMax: 68,
    weightLossGraph: '/kg/w55-68_h165-174.svg',
    averageWeightLoss: 4,
    highWeightLoss: 4.5,
  },
  {
    heightMin: 165,
    heightMax: 174,
    weightMin: 69,
    weightMax: 86,
    weightLossGraph: '/kg/w69-86_h165-174.svg',
    averageWeightLoss: 5.4,
    highWeightLoss: 22.7,
  },
  {
    heightMin: 165,
    heightMax: 174,
    weightMin: 87,
    weightMax: 99,
    weightLossGraph: '/kg/w87-99_h165-174.svg',
    averageWeightLoss: 7.3,
    highWeightLoss: 20.4,
  },
  {
    heightMin: 165,
    heightMax: 174,
    weightMin: 100,
    weightMax: 999,
    weightLossGraph: '/kg/w>100_h165-174.svg',
    averageWeightLoss: 9.1,
    highWeightLoss: 13.6,
  },

  // height 175 - 184
  {
    heightMin: 175,
    heightMax: 184,
    weightMin: 0,
    weightMax: 54,
    weightLossGraph: null,
    averageWeightLoss: 2.3,
    highWeightLoss: 3.6,
  },
  {
    heightMin: 175,
    heightMax: 184,
    weightMin: 55,
    weightMax: 68,
    weightLossGraph: null,
    averageWeightLoss: 3.2,
    highWeightLoss: 11.3,
  },
  {
    heightMin: 175,
    heightMax: 184,
    weightMin: 69,
    weightMax: 86,
    weightLossGraph: '/kg/w69-86_h175-184.svg',
    averageWeightLoss: 5.4,
    highWeightLoss: 11.3,
  },
  {
    heightMin: 175,
    heightMax: 184,
    weightMin: 87,
    weightMax: 99,
    weightLossGraph: '/kg/w87-99_h175-184.svg',
    averageWeightLoss: 6.8,
    highWeightLoss: 11.3,
  },
  {
    heightMin: 175,
    heightMax: 184,
    weightMin: 100,
    weightMax: 999,
    weightLossGraph: '/kg/w>100_h175-184.svg',
    averageWeightLoss: 7.3,
    highWeightLoss: 13.6,
  },

  // height > 185
  {
    heightMin: 185,
    heightMax: 999,
    weightMin: 0,
    weightMax: 54,
    weightLossGraph: null,
    averageWeightLoss: 2.3,
    highWeightLoss: 3.6,
  },
  {
    heightMin: 185,
    heightMax: 999,
    weightMin: 55,
    weightMax: 68,
    weightLossGraph: null,
    averageWeightLoss: 6.4,
    highWeightLoss: 11.3,
  },
  {
    heightMin: 185,
    heightMax: 999,
    weightMin: 69,
    weightMax: 86,
    weightLossGraph: '/kg/w69-86_h>185.svg',
    averageWeightLoss: 5.4,
    highWeightLoss: 6.4,
  },
  {
    heightMin: 185,
    heightMax: 999,
    weightMin: 87,
    weightMax: 99,
    weightLossGraph: '/kg/w87-99_h>185.svg',
    averageWeightLoss: 5.9,
    highWeightLoss: 11.3,
  },
  {
    heightMin: 185,
    heightMax: 999,
    weightMin: 100,
    weightMax: 999,
    weightLossGraph: '/kg/w>100_h>185.svg',
    averageWeightLoss: 8.6,
    highWeightLoss: 13.6,
  },
]
