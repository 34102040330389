import React from 'react'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { Quizzes } from 'redux/quiz/types'
import { lbsLeftOverFromTotalLbs, stoneFromTotalLbs, weightRange } from 'libs/weight/weightCalculations'
import { ExplanationTemplate } from 'components/screen-templates/ExplanationTemplate'
import { FormattedMessage, useIntl } from 'react-intl'

export type ZoeCanHelpScreenProps = {
  title: string
  topImage: string
  description: string
  buttonText: string
}

type Props = ZoeCanHelpScreenProps & {
  onNextClick: onNextClickType
  onBackClick?: onBackClickCallback
  quiz: Quizzes
}

export const ZoeCanHelpScreen: React.FC<Props> = ({
  title,
  description,
  buttonText,
  topImage,
  quiz,
  onNextClick,
  onBackClick,
}) => {
  const intl = useIntl()

  const hasWeightGoal = quiz.has_weight_goal === 'true'
  const hasWeightUnitStone = quiz.weight_unit === 'stone'
  const goalStringId = hasWeightGoal
    ? hasWeightUnitStone
      ? 'quiz.zoe_can_help.weight_goal_stone.v5'
      : 'quiz.zoe_can_help.weight_goal.v5'
    : 'quiz.zoe_can_help.health_goal.v5'

  const { gender, age_range, health_goal } = quiz
  const { lowerWeight, upperWeight } = weightRange(quiz)

  const goalTokens = {
    gender: intl.formatMessage({
      id: `quiz.gender.${gender}.display_plural`,
    }),
    ageRange: intl.formatMessage({
      id: `quiz.age_range.${age_range}`,
    }),
    healthGoal: intl.formatMessage({
      id: `quiz.health_goal.${health_goal}.short.v5`,
    }),
    ...(hasWeightGoal &&
      !hasWeightUnitStone && {
        lowerWeight: lowerWeight?.value,
        upperWeight: upperWeight?.value,
      }),
    ...(hasWeightGoal &&
      hasWeightUnitStone && {
        lowerWeightStone: lowerWeight?.value && stoneFromTotalLbs(lowerWeight.value),
        lowerWeightLbs: lowerWeight?.value && lbsLeftOverFromTotalLbs(lowerWeight.value),
        upperWeightStone: upperWeight?.value && stoneFromTotalLbs(upperWeight.value),
        upperWeightLbs: upperWeight?.value && lbsLeftOverFromTotalLbs(upperWeight.value),
      }),
    unit: quiz.weight_unit || 'lbs',
  }

  return (
    <ExplanationTemplate
      image={topImage}
      imageAlt=""
      title={title}
      subTitle={<FormattedMessage id={goalStringId} values={goalTokens} />}
      body={description}
      cta={buttonText}
      onNextClick={onNextClick}
      onBackClick={onBackClick}
    />
  )
}
