import { MultiValueFormValues } from '../../components/screen-templates/FormScreen'
import { SupportedCountries } from '../geoLocation'
import { HeightUnit } from '../weight/types'

export const getFormattedHeightValues = (values: MultiValueFormValues): MultiValueFormValues => {
  const updatedValues = { ...values }
  const { height_unit } = values

  if (height_unit === 'ft') {
    delete updatedValues.height_cm
    updatedValues.height_in = updatedValues.height_in || 0
  } else {
    delete updatedValues.height_ft
    delete updatedValues.height_in
  }

  return updatedValues
}

export const getDefaultHeightUnitByCountry = (country: SupportedCountries): HeightUnit => {
  switch (country) {
    case 'GB':
      return 'ft'
    default:
      return 'cm'
  }
}
