import { appConfig } from 'appConfig'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export type Quote = {
  quoteTextId: string
  author: string
  authorRole: string
  logo: string
  logoAlt: string
}

interface QuoteCardProps {
  quote: Quote
}

export const QuoteCard: React.FC<QuoteCardProps> = ({ quote }) => {
  return (
    <div className="max-w-card-width-mobile sm:max-w-card-width-desktop mx-auto pt-12 pb-10 px-6 bg-white rounded-2xl">
      <img className="h-6 mx-auto" src={`${appConfig.publicUrl}${quote.logo}`} alt={quote.logoAlt} />

      <div className="mt-6">
        <img className="float-left mr-2" src={`${appConfig.publicUrl}/img/icons/quote.svg`} alt="Quote" />
        <p className="text-body-m font-light">
          <FormattedMessage id={quote.quoteTextId} />
        </p>
      </div>
      <div className="mt-4">
        <p className="">{quote.author}</p>
        <p className="light">{quote.authorRole}</p>
      </div>
    </div>
  )
}
