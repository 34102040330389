import React from 'react'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { ExplanationTemplate } from 'components/screen-templates/ExplanationTemplate'
import { HealthStatCardContent } from '../../screen-components/HealthStatCardContent'
import { StatisticCard } from '../../screen-components/StatisticCard'
import { ContextAwareText } from '../text/ContextAwareText'
import { Quizzes } from '../../../redux/quiz/types'

export type InfoScreenProps = {
  title: string
  description?: string
  footerBody?: string
  externalLinks?: { url: string; text: string }[]
  topImage?: string
  descriptionBelowTopImage?: string
  topImageLink?: string
  buttonText?: string
  hideContinueButton?: boolean
  attribution?: string
  attributionImage?: string
  healthStat?:
    | 'aging'
    | 'gut_health'
    | 'heart_health'
    | 'strength'
    | 'brain_health'
    | 'diabetes'
    | 'immune_system'
    | 'weight_plan'
    | 'other'
  showHomePageLink?: boolean
}

type Props = InfoScreenProps & {
  onNextClick: onNextClickType
  onBackClick: onBackClickCallback
  quiz: Quizzes
}

interface HealthGoalStatisticCardProps {
  healthStat:
    | 'aging'
    | 'gut_health'
    | 'heart_health'
    | 'strength'
    | 'brain_health'
    | 'diabetes'
    | 'immune_system'
    | 'weight_plan'
    | 'other'
}

interface Stats {
  statisticFigure: number
  strapline: string
  healthText?: string
}

interface HealthGoalData {
  [name: string]: Stats
}

const feelHealthier = {
  statisticFigure: 84,
  strapline: 'quiz.results.statistic_card.feel_healthier',
  healthText: 'quiz.results.statistic_card.improvements.feel_healthier.content.item_',
}
const gutHealth = {
  statisticFigure: 85,
  strapline: 'quiz.results.statistic_card.gut_health',
  healthText: 'quiz.results.statistic_card.improvements.gut_health.content.item_',
}
const moreEnergy = {
  statisticFigure: 70,
  strapline: 'quiz.results.statistic_card.more_energy',
}
const weightPlan = {
  statisticFigure: 74,
  strapline: 'quiz.advice.weight_loss.statistic.long_term.strapline',
}

const healthGoalData: HealthGoalData = {
  aging: feelHealthier,
  gut_health: gutHealth,
  heart_health: feelHealthier,
  strength: moreEnergy,
  brain_health: feelHealthier,
  diabetes: feelHealthier,
  immune_system: feelHealthier,
  weight_plan: weightPlan,
  other: feelHealthier,
}

const HealthGoalStatisticCard: React.FC<HealthGoalStatisticCardProps> = ({ healthStat }) => {
  const healthGoalProps = healthGoalData[healthStat]

  const footnoteContent = healthGoalProps.healthText && (
    <HealthStatCardContent
      title={'quiz.results.statistic_card.improvements.content.title'}
      text={healthGoalProps.healthText}
    />
  )

  return (
    <div className="text-center xs-max-w-card-width-desktop mx-auto">
      <StatisticCard
        statisticFigure={healthGoalProps.statisticFigure}
        statisticUnit={'%'}
        strapline={healthGoalProps.strapline}
        footnote={'quiz.results.statistic_card.footnote'}
        greyBorder={true}
        footnoteContent={footnoteContent}
      />
    </div>
  )
}

export const InfoScreen: React.FC<Props> = (props) => {
  const healthStat = props.healthStat ? <HealthGoalStatisticCard healthStat={props.healthStat} /> : null
  return (
    <ExplanationTemplate
      image={props.topImage}
      descriptionBelowImage={
        props.descriptionBelowTopImage ? (
          <ContextAwareText text={props.descriptionBelowTopImage} quiz={props.quiz} />
        ) : undefined
      }
      title={props.title}
      subTitle={<ContextAwareText text={props.description} quiz={props.quiz} />}
      footerBody={healthStat ? undefined : props.footerBody ? <ContextAwareText text={props.footerBody} /> : undefined}
      attribution={props.attribution}
      attributionImage={props.attributionImage}
      cta={props.buttonText ?? 'Continue'}
      healthStat={healthStat}
      onNextClick={props.onNextClick}
      onBackClick={props.onBackClick}
      showHomepageLink={props.showHomePageLink ?? false}
      hideContinueButton={props.hideContinueButton ?? false}
      externalLinks={props.externalLinks}
    />
  )
}
