import { appConfig } from 'appConfig'
import { Button } from 'components/core/buttons/Button'
import { onNextClickType } from 'components/types'
import React from 'react'
import { FormattedMessage } from 'react-intl'

interface Advice1FooterProps {
  buttonText: string
  onNextClick: onNextClickType
}

const journalIcons = [
  '/img/logos/nature-medicine.svg',
  '/img/logos/science-magazine.svg',
  '/img/logos/lancet-magazine.svg',
]

const renderJournalIcons = () => {
  return journalIcons.map((icon) => {
    return <img src={`${appConfig.publicUrl}${icon}`} key={icon} className="mb-6 flex-grow mx-auto" />
  })
}

export const Advice1Footer: React.FC<Advice1FooterProps> = ({ buttonText, onNextClick }) => {
  // Left button colour and text styling to be consistent with rest of quiz. Doesn't match Figma design.
  return (
    <div className="pt-16 pb-12 max-w-card-width-mobile sm:max-w-card-width-desktop mx-auto text-center">
      <h2 className="light mb-6 text-2xl leading-8 sm:text-display-2xs">
        <FormattedMessage id="quiz.advice.footer.title" />
      </h2>
      <p className="light">
        <FormattedMessage id="quiz.advice.study" />
      </p>
      <div className="w-100 h-px bg-gray-200 my-6"></div>
      <p className="light mb-6">
        <FormattedMessage id="quiz.advice.journals" />
      </p>
      {renderJournalIcons()}
      <div className="w-100 h-px bg-gray-200 my-12"></div>

      <div className="rounded-t-2xl">
        <Button onClick={onNextClick}>{buttonText}</Button>
      </div>
    </div>
  )
}
