import React from 'react'

import { IntlConfig } from 'react-intl'

export const defaultRichTextElements: IntlConfig['defaultRichTextElements'] = {
  // eslint-disable-next-line
  b: (v) => <b>{v}</b>,
  em: (v) => <em>{v}</em>,
  u: (v) => <span className="border-b-2 border-surface-yellow">{v}</span>,
  footnote: (v) => <span className="text-sm">{v}</span>,
}
