import { QuizConfigMap } from './types'
import v5Config from './v5.config'
import menoscaleConfig from './menoscale.config'

const DEFAULT_QUIZ_CONFIG: QuizConfigMap = {
  v5: v5Config,
  menoscale: menoscaleConfig,
} as const

export default DEFAULT_QUIZ_CONFIG
