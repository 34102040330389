import React from 'react'
import { FormScreen } from '../../screen-templates/FormScreen'
import { onBackClickCallback, onNextClickType } from 'components/types'
import * as Yup from 'yup'
import { RadioButton } from 'components/core/fields/RadioButton'
import { ContextAwareText } from '../text/ContextAwareText'
import { Quizzes } from '../../../redux/quiz/types'

type Option = {
  label: string
  value: string
}
type Question = {
  questionKey: string
  question: string
  options: Option[]
}

export type MultipleQuestionsScreenProps = {
  title: string
  buttonText?: string
  questions: Question[]
  dividerSectionText?: string
}

type Props = MultipleQuestionsScreenProps & {
  onBackClick?: onBackClickCallback
  onNextClick: onNextClickType
  quiz: Quizzes
}
export const MultipleQuestionsScreen: React.FC<Props> = ({
  title,
  buttonText,
  questions,
  quiz,
  dividerSectionText,
  onNextClick,
  onBackClick,
}) => {
  const formBody = questions.map((question, index) => (
    <div key={index}>
      <div className={`light text-base-secondary mb-6 ${index > 0 ? 'mt-6' : ''}`}>
        <ContextAwareText text={question.question} quiz={quiz} />
      </div>
      <div className="flex justify-evenly">
        {question.options.map((option, index) => (
          <>
            <RadioButton
              key={index}
              name={question.questionKey}
              id={option.value}
              value={option.value}
              label={option.label}
            />
            {index < question.options.length - 1 && <div className="mx-1" />}
          </>
        ))}
      </div>
    </div>
  ))

  const validationSchemaEntries = questions.map((question) => [question.questionKey, Yup.string().required()])

  return (
    <FormScreen
      screenTitle={title}
      dividerSectionLogo={dividerSectionText ? '/img/logos/zoe-circle.svg' : undefined}
      dividerSectionText={<ContextAwareText text={dividerSectionText} />}
      buttonText={buttonText ?? 'Continue'}
      form={{
        body: <>{formBody}</>,
        validationSchema: Yup.object(Object.fromEntries(validationSchemaEntries)),
      }}
      onNextClick={onNextClick}
      onBackClick={onBackClick}
    />
  )
}
