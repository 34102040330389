import { FormScreen } from 'components/screen-templates/FormScreen'
import { onBackClickCallback, onNextClickType } from 'components/types'
import React from 'react'
import { appConfig } from '../../appConfig'
import { ContextAwareText } from '../core/text/ContextAwareText'

export type SorryToSeeYouGoScreenProps = {
  title: string
  description: string
  image: string
  imageAlt: string
  imageLink: string
  zoeContactEmail: string
}

type Props = SorryToSeeYouGoScreenProps & {
  onNextClick: onNextClickType
  onBackClick: onBackClickCallback
}

const SorryToSeeYouGoScreenDescription: React.FC<Props> = (props) => {
  return (
    <>
      <ContextAwareText
        text={props.description}
        additionalTokens={{
          zoeContactEmail: (
            <a href={`mailto:${props.zoeContactEmail}`} className="text-zoe-primary">
              {props.zoeContactEmail}
            </a>
          ),
        }}
      />
      <a href={`${appConfig.zoeWebsiteBaseUri}${props.imageLink}`} target="_blank" rel="noreferrer">
        <img src={props.image} alt={props.imageAlt} className="mt-10 rounded-2xl shadow-blog-card" />
      </a>
    </>
  )
}

export const SorryToSeeYouGoScreen: React.FC<Props> = (props) => {
  return (
    <FormScreen
      screenTitle={props.title}
      description={<SorryToSeeYouGoScreenDescription {...props} />}
      hideButton={true}
      form={{
        body: <></>,
      }}
      onNextClick={props.onNextClick}
      onBackClick={props.onBackClick}
    />
  )
}
