import { appConfig } from 'appConfig'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const renderImprovementsList = (text: string) => {
  return [...Array(5).keys()].map((number) => {
    return (
      <li key={number} className="flex flex-row mt-4">
        <img src={`${appConfig.publicUrl}/img/icons/tick.svg`} alt={`Point ${number + 1}`} />
        <p className="ml-4 light text-lg">
          <FormattedMessage id={`${text}${number + 1}`} />
        </p>
      </li>
    )
  })
}

interface HealthStatCardContent {
  title: string
  text: string
}

export const HealthStatCardContent: React.FC<HealthStatCardContent> = ({ title, text }) => {
  return (
    <>
      <p className="uppercase light text-xs">
        <FormattedMessage id={title} />
      </p>
      <ul>{renderImprovementsList(text)}</ul>
    </>
  )
}
