import * as Sentry from '@sentry/react'

export type KameleoonExperiment = {
  name: string
  id: string
  associatedVariation: {
    name: string
    id: string
  }
}

export const GOALS = {
  QUIZ_EMAIL_SUBMITTED: 335808,
  QUIZ_EMAIL_SUBMITTED_WITH_MARKETING_OPT_OUT: 335948,
  QUIZ_EMAIL_SUBMITTED_WITH_MARKETING_CONSENT: 336433,
  MENOSCALE_EMAIL_SUBMITTED: 350562,
  MENOSCALE_EMAIL_SUBMITTED_WITH_MARKETING_OPT_OUT: 350564,
  MENOSCALE_EMAIL_SUBMITTED_WITH_MARKETING_CONSENT: 350565,
}

export const KAMELEOON_GLOBAL_VARIABLE_NAME = 'Kameleoon'
export const KAMELEOON_QUEUE_NAME = 'kameleoonQueue'
export function sendExperimentViewedToGA4(experiment: KameleoonExperiment): void {
  const zoeDataLayer = (window as any).zoeDataLayer || []
  const eventAlreadyExists = zoeDataLayer.some(
    (event: any) =>
      event.event === 'experiment_viewed' &&
      event.experiment_id === experiment.name &&
      event.variant_id === experiment.associatedVariation.name,
  )

  if (!eventAlreadyExists) {
    zoeDataLayer.push({
      event: 'experiment_viewed',
      experiment_id: experiment.name,
      variant_id: experiment.associatedVariation.name,
    })
  }
}

export const safelyCallKameleoon = (method: (...args: any[]) => any, ...args: any[]): any => {
  if (typeof method === 'function') {
    try {
      return method(...args)
    } catch (e) {
      Sentry.captureException(e)
    }
  }
}
