import { appConfig } from 'appConfig'
import React from 'react'
import { FormattedMessage } from 'react-intl'

export const ZoeScoresStatCardContent: React.FC = () => {
  return (
    <>
      <div className="flex flex-row justify-center text-center">
        <div className="mr-3">
          <img
            src={`${appConfig.publicUrl}/img/quiz/zoe-score-banana.png`}
            alt="ZOE Score of 32 for banana"
            className="sm:w-3/4 ml-auto"
          />
        </div>
        <div className="ml-3">
          <img
            src={`${appConfig.publicUrl}/img/quiz/zoe-score-cheese.png`}
            alt="ZOE score of 67 for cheese"
            className="sm:w-3/4 mr-auto"
          />
        </div>
      </div>
      <p className="light mt-6">
        <FormattedMessage id="quiz.advice.weight_loss.statistic.zoe_scores.explanation" />
      </p>
    </>
  )
}
