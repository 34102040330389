import React, { useEffect, useMemo, useState } from 'react'

export interface LoadingBarProps {
  loadingMessage: React.ReactNode
  halfwayLoadedMessage?: React.ReactNode
  completeMessage: React.ReactNode
  loadingTime?: number
  onComplete?: () => void
}

// FIXME: Might be memory leak
export const LoadingBar: React.FC<LoadingBarProps> = ({
  loadingMessage,
  halfwayLoadedMessage,
  completeMessage,
  loadingTime = 5000, //time to load in ms
  onComplete,
}) => {
  const [progress, setProgress] = useState(0)
  const [switchMessage, setSwitchMessage] = useState(false)
  const complete = useMemo(() => progress >= loadingTime, [loadingTime, progress])
  const percentageComplete = ((progress / loadingTime) * 100).toFixed(2)
  const stepTime = 50

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSwitchMessage(true)
    }, 5000)
    return () => clearTimeout(timeout)
  }, [])

  useEffect(() => {
    const updateProgress = () => setProgress(progress + stepTime)

    const timeout = setTimeout(() => {
      if (complete) {
        onComplete && onComplete()
      } else {
        updateProgress()
      }
    }, stepTime)
    return () => clearTimeout(timeout)
  }, [loadingTime, progress, onComplete])

  return (
    <div className="flex flex-col">
      <div className="h-3px w-full bg-background-tertiary">
        <div className="h-3px w-full bg-background-progress-bar" style={{ width: percentageComplete + '%' }} />
      </div>
      <div className="pt-4 text-progress-loading-bar text-left light text-base-secondary mt-8">
        <h2>
          {complete ? completeMessage : halfwayLoadedMessage && switchMessage ? halfwayLoadedMessage : loadingMessage}
        </h2>
      </div>
    </div>
  )
}
