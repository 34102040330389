import React from 'react'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { ProgressLoadingScreen } from 'components/screen-templates/ProgressLoadingScreen'
import { videos } from '../../videoVersions'
import { ContextAwareText } from '../text/ContextAwareText'

export type VideoScreenProps = {
  loadingMessage: string
  buttonText: string
  videoVersion: keyof typeof videos
  showBackLink?: boolean
}

type Props = VideoScreenProps & {
  onBackClick?: onBackClickCallback
  onNextClick: onNextClickType
}

export const VideoScreen: React.FC<Props> = (props) => {
  return (
    <ProgressLoadingScreen
      loadingMessage={<ContextAwareText text={props.loadingMessage} />}
      videoVersion={props.videoVersion}
      buttonText={props.buttonText}
      onNextClick={props.onNextClick}
      onBackClick={props.showBackLink ? props.onBackClick : undefined}
    />
  )
}
