import React, { useCallback, useState, useRef, useEffect } from 'react'
import { ScreenProps } from 'components/types'
import { LoadingBar } from 'components/core/bars/LoadingBar'
import { Button } from 'components/core/buttons/Button'
import { appConfig } from 'appConfig'
import { videos } from 'components/videoVersions'
import TagManager from 'react-gtm-module'
import { VideoAction, videoEventDataLayer } from 'components/gtmV2'
import { FormattedMessage, useIntl } from 'react-intl'
import { BackButton } from '../core/buttons/BackButton'

export interface ProgressLoadingScreenProps extends ScreenProps {
  headerBar?: React.ReactNode
  loadingMessage?: React.ReactNode
  halfwayLoadedMessage?: React.ReactNode
  loadingTime?: number
  screenTitle?: React.ReactNode
  titleSeparator?: React.ReactNode
  titleAboveMedia?: React.ReactNode
  subtitleAboveMedia?: React.ReactNode
  descriptionAboveMedia?: React.ReactNode
  image?: string
  imageAlt?: string
  videoVersion?: keyof typeof videos
  titleBelowMedia?: React.ReactNode
  footnote?: React.ReactNode
  otherImages?: string[]
  buttonText: React.ReactNode
}

export const ProgressLoadingScreen: React.FC<ProgressLoadingScreenProps> = ({
  headerBar,
  loadingMessage,
  halfwayLoadedMessage,
  loadingTime,
  screenTitle,
  titleSeparator,
  titleAboveMedia,
  subtitleAboveMedia,
  descriptionAboveMedia,
  image,
  imageAlt,
  videoVersion,
  titleBelowMedia,
  footnote,
  otherImages,
  buttonText,
  onNextClick,
  onBackClick,
}) => {
  const intl = useIntl()

  const [nextButtonEnabled, setNextButtonEnabled] = useState(loadingMessage ? false : true)
  const [isMuted, setIsMuted] = useState(true)
  const [muteButtonShrunk, setMuteButtonShrunk] = useState(false)
  const [playtime, setPlaytime] = useState(0)
  const setPlaytimeToMax = (newTime: number) => setPlaytime((x) => Math.max(x, newTime))
  const videoRef = useRef<HTMLVideoElement>(null)

  const videoEventHandler = () => {
    if (videoRef.current) {
      setPlaytimeToMax(videoRef.current.currentTime)
    }
  }

  useEffect(() => {
    videoRef.current && videoRef.current.addEventListener('timeupdate', videoEventHandler)
    return () => {
      videoRef.current && videoRef.current.removeEventListener('timeupdate', videoEventHandler)
    }
  }, [videoRef])

  const trackGtmVideoEventV2 = (playtime: number, videoAction: VideoAction) => {
    videoVersion &&
      TagManager.dataLayer({
        dataLayer: videoEventDataLayer(videoVersion, videoAction, playtime),
      })
  }

  const nextPageHandler = useCallback(
    (playtime: number) => {
      trackGtmVideoEventV2(playtime, 'Complete')
      onNextClick()
    },
    [onNextClick],
  )

  const loadingCompleteHandler = useCallback(() => {
    setNextButtonEnabled(true)
  }, [setNextButtonEnabled])

  const muteButtonHandler = useCallback(() => {
    setIsMuted(!isMuted)
    trackGtmVideoEventV2(playtime, isMuted ? 'Unmute' : 'Mute')
  }, [isMuted, setIsMuted])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMuteButtonShrunk(true)
    }, 5000)
    return () => clearTimeout(timeout)
  }, [])

  return (
    <div>
      {headerBar}
      <div className="flex items-center justify-center align-center">
        {onBackClick && <BackButton onBackClick={onBackClick} />}
      </div>
      <main className="flex justify-center">
        <div className="mt-10 max-w-info-width-mobile sm:max-w-info-width-desktop text-center">
          {loadingMessage && (
            <LoadingBar
              loadingMessage={loadingMessage || <FormattedMessage id="quiz.science.progress.loading" />}
              halfwayLoadedMessage={halfwayLoadedMessage || ''}
              loadingTime={loadingTime}
              completeMessage={<FormattedMessage id="quiz.science.progress.complete" />}
              onComplete={loadingCompleteHandler}
            />
          )}

          {screenTitle}
          {titleSeparator}
          {titleAboveMedia && <h4 className="mt-12 text-left">{titleAboveMedia}</h4>}
          {subtitleAboveMedia && <h3 className="light mb-10 mt-8 sm:leading-8 text-left">{subtitleAboveMedia}</h3>}
          {descriptionAboveMedia && <p className="light mt-4 text-left">{descriptionAboveMedia}</p>}

          {image && (
            <div className="mt-10">
              <img src={`${image}`} alt={imageAlt} className="mb-6 mx-auto" />
              {/*<hr className="mb-6 bg-background-tertiary" />*/}
            </div>
          )}

          {/* this is the only use-case - if more than one going forward, extract to component */}
          {videoVersion && (
            <div className="mt-8 mb-6 shadow-video-card relative rounded-2xl">
              <video
                poster={videos[videoVersion].videoPoster}
                autoPlay={true}
                muted={isMuted}
                loop={true}
                playsInline={true}
                onClick={muteButtonHandler}
                width="100%"
                className="rounded-2xl cursor-pointer"
                ref={videoRef}
              >
                <source src={videos[videoVersion].video} />
                <p>{intl.formatMessage({ id: 'html5_support' })}</p>
              </video>

              <img
                src={`${appConfig.publicUrl}/img/quiz/${isMuted ? 'unmute' : 'mute'}.svg`}
                alt={intl.formatMessage({ id: 'quiz.evaluating.video.alt' })}
                onClick={muteButtonHandler}
                className={muteButtonShrunk ? 'mute-btn shrink' : 'mute-btn'}
              />
            </div>
          )}

          {titleBelowMedia && <h3 className="mb-4 semibold">{titleBelowMedia}</h3>}
          {footnote && <p className="light text-base-primary text-left mt-12">{footnote}</p>}
          <div className="mt-10">
            {otherImages &&
              otherImages.map((image) => {
                return <img src={`${appConfig.publicUrl}${image}`} key={image} className="mb-8 flex-grow mx-auto" />
              })}
          </div>
          <div className="mt-8 mb-12">
            {
              <Button onClick={() => nextPageHandler(playtime)} disabled={!nextButtonEnabled}>
                {buttonText}
              </Button>
            }
          </div>
        </div>
      </main>
    </div>
  )
}
