import { Dialog, DialogTrigger, Modal } from 'react-aria-components'
import styled, { keyframes } from 'styled-components'
import React, { ReactElement } from 'react'

export type DSModalProps = {
  variant: 'primary' | 'secondary' | 'daily30'
  children: React.ReactNode
  isOpen: boolean
  onCloseButtonClick?: () => void
  onBackdropClick?: () => void
}

const blur = keyframes`
  from {
    background: rgba(45 0 0 / 0);
    backdrop-filter: blur(0);
  }

  to {
    background: rgba(45 0 0 / .3);
    backdrop-filter: blur(10px);
  }
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const slideIn = keyframes`
  from {
    transform: translateY(-20%);
  }
  to {
    transform: translateY(0);
  }
`

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 900; // goes on top of header, change header context
`

const StyledBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeIn} 0.3s ease-out;
  &[data-entering] {
    animation: ${blur} 300ms;
  }

  &[data-exiting] {
    animation: ${blur} 300ms reverse ease-in;
  }
`

const StyledModalWrapper = styled.div`
  border-radius: var(--radius-8);
  padding: var(--grid-24) var(--grid-16);
  max-width: 500px;
  width: 100%;
  animation: ${slideIn} 0.3s ease-out;
  position: relative;
  max-height: 100vh;
  overflow-y: auto;

  &.primary {
    background-color: var(--colour-surface-neutral);
  }

  &.secondary {
    background-color: var(--colour-surface-cream-subtle);
  }

  &.daily30 {
    background-color: hsla(168, 27%, 89%, 1);
  }
`

const StyledDialogTriggerContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: var(--grid-24);
  right: var(--grid-16);
  border-radius: 50%;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: filter 0.3s ease-out;

  &.primary {
    background-color: var(--colour-surface-cream-subtle);
  }

  &.secondary {
    background-color: var(--colour-surface-neutral);
  }

  &.daily30 {
    background-color: var(--colour-surface-cream-subtle);
  }

  &:hover {
    filter: invert(100%);
  }
`

export const DSModal = ({
  variant,
  children,
  isOpen,
  onBackdropClick,
  onCloseButtonClick,
}: DSModalProps): ReactElement => (
  <StyledModal isOpen={isOpen}>
    <Dialog>
      <StyledBackdrop onClick={onBackdropClick}>
        <StyledModalWrapper className={variant} onClick={(e) => e.stopPropagation()}>
          {children}
          {onCloseButtonClick && (
            <StyledDialogTriggerContainer className={variant} onClick={onCloseButtonClick}>
              <DialogTrigger>
                <svg
                  style={{
                    width: 16,
                    height: 16,
                    minWidth: 16,
                    minHeight: 16,
                    color: 'var(--colour-text-primary)',
                  }}
                >
                  <path
                    d="M2 2L14 14M2 14L14 2"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </DialogTrigger>
            </StyledDialogTriggerContainer>
          )}
        </StyledModalWrapper>
      </StyledBackdrop>
    </Dialog>
  </StyledModal>
)
