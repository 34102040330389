import { Quizzes } from 'redux/quiz/types'
import { addMonths, subDays } from 'date-fns'
import { bmi, LOWER_HEALTHY_BMI, lowerHealthyBMIWeightForHeight } from './bmi'
import { Height, Weight, WeightGoal, WeightPlan, WeightPlanType, WeightUnit } from './types' // Weight loss tresholds

// Weight loss tresholds
export const WEIGHT_LOSS_GOAL_THRESHOLD_LBS = 10
export const WEIGHT_LOSS_GOAL_THRESHOLD_KG = 4.5
const MAX_WEIGHT_LOSS_LBS = 40
const MAX_WEIGHT_LOSS_KG = 18

const DEFAULT_WEIGHT_LOSS_CALCULATIONS_CONFIG: Record<
  WeightUnit,
  {
    highWeightLossBMIThreshold: number
    maxLowWeightLoss: number
    maxMediumWeightLoss: number
    maxHighWeightLoss: number
    maxPercentageMediumWeightLoss: number
    maxPercentageHighWeightLoss: number
  }
> = Object.freeze({
  lbs: {
    highWeightLossBMIThreshold: 30,
    maxLowWeightLoss: 10,
    maxMediumWeightLoss: 20,
    maxHighWeightLoss: 25,
    maxPercentageMediumWeightLoss: 0.1,
    maxPercentageHighWeightLoss: 0.15,
  },
  kg: {
    highWeightLossBMIThreshold: 30,
    maxLowWeightLoss: 4.5,
    maxMediumWeightLoss: 9,
    maxHighWeightLoss: 11,
    maxPercentageMediumWeightLoss: 0.1,
    maxPercentageHighWeightLoss: 0.15,
  },
  stone: {
    highWeightLossBMIThreshold: 30,
    maxLowWeightLoss: 4.5,
    maxMediumWeightLoss: 9,
    maxHighWeightLoss: 11,
    maxPercentageMediumWeightLoss: 0.1,
    maxPercentageHighWeightLoss: 0.15,
  },
})

const getHeightFromQuiz = (quiz: Quizzes): Height | undefined => {
  const { height_ft, height_in, height_cm, height_unit } = quiz

  if ((height_unit === 'cm' && !height_cm) || ((!height_unit || height_unit === 'ft') && !height_ft)) {
    return
  }

  const height: Height =
    height_unit === 'cm'
      ? {
          unit: 'cm',
          value: height_cm,
        }
      : {
          unit: 'ft',
          value: {
            feet: height_ft,
            inches: height_in ?? 0,
          },
        }

  return height
}

export const getWeightFromQuiz = (quiz: Quizzes): Weight | undefined => {
  const weightUnit = quiz.weight_unit
  const weightLbs = quiz.weight_lbs
  const weightKg = quiz.weight_kg

  const hasUnitForLbs = ['lbs', 'stone'].includes(weightUnit)

  if (!weightUnit || (hasUnitForLbs && !weightLbs) || (weightUnit === 'kg' && !weightKg)) {
    return
  }

  return {
    value: (hasUnitForLbs ? weightLbs : weightKg) as number,
    unit: weightUnit,
  }
}

export const getIdealWeightFromQuiz = (quiz: Quizzes): Weight | undefined => {
  const weightUnit = quiz.weight_unit
  const weightLbs = quiz.ideal_weight_lbs
  const weightKg = quiz.ideal_weight_kg

  const hasUnitForLbs = ['lbs', 'stone'].includes(weightUnit)

  if (!weightUnit || (hasUnitForLbs && !weightLbs) || (weightUnit === 'kg' && !weightKg)) {
    return
  }

  return {
    value: (hasUnitForLbs ? weightLbs : weightKg) as number,
    unit: weightUnit,
  }
}

export const weightRange = (quiz: Quizzes): { lowerWeight?: Weight; upperWeight?: Weight } => {
  const startWeight = getWeightFromQuiz(quiz)
  const idealWeight = getIdealWeightFromQuiz(quiz)

  if (!startWeight || !idealWeight) {
    return {}
  }

  const maxWeightLoss = startWeight.unit === 'lbs' ? MAX_WEIGHT_LOSS_LBS : MAX_WEIGHT_LOSS_KG
  const weightLossThreshold =
    startWeight.unit === 'lbs' ? WEIGHT_LOSS_GOAL_THRESHOLD_LBS : WEIGHT_LOSS_GOAL_THRESHOLD_KG

  if (startWeight.value - idealWeight.value < maxWeightLoss) {
    return {
      lowerWeight: { value: Math.floor(idealWeight.value - weightLossThreshold), unit: startWeight.unit },
      upperWeight: { value: Math.floor(idealWeight.value + weightLossThreshold), unit: startWeight.unit },
    }
  }

  return {
    lowerWeight: { value: Math.floor(startWeight.value - maxWeightLoss), unit: startWeight.unit },
    upperWeight: { value: Math.floor(startWeight.value - maxWeightLoss + maxWeightLoss / 2), unit: startWeight.unit },
  }
}

export const weightPlanType = (startWeight?: Weight, endWeight?: Weight): WeightPlanType => {
  const weightLossGoalThreshold =
    startWeight && startWeight.unit === 'lbs' ? WEIGHT_LOSS_GOAL_THRESHOLD_LBS : WEIGHT_LOSS_GOAL_THRESHOLD_KG

  return startWeight && endWeight && startWeight.value - endWeight.value > weightLossGoalThreshold
    ? 'weight_loss'
    : 'weight_maintenance'
}

export const weightPlanTypeFromQuiz = (quiz: Quizzes): WeightPlanType => {
  const startWeight = getWeightFromQuiz(quiz)
  const endWeight = getIdealWeightFromQuiz(quiz)

  return weightPlanType(startWeight, endWeight)
}

const getWeightMaintenancePlan = (
  startDate: Date,
  idealWeight?: Weight,
): {
  endWeight?: Weight
  startDate: Date
  endDate: Date
  type: WeightPlanType
} => {
  return {
    endWeight: idealWeight,
    startDate: startDate,
    endDate: addMonths(startDate, 1),
    type: 'weight_maintenance',
  }
}

export const getWeightGoal = (quiz: Quizzes, dayOffset = 0): WeightGoal | undefined => {
  const height = getHeightFromQuiz(quiz)
  const weight = getWeightFromQuiz(quiz)

  const idealWeight = getIdealWeightFromQuiz(quiz)
  const WEEKS_BEFORE_START_PLAN = 6
  const startDate = new Date()

  startDate.setDate(new Date().getDate() + WEEKS_BEFORE_START_PLAN * 7)

  if (height && weight && idealWeight && weightPlanType(weight, idealWeight) === 'weight_loss') {
    return calculateWeightGoal(startDate, weight, height, idealWeight, dayOffset)
  }

  return getWeightMaintenancePlan(startDate, idealWeight)
}

export const calculateWeightGoal = (
  startDate: Date,
  startWeight: Weight,
  height: Height,
  idealWeight: Weight,
  dayOffset = 0,
): WeightGoal | undefined => {
  const weightPlan = calculateWeightPlan(startWeight, idealWeight, height)
  if (weightPlan.length === 0) {
    return
  }

  const endDate = addMonths(startDate, weightPlan.length - 1)

  const dayOffsetPerPeriod = dayOffset / (weightPlan.length - 1)
  const weightPlanWithDates = weightPlan.map((weight, i) => ({
    weight,
    date: subDays(addMonths(startDate, i), dayOffsetPerPeriod * i),
  }))

  const endWeight = { value: Number(weightPlan.slice(-1)[0].toFixed(2)), unit: startWeight.unit }

  return {
    weightPlan: weightPlanWithDates,
    endWeight,
    startDate: startDate,
    endDate: subDays(endDate, dayOffset),
    type: weightPlanType(startWeight, endWeight),
  }
}

const calculateWeightPlan = (startWeight: Weight, idealWeight: Weight, height: Height): WeightPlan => {
  const config = DEFAULT_WEIGHT_LOSS_CALCULATIONS_CONFIG[startWeight.unit]
  const targetWeightloss = getSafeWeightlossAmount(height, startWeight, idealWeight)
  // Calculate weight plan
  // TODO: this needs desperate refactoring, haven't been brave enough yet
  const planLengthInMonths =
    startWeight.unit === 'lbs'
      ? Math.min(6, Math.floor(targetWeightloss.value / 3) + 2)
      : Math.min(6, Math.floor(targetWeightloss.value / 1.2) + 1) // TODO is this right?
  const averageMonthlyWeightLoss = targetWeightloss.value / planLengthInMonths
  let remainingWeight = targetWeightloss.value
  const weightPlan: { [key: number]: number } = {
    0: startWeight.value,
  }

  if (targetWeightloss.value <= config.maxLowWeightLoss) {
    for (let i = 1; i < planLengthInMonths + 1; i++) {
      let loss = 0
      switch (i) {
        case 1:
          loss = Math.ceil(Math.floor(averageMonthlyWeightLoss / 2) + 2)
          break
        case 2:
          loss = Math.ceil(averageMonthlyWeightLoss) + 2
          break
        case 3:
          loss = Math.ceil((remainingWeight * 2) / 3)
          break
        default:
          loss = remainingWeight
      }

      if (i === planLengthInMonths) {
        weightPlan[i] = weightPlan[i - 1] - remainingWeight
      } else {
        weightPlan[i] = weightPlan[i - 1] - loss
        remainingWeight = remainingWeight - loss
      }
    }
  } else if (targetWeightloss.value <= config.maxMediumWeightLoss) {
    for (let i = 1; i < planLengthInMonths + 1; i++) {
      let loss = 0
      switch (i) {
        case 1:
          loss = Math.floor(averageMonthlyWeightLoss / 2) + 1
          break
        case 2:
          loss = Math.floor(averageMonthlyWeightLoss) + 0
          break
        case 3:
          loss = Math.floor(remainingWeight / 3)
          break
        default:
          loss = Math.ceil(remainingWeight / 2)
      }

      if (i === planLengthInMonths) {
        weightPlan[i] = weightPlan[i - 1] - remainingWeight
      } else {
        weightPlan[i] = weightPlan[i - 1] - loss
        remainingWeight = remainingWeight - loss
      }
    }
  } else if (targetWeightloss.value <= config.maxHighWeightLoss) {
    remainingWeight = targetWeightloss.value

    for (let i = 1; i < planLengthInMonths + 1; i++) {
      switch (i) {
        case planLengthInMonths:
          weightPlan[i] = weightPlan[i - 1] - remainingWeight
          break
        case 1:
          weightPlan[1] = startWeight.value - Math.floor(averageMonthlyWeightLoss) + 1
          remainingWeight = targetWeightloss.value - Math.floor(averageMonthlyWeightLoss) + 1
          break
        case 2:
        case 3:
          weightPlan[i] = weightPlan[i - 1] - Math.floor(averageMonthlyWeightLoss) - 1
          remainingWeight = remainingWeight - Math.floor(averageMonthlyWeightLoss) - 1
          break
        case 4:
        case 5:
          weightPlan[i] = weightPlan[i - 1] - Math.floor(averageMonthlyWeightLoss)
          remainingWeight = remainingWeight - Math.floor(averageMonthlyWeightLoss)
          break
        default:
          weightPlan[i] = weightPlan[i - 1] - remainingWeight
      }
    }
  }

  for (const key of Object.keys(weightPlan)) {
    if (weightPlan[parseInt(key)] < startWeight.value - targetWeightloss.value) {
      weightPlan[parseInt(key)] = startWeight.value - targetWeightloss.value
    }
  }

  return Object.values(weightPlan)
}

export const getSafeWeightlossAmount = (height: Height, startWeight: Weight, idealWeight: Weight): Weight => {
  const startBMI = bmi(height, startWeight)
  const idealBMI = bmi(height, idealWeight)
  const config = DEFAULT_WEIGHT_LOSS_CALCULATIONS_CONFIG[startWeight.unit]

  // Adjust target to be safe
  const targetWeight =
    idealBMI >= LOWER_HEALTHY_BMI ? idealWeight : lowerHealthyBMIWeightForHeight(height, startWeight.unit)

  // Adjust target to be realistic
  const targetWeightloss =
    startBMI < config.highWeightLossBMIThreshold
      ? Math.min(
          startWeight.value - targetWeight.value,
          config.maxPercentageMediumWeightLoss * startWeight.value,
          config.maxMediumWeightLoss,
        )
      : Math.min(
          startWeight.value - targetWeight.value,
          config.maxPercentageHighWeightLoss * startWeight.value,
          config.maxHighWeightLoss,
        )

  return { value: targetWeightloss, unit: startWeight.unit }
}

export const lowerHealthyBMIWeightForHeightForUnit = (quiz: Quizzes, unit: WeightUnit, minBMI?: number): Weight => {
  const height = getHeightFromQuiz(quiz)

  // TODO is this right?
  if (!height) {
    return {
      value: 0,
      unit: unit,
    }
  }

  const lowerBMIWeight = lowerHealthyBMIWeightForHeight(height, unit, minBMI)

  // TODO can we do the ceil directly inside lowerHealthyBMIWeightForHeight?
  return { value: Math.ceil(lowerBMIWeight.value), unit: lowerBMIWeight.unit }
}

export const lowerHealthyBMIWeightForHeightFromQuiz = (quiz: Quizzes): Weight => {
  const weight = getWeightFromQuiz(quiz)

  // TODO is this right?
  if (!weight) {
    return {
      value: 0,
      unit: 'lbs',
    }
  }

  return lowerHealthyBMIWeightForHeightForUnit(quiz, weight.unit)
}

export const totalLbsFromStoneAndLbs = (stone: number, lbs: number): number => {
  return stone * 14 + lbs
}

export const stoneFromTotalLbs = (totalLbs: number): number => {
  return Math.floor(totalLbs / 14)
}

export const lbsLeftOverFromTotalLbs = (totalLbs: number): number => {
  return totalLbs % 14
}
