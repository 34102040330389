export type WeightUnit = 'lbs' | 'kg' | 'stone'

export type Weight = {
  value: number
  unit: WeightUnit
}

export type WeightPlan = number[]

export type WeightPlanWithDates = { date: Date; weight: number }[]

export type WeightPlanType = 'weight_maintenance' | 'weight_loss'

export type WeightGoal = {
  weightPlan?: WeightPlanWithDates
  endWeight?: Weight
  startDate: Date
  endDate: Date
  type: WeightPlanType
}

export const MIN_ALLOWED_WEIGHT = 0
export const MAX_ALLOWED_WEIGHT_LBS = 500
export const MAX_ALLOWED_WEIGHT_KG = 225

export type HeightUnit = 'cm' | 'ft'

export type HeightMetric = {
  unit: 'cm'
  value: number
}

export type HeightImperial = {
  unit: 'ft'
  value: {
    feet: number
    inches: number
  }
}

export type Height = HeightMetric | HeightImperial
