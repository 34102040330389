import { MultiValueFormValues } from '../../components/screen-templates/FormScreen'
import { totalLbsFromStoneAndLbs } from './weightCalculations'
import { SupportedCountries } from '../geoLocation'
import { WeightUnit } from './types'

export const getFormattedWeightValues = (values: MultiValueFormValues): MultiValueFormValues => {
  const updatedValues = { ...values }
  const { weight_unit, weight_st, weight_st_lbs } = values

  if (weight_unit === 'lbs' || weight_unit === 'stone') {
    delete updatedValues.weight_st_lbs
    delete updatedValues.weight_kg
    delete updatedValues.weight_st
    if (weight_unit === 'stone') {
      updatedValues.weight_lbs = totalLbsFromStoneAndLbs(Number(weight_st), Number(weight_st_lbs) || 0)
    }
  } else {
    delete updatedValues.weight_lbs
    delete updatedValues.weight_st
    delete updatedValues.weight_st_lbs
  }

  return updatedValues
}

export const getDefaultWeightUnitByCountry = (country: SupportedCountries): WeightUnit => {
  switch (country) {
    case 'GB':
      return 'stone'
    case 'US':
      return 'lbs'
    default:
      return 'kg'
  }
}
