import React from 'react'
import { styled } from 'styled-components'
import { useField } from 'formik'
import { InputFieldProps } from './InputField'
import { FieldError } from './FieldError'
import { DSInput } from '@zoe/ds-web'

const StyledAgeInput = styled.div`
  width: 50%;
  min-width: 150px;
`
export const CustomAgeInput: React.FC<InputFieldProps> = ({ ...props }): JSX.Element => {
  const meta = useField(props)[1]

  return (
    <StyledAgeInput>
      <DSInput name={props.name} type="number" min={18} max={120} placeholder="" label="years" />
      {meta.error && meta.touched && <FieldError>{meta.error}</FieldError>}
    </StyledAgeInput>
  )
}
