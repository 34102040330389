import { Menopause, Menoscale } from '../../redux/quiz/types'
import { SupportedCountries } from '../geoLocation'

const menopauseAgeRanges = [
  '30-34',
  '35-40',
  '41-45',
  '46-50',
  '51-55',
  '56-60',
  '61-65',
  '66-70',
  '71-75',
  '76-80',
  '80-120',
] as const

export type MenopauseAgeRange = typeof menopauseAgeRanges[number]

export type MenopauseAverageData = {
  perimenopausal: number | null
  postmenopausal: number | null
}
type Symptoms = Record<typeof MENOSCALE_SCORE_KEYS[number], number | null>

type MenopauseScoresByAgeGroup = Record<MenopauseAgeRange, MenopauseAverageData>
type SymptomByAgeGroup = Record<MenopauseAgeRange, Symptoms>

const MENOSCALE_SCORE_KEYS = [
  'hot_flushes',
  'night_sweats',
  'palpitations',
  'joint_and_muscular_discomfort',
  'weight_gain',
  'loss_of_interest_in_sex',
  'bladder_problems',
  'vaginal_dryness',
  'declining_skin_quality',
  'declining_hair_quality',
  'irritability',
  'low_mood_depression_mood_swings',
  'anxiety',
  'sleep_problems',
  'headaches',
  'brain_fog',
  'memory_problems',
  'tiredness_and_fatigue',
  'decreased_physical_strength',
  'digestive_symptoms',
] as const

const MENOSCALE_LABELS: Record<string, string> = {
  hot_flushes: 'Hot flushes',
  night_sweats: 'Night sweats',
  palpitations: 'Palpitations (heart beating fast, strongly, or irregularly)',
  joint_and_muscular_discomfort: 'Joint and muscular discomfort',
  weight_gain: 'Weight gain',
  loss_of_interest_in_sex: 'Loss of interest in sex',
  bladder_problems: 'Bladder problems (e.g. infections, leaks, urgency)',
  vaginal_dryness: 'Vaginal dryness',
  declining_skin_quality: 'Declining skin quality',
  declining_hair_quality: 'Declining hair quality',
  irritability: 'Irritability',
  low_mood_depression_mood_swings: 'Low mood, depression, mood swings',
  anxiety: 'Anxiety',
  sleep_problems: 'Sleep problems',
  headaches: 'Headaches',
  brain_fog: 'Brain fog',
  memory_problems: 'Memory problems',
  tiredness_and_fatigue: 'Tiredness and fatigue',
  decreased_physical_strength: 'Decreased physical strength / stamina',
  digestive_symptoms: 'Digestive symptoms e.g. bloating, gas, discomfort',
}

const menoscaleData: MenopauseScoresByAgeGroup = {
  '30-34': {
    perimenopausal: 36.98,
    postmenopausal: 36.2,
  },
  '35-40': {
    perimenopausal: 31,
    postmenopausal: 34.21,
  },
  '41-45': {
    perimenopausal: 31,
    postmenopausal: 32,
  },
  '46-50': {
    perimenopausal: 31,
    postmenopausal: 31,
  },
  '51-55': {
    perimenopausal: 30,
    postmenopausal: 27,
  },
  '56-60': {
    perimenopausal: 29,
    postmenopausal: 23,
  },
  '61-65': {
    perimenopausal: 33.05,
    postmenopausal: 17,
  },
  '66-70': {
    perimenopausal: 33,
    postmenopausal: 12,
  },
  '71-75': {
    perimenopausal: 22,
    postmenopausal: 8,
  },
  '76-80': {
    perimenopausal: null,
    postmenopausal: 5,
  },
  '80-120': {
    perimenopausal: null,
    postmenopausal: 3.5,
  },
}

/* TODO use data from this sheet: https://docs.google.com/spreadsheets/d/195h8tF-U6aCeXrA_Wu7cC8vMH5KmRtDM6RjhRV0_Rew/edit?pli=1&gid=752798499#gid=752798499 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const symptomData: SymptomByAgeGroup = {
  '30-34': {
    hot_flushes: 1,
    tiredness_and_fatigue: 3,
    loss_of_interest_in_sex: 3,
    sleep_problems: 1,
    irritability: 3,
    anxiety: 1,
    declining_hair_quality: 0,
    brain_fog: 1,
    memory_problems: 1,
    low_mood_depression_mood_swings: 3,
    joint_and_muscular_discomfort: 1,
    vaginal_dryness: 1,
    headaches: 1,
    declining_skin_quality: 1,
    night_sweats: 1,
    weight_gain: 3,
    digestive_symptoms: null,
    decreased_physical_strength: null,
    bladder_problems: null,
    palpitations: null,
  },
  '35-40': {
    hot_flushes: 1,
    tiredness_and_fatigue: 2.74,
    loss_of_interest_in_sex: 3,
    sleep_problems: 1,
    irritability: 3,
    anxiety: 1,
    declining_hair_quality: 0,
    brain_fog: 1,
    memory_problems: 1,
    low_mood_depression_mood_swings: 2.33,
    joint_and_muscular_discomfort: 1,
    vaginal_dryness: 0,
    headaches: 1,
    declining_skin_quality: 1,
    night_sweats: 1,
    weight_gain: 2.4,
    digestive_symptoms: null,
    decreased_physical_strength: null,
    bladder_problems: null,
    palpitations: null,
  },
  '41-45': {
    hot_flushes: 0,
    tiredness_and_fatigue: 2.65,
    loss_of_interest_in_sex: 3,
    sleep_problems: 1,
    irritability: 3,
    anxiety: 1,
    declining_hair_quality: 0,
    brain_fog: 3,
    memory_problems: 1,
    low_mood_depression_mood_swings: 3,
    joint_and_muscular_discomfort: 1,
    vaginal_dryness: 0,
    headaches: 1,
    declining_skin_quality: 1,
    night_sweats: 1,
    weight_gain: 2.53,
    digestive_symptoms: null,
    decreased_physical_strength: null,
    bladder_problems: null,
    palpitations: null,
  },
  '46-50': {
    hot_flushes: 0,
    tiredness_and_fatigue: 2.65,
    loss_of_interest_in_sex: 3,
    sleep_problems: 1,
    irritability: 3,
    anxiety: 1,
    declining_hair_quality: 0,
    brain_fog: 3,
    memory_problems: 1,
    low_mood_depression_mood_swings: 3,
    joint_and_muscular_discomfort: 1,
    vaginal_dryness: 0,
    headaches: 1,
    declining_skin_quality: 1,
    night_sweats: 1,
    weight_gain: 2.53,
    digestive_symptoms: null,
    decreased_physical_strength: null,
    bladder_problems: null,
    palpitations: null,
  },
  '51-55': {
    hot_flushes: 1,
    tiredness_and_fatigue: 2.42,
    loss_of_interest_in_sex: 3,
    sleep_problems: 1,
    irritability: 1,
    anxiety: 1,
    declining_hair_quality: 0,
    brain_fog: 1,
    memory_problems: 1,
    low_mood_depression_mood_swings: 1,
    joint_and_muscular_discomfort: 1,
    vaginal_dryness: 0,
    headaches: 1,
    declining_skin_quality: 1,
    night_sweats: 1,
    weight_gain: 3,
    digestive_symptoms: null,
    decreased_physical_strength: null,
    bladder_problems: null,
    palpitations: null,
  },
  '56-60': {
    hot_flushes: 0,
    tiredness_and_fatigue: 1,
    loss_of_interest_in_sex: 3,
    sleep_problems: 1,
    irritability: 1,
    anxiety: 1,
    declining_hair_quality: 0,
    brain_fog: 1,
    memory_problems: 1,
    low_mood_depression_mood_swings: 1,
    joint_and_muscular_discomfort: 1,
    vaginal_dryness: 1,
    headaches: 0,
    declining_skin_quality: 1,
    night_sweats: 0,
    weight_gain: 2.34,
    digestive_symptoms: null,
    decreased_physical_strength: null,
    bladder_problems: null,
    palpitations: null,
  },
  '61-65': {
    hot_flushes: 0,
    tiredness_and_fatigue: 1,
    loss_of_interest_in_sex: 1,
    sleep_problems: 1,
    irritability: 0,
    anxiety: 0,
    declining_hair_quality: 0,
    brain_fog: 1,
    memory_problems: 1,
    low_mood_depression_mood_swings: 0,
    joint_and_muscular_discomfort: 1,
    vaginal_dryness: 1,
    headaches: 0,
    declining_skin_quality: 1,
    night_sweats: 0,
    weight_gain: 1,
    digestive_symptoms: null,
    decreased_physical_strength: null,
    bladder_problems: null,
    palpitations: null,
  },
  '66-70': {
    hot_flushes: 0,
    tiredness_and_fatigue: 1,
    loss_of_interest_in_sex: 1,
    sleep_problems: 0,
    irritability: 0,
    anxiety: 0,
    declining_hair_quality: 0,
    brain_fog: 0,
    memory_problems: 1,
    low_mood_depression_mood_swings: 0,
    joint_and_muscular_discomfort: 0,
    vaginal_dryness: 1,
    headaches: 0,
    declining_skin_quality: 1,
    night_sweats: 0,
    weight_gain: 1,
    digestive_symptoms: null,
    decreased_physical_strength: null,
    bladder_problems: null,
    palpitations: null,
  },
  '71-75': {
    hot_flushes: 0,
    tiredness_and_fatigue: 0,
    loss_of_interest_in_sex: 0,
    sleep_problems: 0,
    irritability: 0,
    anxiety: 0,
    declining_hair_quality: 0,
    brain_fog: 0,
    memory_problems: 0,
    low_mood_depression_mood_swings: 0,
    joint_and_muscular_discomfort: 0,
    vaginal_dryness: 0,
    headaches: 0,
    declining_skin_quality: 0,
    night_sweats: 0,
    weight_gain: 0,
    digestive_symptoms: null,
    decreased_physical_strength: null,
    bladder_problems: null,
    palpitations: null,
  },
  '76-80': {
    hot_flushes: 0,
    tiredness_and_fatigue: 0,
    loss_of_interest_in_sex: 0,
    sleep_problems: 0,
    irritability: 0,
    anxiety: 0,
    declining_hair_quality: 0,
    brain_fog: 0,
    memory_problems: 0,
    low_mood_depression_mood_swings: 0,
    joint_and_muscular_discomfort: 0,
    vaginal_dryness: 0,
    headaches: 0,
    declining_skin_quality: 0,
    night_sweats: 0,
    weight_gain: 0,
    digestive_symptoms: null,
    decreased_physical_strength: null,
    bladder_problems: null,
    palpitations: null,
  },
  '80-120': {
    hot_flushes: 0,
    tiredness_and_fatigue: 0,
    loss_of_interest_in_sex: 0,
    sleep_problems: 0,
    irritability: 0,
    anxiety: 0,
    declining_hair_quality: 0,
    brain_fog: 0,
    memory_problems: 0,
    low_mood_depression_mood_swings: 0,
    joint_and_muscular_discomfort: 0,
    vaginal_dryness: 0,
    headaches: 0,
    declining_skin_quality: 0,
    night_sweats: 0,
    weight_gain: 0,
    digestive_symptoms: null,
    decreased_physical_strength: null,
    bladder_problems: null,
    palpitations: null,
  },
}

export const getMenopausalAgeRange = (age?: number): MenopauseAgeRange | null => {
  if (!age || isNaN(age)) return null

  const ageRanges = menopauseAgeRanges.map((range) => {
    const [min, max] = range.split('-').map((num) => parseInt(num))
    return { range, min, max }
  })
  const ageRange = ageRanges.find(({ min, max }) => age >= min && age <= max)
  return ageRange?.range ?? null
}

export const calculateAverageScoreForAgeAndMenopausalStatus = (
  ageRange: MenopauseAgeRange | null,
): MenopauseAverageData | null => {
  if (!ageRange) return null

  return menoscaleData[ageRange]
}

export const calculateMenoscaleScore = (quiz: Menoscale): number => {
  const total = MENOSCALE_SCORE_KEYS.reduce((acc, key) => {
    const value = quiz[key] ?? '0'
    return acc + parseInt(value)
  }, 0)

  return isNaN(total) ? 0 : Math.round(total)
}

type MenoscaleResults = {
  score: number
  ageRange: MenopauseAgeRange | null
  averages: MenopauseAverageData | null
  mainSymptoms: {
    name: string
    score: number
    averageScore: number
  }[]
  menopauseStatus: Menopause
}
export const calculateMenoscaleResults = (quiz: Menoscale): MenoscaleResults => {
  const mainSymptoms = getMenoscaleMainSymptoms(quiz)
  const ageRange = getMenopausalAgeRange(parseInt(quiz.age))
  const averages = calculateAverageScoreForAgeAndMenopausalStatus(ageRange)

  return {
    score: calculateMenoscaleScore(quiz),
    ageRange,
    averages,
    mainSymptoms,
    menopauseStatus: quiz.menopause,
  }
}

export const getMenoscaleMainSymptoms = (
  quiz: Menoscale,
): {
  name: string
  score: number
  averageScore: number
}[] => {
  const ageRange = getMenopausalAgeRange(parseInt(quiz.age))
  const highestScoringSymptoms = MENOSCALE_SCORE_KEYS.map((key) => ({
    name: key,
    score: parseInt(quiz[key] ?? '0'),
  })).sort((a, b) => b.score - a.score)

  // if there's no age range (unlikely to happen), we return the top 3
  if (!ageRange) return highestScoringSymptoms.slice(0, 3).map((symptom) => ({ ...symptom, averageScore: 0 }))

  // if there's an age range we get the top 3 symptoms for that age range that have data > 0
  const symptomsForAgeRange = symptomData[ageRange]
  const highestScoringSymptomsForAgeRange = highestScoringSymptoms
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .filter((symptom) => symptomsForAgeRange[symptom.name] && symptomsForAgeRange[symptom.name]! > 0)
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .map((symptom) => ({ ...symptom, averageScore: symptomsForAgeRange[symptom.name]! }))

  return highestScoringSymptomsForAgeRange.slice(0, 3)
}

export const getSymptomLabel = (symptom: string, quizCountry?: SupportedCountries): string => {
  if (symptom === 'hot_flushes' && quizCountry == 'US') {
    return 'Hot flashes'
  }
  return MENOSCALE_LABELS[symptom] ?? symptom
}
export const getNormalisedMenoscaleSymptoms = (
  menoscaleKeys?: string[],
  quizCountry?: SupportedCountries,
): string[] | undefined => {
  if (menoscaleKeys) {
    return menoscaleKeys.map((sympton) => getSymptomLabel(sympton, quizCountry))
  }
}
