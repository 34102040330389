import { RadioButton } from 'components/core/fields/RadioButton'
import { RadioButtonGroup } from 'components/core/fields/RadioButtonGroup'
import React from 'react'
import { FormScreen } from 'components/screen-templates/FormScreen'
import * as Yup from 'yup'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { Conditions, Quizzes } from '../../../redux/quiz/types'
import { CheckboxGroup } from '../fields/CheckboxGroup'
import { Checkbox } from '../fields/Checkbox'
import { ContextAwareText } from '../text/ContextAwareText'
import { InputField } from '../fields/InputField'
import { evaluateCondition } from '../../utils'
import { Answer } from '../../../redux/quiz/slice'

type SortStrategy = 'random' | 'fixed'
type Option = {
  label: string
  value: string
  // indicates this option should be fixed and not affected by sorting
  fixed?: boolean
  showWhen?: Conditions
}

export type SingleQuestionScreenProps = {
  title: string
  description?: string
  buttonTitle?: string
  questionKey: string
  options: Option[]
  hideBackLink?: boolean
  allowMultipleSelection?: boolean
  includeWeightGoalAnswers?: boolean
  order?: SortStrategy
  showTextInputWhen?: string
  textInputPlaceholder?: string
  textInputQuestionKey?: string
}

type Props = SingleQuestionScreenProps & {
  onNextClick: onNextClickType
  onBackClick: onBackClickCallback
  conditionValues: Required<Conditions>
  quiz: Quizzes
  goalAnswersToBeSubmitted: Answer[] | undefined
}

const getVisibleOptions = (props: Props) => {
  return props.options.filter((option) => !option.showWhen || evaluateCondition(option.showWhen, props.conditionValues))
}

const sortOptions = (options: Option[], order: SortStrategy): Option[] => {
  if (order === 'random') {
    // we first create a copy of the array, nullifying all non-fixed options
    const nullifiedOptions = [...options].map((option) => (option.fixed ? option : null))
    // then we shuffle the non-fixed options
    const randomizedOptions = options.filter((option) => option && !option.fixed).sort(() => 0.5 - Math.random())

    // ... and then we assign a random option to each nullified position
    return nullifiedOptions.map((option) => {
      return option === null ? (randomizedOptions.shift() as Option) : option
    })
  }

  // we don't need sorting for the default/fixed strategy - we return the options as they are
  return options
}

export const SingleQuestionScreen: React.FC<Props> = (props: Props) => {
  const [selected, setSelected] = React.useState<string | string[]>(props.allowMultipleSelection ? [] : '')
  const visibleOptions = getVisibleOptions(props)
  const sortedOptions = sortOptions(visibleOptions, props.order ?? 'fixed')

  const options = sortedOptions.map((option, index) =>
    props.allowMultipleSelection ? (
      <Checkbox key={index} name={props.questionKey} id={option.value} value={option.value} label={option.label} />
    ) : (
      <React.Fragment key={index}>
        <RadioButton
          clicked={props.showTextInputWhen ? (value: string) => setSelected(value) : undefined}
          name={props.questionKey}
          id={option.value}
          value={option.value}
          label={option.label}
        />
        {props.showTextInputWhen === selected && props.textInputQuestionKey && option.value === selected && (
          <div className="mb-4">
            <InputField
              name={props.textInputQuestionKey}
              type="text"
              placeholder={props.textInputPlaceholder ?? 'Please specify'}
            />
          </div>
        )}
      </React.Fragment>
    ),
  )
  return (
    <FormScreen
      screenTitle={props.title}
      description={props.description ? <ContextAwareText text={props.description} quiz={props.quiz} /> : null}
      buttonText={props.buttonTitle ?? 'Continue'}
      form={{
        body: props.allowMultipleSelection ? (
          <CheckboxGroup name={props.questionKey}>{options}</CheckboxGroup>
        ) : (
          <RadioButtonGroup name={props.questionKey}>{options}</RadioButtonGroup>
        ),
        initialValues: props.textInputQuestionKey ? { [props.textInputQuestionKey]: '' } : {},
        validationSchema: Yup.object({
          [props.questionKey]: props.allowMultipleSelection
            ? Yup.array().min(1, 'Please select at least one option')
            : Yup.string().required('Please select an option'),
        }),
      }}
      onNextClick={props.onNextClick}
      onBackClick={props.hideBackLink ? undefined : props.onBackClick}
      goalAnswersToBeSubmitted={props.goalAnswersToBeSubmitted}
    />
  )
}
