import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { eachDayOfInterval } from 'date-fns'
import { Weight } from 'libs/weight/types'
import { useIntl } from 'react-intl'

export interface BeWeightHeadingProps {
  weight?: Weight
  startDate?: Date
  endDate: Date
}

export const BeWeightHeading: React.FC<BeWeightHeadingProps> = React.memo(({ weight, startDate, endDate }) => {
  const intl = useIntl()
  const [index, setIndex] = useState(0)

  const formatDate = useCallback(
    (date: Date): string =>
      new Intl.DateTimeFormat('en', {
        day: 'numeric',
        month: 'short',
      }).format(date),
    [],
  )

  const dates = useMemo(
    () =>
      !startDate
        ? [formatDate(endDate)]
        : eachDayOfInterval({
            start: endDate,
            end: startDate,
          })
            .reverse()
            .map((d) => formatDate(d)),
    [startDate, endDate, formatDate],
  )

  useEffect(() => {
    if (!startDate) return

    const intervalId = setInterval(() => {
      if (index < dates.length - 1) {
        setIndex((i) => i + 1)
      } else {
        clearInterval(intervalId)
      }
    }, 250)

    return () => clearInterval(intervalId)
  }, [startDate, index, dates.length])

  return (
    <h3>
      {weight ? `${weight.value.toFixed()} ${weight.unit}` : intl.formatMessage({ id: 'improve_your_gut_health' })}
      {` by `}
      {!startDate ? dates[0] : dates[index]}
    </h3>
  )
})
