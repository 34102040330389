import React from 'react'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { HealthGoalV5_1, Quizzes } from 'redux/quiz/types'
import { InfoOnlyScreen } from 'components/screen-templates/InfoOnlyScreen'
import { ContextAwareText } from '../core/text/ContextAwareText'

type GoalContent = {
  title: string
  intro: string
  footerNotes?: {
    title: string
    description: string
  }
}

export type ResultsScreenProps = {
  healthGoalContent: Record<HealthGoalV5_1, GoalContent>
  features: { text: string; variations?: Partial<Record<HealthGoalV5_1, string>> }[]
  subtitle: string
  subtitleDescription: string
  buttonText?: string
}

type Props = ResultsScreenProps & {
  onBackClick?: onBackClickCallback
  onNextClick: onNextClickType
  quiz: Quizzes
}
export const ResultsScreen: React.FC<Props> = ({
  subtitle,
  subtitleDescription,
  buttonText,
  features,
  healthGoalContent,
  quiz,
  onNextClick,
  onBackClick,
}) => {
  const healthGoal = (quiz.health_goal as HealthGoalV5_1) || 'energy'
  const content = healthGoalContent[healthGoal]
  const allFeatures = features.map((feature) => {
    return feature.variations?.[healthGoal] ?? feature.text
  })

  return (
    <InfoOnlyScreen
      aboveTitle={<ContextAwareText text={content.intro} />}
      screenTitleH2={<ContextAwareText text={content.title} />}
      image="/img/quiz/results.png"
      descriptionBelowImage={
        <>
          <h2 className="mt-8">
            <ContextAwareText text={subtitle} />
          </h2>
          <p className="light mt-2 mb-8">
            <ContextAwareText text={subtitleDescription} />
          </p>
          <ul>
            {allFeatures.map((feature, index) => (
              <li key={index} className="flex flex-row mt-4 align-start">
                <img src="/img/icons/yellow-tick.svg" />
                <p className="ml-4">
                  <ContextAwareText text={feature} />
                </p>
              </li>
            ))}
          </ul>
          {content.footerNotes && (
            <>
              <div className="w-100 h-px bg-gray-200 my-10" />
              <h2 className="mt-8">
                <ContextAwareText text={content.footerNotes.title} />
              </h2>
              <p className="light mt-2 mb-8">
                <ContextAwareText text={content.footerNotes.description} />
              </p>
            </>
          )}
        </>
      }
      buttonText={buttonText ?? 'Continue'}
      belowButton={
        <div className="flex flex-col justify-center">
          <p className="light text-center">Peer reviewed and published in</p>
          <img src="/img/logos/nature-medicine.svg" className="mt-2 mb-8" style={{ maxHeight: 18 }} />
        </div>
      }
      onNextClick={onNextClick}
      onBackClick={onBackClick}
    />
  )
}
