import React from 'react'
import { Answer } from '../../redux/quiz/slice'
import { ScreenProps } from '../types'
import { CardsScreen, CardsScreenProps } from './CardsScreen'
import { MultiImagesInfoOnlyScreen } from './MultiImagesInfoOnlyScreen'

export interface MultiConditionalScreenProps extends ScreenProps {
  screenType: string
  headerBar?: React.ReactNode
  screenTitle?: React.ReactNode
  descriptionAboveDynamicImageStatic?: React.ReactNode
  descriptionAboveDynamicImageDynamic?: React.ReactNode
  dynamicImage?: React.ReactNode // weight plan chart
  titleAboveImage1?: React.ReactNode
  descriptionAboveImage1?: React.ReactNode
  image1?: string
  image1Alt?: string
  descriptionBelowImage1Static?: React.ReactNode
  description2BelowImage1Static?: React.ReactNode
  image2?: string
  image2Alt?: string
  captionBelowImage2?: React.ReactNode
  isSeparatorBelowImage2Dynamic?: boolean
  descriptionBelowImage2Dynamic?: React.ReactNode
  descriptionBelowImage2Static?: React.ReactNode
  hideInterimCallToAction?: boolean
  showDivider: boolean
  dividerSectionText?: React.ReactNode
  otherImages?: string[]
  goalAnswersToBeSubmitted?: Answer[]
  buttonText: React.ReactNode
}

interface MultiImagesConditionalScreenBase {
  conditionalScreen2: MultiConditionalScreenProps
  conditionalUpon: 'weight_plan' | 'sex'
  condition: 'weight_maintenance' | 'weight_loss' | 'male' | 'female'
}

interface CardsScreen extends MultiImagesConditionalScreenBase {
  conditionalScreen1: CardsScreenProps & { screenType: 'cards' }
}

interface MultiImagesInfoOnlyScreen extends MultiImagesConditionalScreenBase {
  conditionalScreen1: MultiConditionalScreenProps & { screenType: 'multiImagesInfoOnly' }
}

export type MultiImagesConditionalScreenProps = CardsScreen | MultiImagesInfoOnlyScreen

export const MultiImagesConditionalScreen: React.FC<MultiImagesConditionalScreenProps> = ({
  conditionalScreen1,
  conditionalScreen2,
  conditionalUpon,
  condition,
}) => {
  function renderScreen() {
    // TODO: Any way we can make this more flexible or less error prone?
    switch (conditionalUpon) {
      case 'weight_plan':
        if (condition === 'weight_loss' && conditionalScreen1.screenType === 'multiImagesInfoOnly') {
          return <MultiImagesInfoOnlyScreen {...conditionalScreen1} />
        } else if (condition === 'weight_loss' && conditionalScreen1.screenType === 'cards') {
          return <CardsScreen {...conditionalScreen1} />
        } else {
          return <MultiImagesInfoOnlyScreen {...conditionalScreen2} />
        }
      // case 'sex':
      //   // if (sex === 'male') {
      //   if (true) {
      //     return <p>SHOW MALE SPECIFIC CHART - {conditionalScreen2}</p>
      //   } else {
      //     return <p>SHOW FEMALE SPECIFIC CHART - {conditionalScreen1}</p>
      //   }
      default:
        return { conditionalScreen2 }
    }
  }

  return <div>{renderScreen()}</div>
}
