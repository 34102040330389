import React, { useState } from 'react'
import * as Yup from 'yup'
import { Answer } from 'clients/quiz-service'
import { FormScreen, FormValues } from 'components/screen-templates/FormScreen'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { HeightUnit } from 'libs/weight/types'
import { DSDropdown, DSFlex, DSFormErrorText, DSInput, DSSpacer } from '@zoe/ds-web'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { getFormattedHeightValues } from '../../libs/height/height-helpers'

export const MIN_HEIGHT_FEET = 1
export const MAX_HEIGHT_FEET = 13
export const MIN_HEIGHT_INCHES = 0
export const MAX_HEIGHT_INCHES = 11
export const MIN_HEIGHT_CM = 30
export const MAX_HEIGHT_CM = 420

const StyledDropDown = styled.div`
  min-width: 110px;
`

export type HeightScreenProps = {
  title: string
  defaultUnit: HeightUnit
  subtitle?: string
  buttonText?: string
}

type Props = HeightScreenProps & {
  onBackClick?: onBackClickCallback
  onNextClick: onNextClickType
}

export const HeightScreen: React.FC<Props> = ({
  title,
  subtitle,
  defaultUnit,
  buttonText,
  onNextClick,
  onBackClick,
}) => {
  const [selectedUnit, setSelectedUnit] = useState<string>(defaultUnit)

  const handleOnNextClick = (values?: FormValues | Answer[] | any) => {
    onNextClick(getFormattedHeightValues(values))
  }

  return (
    <FormScreen
      screenTitle={title}
      description={subtitle}
      buttonText={buttonText ?? 'Continue'}
      showHomepageLink={false}
      form={{
        body: <HeightScreenBody selectedUnit={selectedUnit} onSelect={setSelectedUnit} />,
        validationSchema: validationSchema(),
        // needed to prevent React "uncontrolled" error showing
        initialValues: { height_ft: '', height_in: '', height_cm: '', height_unit: defaultUnit },
      }}
      onNextClick={handleOnNextClick}
      onBackClick={onBackClick}
    />
  )
}

export const HeightScreenBody = ({
  selectedUnit,
  onSelect,
}: {
  selectedUnit: string
  onSelect: (value: ((prevState: string) => string) | string) => void
}): JSX.Element => {
  const { errors, touched, setFieldTouched, setFieldError, setFieldValue } = useFormikContext<any>()

  const selectedError = selectedUnit === 'ft' ? errors['height_ft'] || errors['height_in'] : errors['height_cm']
  const selectedTouched = selectedUnit === 'ft' ? touched['height_ft'] || touched['height_in'] : touched['height_cm']

  const clearFieldAndErrors = async (fieldName: string) => {
    await setFieldValue(fieldName, '')
    setFieldError(fieldName, undefined)
    await setFieldTouched(fieldName, false)
  }

  const resetForm = async () => {
    await clearFieldAndErrors('height_ft')
    await clearFieldAndErrors('height_in')
    await clearFieldAndErrors('height_cm')
  }

  const onSelectWithReset = (option: string) => {
    resetForm().then(() => {
      onSelect(option)
    })
  }

  return (
    <div>
      <DSFlex direction="row">
        {selectedUnit === 'ft' ? (
          <>
            <DSInput name="height_ft" placeholder="feet" type="number" min={0} />
            <DSSpacer size={12} direction="horizontal" />
            <DSInput name="height_in" placeholder="inches" type="number" min={0} max={11} />
          </>
        ) : (
          <DSInput name="height_cm" placeholder="Add height" type="number" min={0} />
        )}
        <DSSpacer size={12} direction="horizontal" />

        <StyledDropDown>
          <DSDropdown
            name="height_unit"
            onSelect={onSelectWithReset}
            selected={selectedUnit}
            options={[
              { label: 'cm', value: 'cm' },
              { label: 'feet', value: 'ft' },
            ]}
          />
        </StyledDropDown>
      </DSFlex>
      {selectedError && selectedTouched && <DSFormErrorText>{selectedError}</DSFormErrorText>}
    </div>
  )
}

export const heightValidationSchema = {
  height_unit: Yup.string().required(),
  height_ft: Yup.number().when('height_unit', {
    is: (height_unit) => height_unit === 'ft',
    then: Yup.number()
      .required('Please enter your height')
      .integer('Your height in feet must be a whole number')
      .min(MIN_HEIGHT_FEET, `Your height must be above ${MIN_HEIGHT_FEET} ft ${MIN_HEIGHT_INCHES} in`)
      .max(MAX_HEIGHT_FEET, `Your height must be below ${MAX_HEIGHT_FEET} ft ${MAX_HEIGHT_INCHES} in`),
    otherwise: Yup.number(),
  }),
  height_in: Yup.number().when(['height_unit', 'height_ft'], {
    is: (height_unit, height_ft) => height_unit === 'ft' && height_ft >= MIN_HEIGHT_FEET,
    then: Yup.number()
      .notRequired()
      .integer('Your height in inches must be a whole number')
      .min(MIN_HEIGHT_INCHES, `Your height must be above ${MIN_HEIGHT_FEET} ft ${MIN_HEIGHT_INCHES} in`)
      .max(MAX_HEIGHT_INCHES, `Your height must be below ${MAX_HEIGHT_FEET} ft ${MAX_HEIGHT_INCHES} in`),
    otherwise: Yup.number(),
  }),
  height_cm: Yup.number().when('height_unit', {
    is: (height_unit) => height_unit === 'cm',
    then: Yup.number()
      .required('Please enter your height')
      .integer('Your height in cm must be a whole number')
      .min(MIN_HEIGHT_CM, `Your height must be above ${MIN_HEIGHT_CM} cm`)
      .max(MAX_HEIGHT_CM, `Your height must be below ${MAX_HEIGHT_CM} cm`),
    otherwise: Yup.number(),
  }),
}
export const validationSchema = (): Yup.ObjectSchema => Yup.object(heightValidationSchema)
