import React, { useMemo } from 'react'
import { FormScreen, FormValidationSchema, FormValues } from 'components/screen-templates/FormScreen'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { Quizzes } from 'redux/quiz/types'
import { lowerHealthyBMIWeightForHeightForUnit, totalLbsFromStoneAndLbs } from 'libs/weight/weightCalculations'
import * as Yup from 'yup'
import { MAX_ALLOWED_WEIGHT_KG, MAX_ALLOWED_WEIGHT_LBS } from 'libs/weight/types'
import { Answer } from 'clients/quiz-service'
import { IntlShape, useIntl } from 'react-intl'
import { DSFlex, DSFormErrorText, DSInput, DSSpacer, DSText } from '@zoe/ds-web'
import { useFormikContext } from 'formik'

export type TargetWeightScreenProps = {
  title: string
  descriptionLbs: string
  descriptionKg: string
  buttonText?: string
}

type Props = TargetWeightScreenProps & {
  onBackClick?: onBackClickCallback
  onNextClick: onNextClickType
  quiz: Quizzes
}

export const TargetWeightScreen: React.FC<Props> = ({
  quiz,
  title,
  descriptionLbs,
  descriptionKg,
  buttonText,
  onNextClick,
  onBackClick,
}) => {
  const intl = useIntl()
  const initialValues = useMemo(
    () => ({
      ideal_weight_lbs: '',
      ideal_weight_kg: '',
      ideal_weight_st: '',
      ideal_weight_st_lbs: '',
      weight_unit: quiz.weight_unit,
    }),
    [quiz.weight_unit],
  )

  const handleOnNextClick = (arg1?: FormValues | Answer[] | any) => {
    const args = arg1 || {}
    if (args.weight_unit === 'stone') {
      onNextClick({ ideal_weight_lbs: totalLbsFromStoneAndLbs(args.ideal_weight_st, args.ideal_weight_st_lbs || 0) })
    } else if (args.weight_unit === 'lbs') {
      onNextClick({ ideal_weight_lbs: args.ideal_weight_lbs })
    } else {
      onNextClick({ ideal_weight_kg: args.ideal_weight_kg })
    }
  }

  return (
    <FormScreen
      screenTitle={title}
      buttonText={buttonText}
      description={quiz.weight_unit === 'kg' ? descriptionKg : descriptionLbs}
      form={{
        body: <TargetWeightScreenBody weightUnit={quiz.weight_unit} intl={intl} />,
        validationSchema: validationSchema(intl, quiz),
        // needed to prevent React "uncontrolled" error showing
        initialValues,
      }}
      onNextClick={handleOnNextClick}
      onBackClick={onBackClick}
    />
  )
}

const TargetWeightScreenBody = ({ weightUnit, intl }: { weightUnit: string; intl: IntlShape }) => {
  const { errors, touched } = useFormikContext<any>()
  const placeholder = useMemo(() => {
    return intl.formatMessage({ id: 'quiz.ideal_weight.weight_field.label' }, { unit: weightUnit })
  }, [weightUnit])

  return (
    <>
      {weightUnit === 'lbs' && (
        <div>
          <DSInput name={'ideal_weight_lbs'} placeholder={placeholder} type="number" step={1} />
          {errors.ideal_weight_lbs && touched.ideal_weight_lbs && (
            <DSFormErrorText>{errors.ideal_weight_lbs}</DSFormErrorText>
          )}
        </div>
      )}

      {weightUnit === 'kg' && (
        <div>
          <DSInput name={'ideal_weight_kg'} placeholder={placeholder} type="number" step={0.1} />
          {errors.ideal_weight_kg && touched.ideal_weight_kg && (
            <DSFormErrorText>{errors.ideal_weight_kg}</DSFormErrorText>
          )}
        </div>
      )}

      {weightUnit === 'stone' && (
        <>
          <DSText variant="fluid-label-100" weight="lotaLight">
            {placeholder}
          </DSText>
          <DSFlex direction="row">
            <DSInput name="ideal_weight_st" placeholder="stone" type="number" min={0} />
            <DSSpacer size={12} direction="horizontal" />
            <DSInput name="ideal_weight_st_lbs" placeholder="lbs" type="number" min={0} max={13} />
          </DSFlex>
          {(errors['ideal_weight_st'] || errors['ideal_weight_st_lbs']) &&
            (touched['ideal_weight_st'] || touched['ideal_weight_st_lbs']) && (
              <DSFormErrorText>{errors['ideal_weight_st'] || errors['ideal_weight_st_lbs']}</DSFormErrorText>
            )}
        </>
      )}
    </>
  )
}

const validationSchema = (intl: IntlShape, quiz: Quizzes): FormValidationSchema => {
  const minWeightLBS = useMemo(() => lowerHealthyBMIWeightForHeightForUnit(quiz, 'lbs'), [quiz])
  const minWeightKG = useMemo(() => lowerHealthyBMIWeightForHeightForUnit(quiz, 'kg'), [quiz])
  const minWeightStones = useMemo(
    () => ({
      stone: Math.floor(minWeightLBS.value / 14),
      lbs: minWeightLBS.value % 14,
    }),
    [minWeightLBS],
  )

  return Yup.object({
    weight_unit: Yup.string(),
    ideal_weight_lbs: Yup.number().when('weight_unit', {
      is: (weight_unit) => weight_unit === 'lbs',
      then: Yup.number()
        .required(intl.formatMessage({ id: 'quiz.ideal_weight.weight_field.error' }))
        .min(
          minWeightLBS.value,
          intl.formatMessage(
            {
              id: 'quiz.ideal_weight.validation.lower_bmi_limit',
            },
            { weight: minWeightLBS.value, unit: 'lbs' },
          ),
        )
        .max(
          MAX_ALLOWED_WEIGHT_LBS,
          intl.formatMessage(
            { id: 'quiz.weight_validation.max_weight' },
            { maxWeight: MAX_ALLOWED_WEIGHT_LBS, unit: 'lbs' },
          ),
        ),
      otherwise: Yup.number(),
    }),
    ideal_weight_kg: Yup.number().when('weight_unit', {
      is: (weight_unit) => weight_unit === 'kg',
      then: Yup.number()
        .required(intl.formatMessage({ id: 'quiz.ideal_weight.weight_field.error' }))
        .min(
          minWeightKG.value,
          intl.formatMessage(
            {
              id: 'quiz.ideal_weight.validation.lower_bmi_limit_st_lbs',
            },
            { stone: minWeightStones.stone, lbs: minWeightStones.lbs },
          ),
        )
        .max(
          MAX_ALLOWED_WEIGHT_KG,
          intl.formatMessage(
            { id: 'quiz.weight_validation.max_weight' },
            { maxWeight: MAX_ALLOWED_WEIGHT_KG, unit: 'kg' },
          ),
        ),
      otherwise: Yup.number(),
    }),

    // Validation for stones and lbs
    ideal_weight_st: Yup.number().when('weight_unit', {
      is: (weight_unit) => weight_unit === 'stone',
      then: Yup.number()
        .required(intl.formatMessage({ id: 'quiz.ideal_weight.weight_field.error' }))
        .test(
          'min-allowed-weight',
          intl.formatMessage(
            {
              id: 'quiz.ideal_weight.validation.lower_bmi_limit_st_lbs',
            },
            { stone: minWeightStones.stone, lbs: minWeightStones.lbs },
          ),
          function () {
            const { ideal_weight_st, ideal_weight_st_lbs } = this.parent
            const totalLbs = ideal_weight_st * 14 + (ideal_weight_st_lbs || 0)
            return totalLbs >= minWeightLBS.value
          },
        )
        .test(
          'max-allowed-weight',
          intl.formatMessage(
            { id: 'quiz.weight_validation.max_weight_st_lbs' },
            { stone: Math.floor(MAX_ALLOWED_WEIGHT_LBS / 14), lbs: MAX_ALLOWED_WEIGHT_LBS % 14 },
          ),
          function () {
            const { ideal_weight_st, ideal_weight_st_lbs } = this.parent
            const totalLbs = ideal_weight_st * 14 + (ideal_weight_st_lbs || 0)
            return totalLbs <= MAX_ALLOWED_WEIGHT_LBS
          },
        ),
      otherwise: Yup.number(),
    }),

    ideal_weight_st_lbs: Yup.number().when('weight_unit', {
      is: (weight_unit) => weight_unit === 'stone',
      then: Yup.number()
        .notRequired()
        .min(0, 'You cannot enter a negative number')
        .max(13, intl.formatMessage({ id: 'quiz.weight_validation.lbs_in_stone' })),
      otherwise: Yup.number(),
    }),
  })
}
