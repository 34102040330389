import React from 'react'
import { ProgressBar } from '../../screen-components/ProgressBar'
import { DSSpacer } from '@zoe/ds-web'
import { Menopause } from '../../../redux/quiz/types'
import { MenopauseAverageData } from '../../../libs/menoscale/utils'

type Props = {
  score: number
  menopauseStatus: Menopause
  averages: MenopauseAverageData | null
}

const Triangle = ({ direction }: { direction: 'up' | 'down' }) => {
  const baseStyle = {
    borderTopColor: '#fff',
    borderRightColor: 'transparent',
    borderLeftColor: 'transparent',
    borderLeftWidth: '4px',
    borderRightWidth: '4px',
  }
  const downStyle = { ...baseStyle, borderTopWidth: '8px' }
  const upStyle = { ...baseStyle, borderBottomWidth: '8px' }

  return <div className="w-0 h-0" style={direction == 'up' ? upStyle : downStyle}></div>
}

const getAverages = (averages: MenopauseAverageData | null, menopauseStatus: Menopause) => {
  switch (menopauseStatus) {
    case 'perimenopausal':
      return [{ label: 'Average', average: averages?.perimenopausal }]
    case 'postmenopausal':
      return [{ label: 'Average', average: averages?.postmenopausal }]
    case 'do_not_know':
    case 'premenopausal':
    case 'prefer_not_to_say':
      return [
        { label: 'Postmenopausal', average: averages?.postmenopausal },
        { label: 'Perimenopausal', average: averages?.perimenopausal },
      ]
  }
}

const ProgressBarLabel = ({ label }: { label: string }) => (
  <p
    style={{
      fontSize: 'var(--font-size-fluid-label-100)',
      lineHeight: 'var(--line-height-fluid-label-100)',
      margin: 0,
      color: 'var(--colour-text-secondary-inverse)',
      width: 'max-content',
      transform: 'translate(-50%)',
    }}
  >
    {label}
  </p>
)

export const MenoscaleScoreBar: React.FC<Props> = (props) => {
  const averages = getAverages(props.averages, props.menopauseStatus)
  return (
    <>
      <DSSpacer size={24} direction="vertical" />
      {averages && averages.length > 1 && (
        <div className="justify-center w-3/5">
          <div style={{ position: 'relative', transform: 'translate(-4px)', left: `${averages[1].average}%` }}>
            <ProgressBarLabel label={averages[1].label} />
            <Triangle direction="down" />
          </div>
        </div>
      )}
      <div className="flex justify-center w-3/5">
        <ProgressBar
          completionPercentage={props.score / 100}
          color="#FFD100"
          backgroundColor="rgba(255, 255, 255, 0.2)"
        />
      </div>
      {averages && averages.length > 0 && (
        <div className="justify-center w-3/5">
          <div style={{ position: 'relative', transform: 'translate(-4px)', left: `${averages[0].average}%` }}>
            <Triangle direction="up" />
            <ProgressBarLabel label={averages[0].label} />
          </div>
        </div>
      )}
    </>
  )
}
