import { QUIZ_COMPLETE_COOKIE_NAME } from 'components/constants'
import { useEffect } from 'react'
import { useCookies } from 'react-cookie'
import { useSelector } from 'react-redux'
import { selectQuizCountry } from 'redux/quiz/slice'
import { cookieDomain, cookieExpiryDate } from '../screen-templates/EntryScreen'
import React from 'react'

export type ExitScreenProps = {
  exitPath: string
}

export const ExitScreen: React.FC<ExitScreenProps> = (props): null => {
  const quizCountry = useSelector(selectQuizCountry)
  const [, setCookies] = useCookies([QUIZ_COMPLETE_COOKIE_NAME])

  useEffect(() => {
    setCookies(QUIZ_COMPLETE_COOKIE_NAME, true, {
      path: '/',
      expires: cookieExpiryDate(180),
      domain: cookieDomain,
    })

    window.location.assign(props.exitPath)
  }, [quizCountry])

  return null
}
