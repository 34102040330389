import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'

import { IntlProvider } from 'react-intl'
import { Provider as ReduxProvider } from 'react-redux'

import { QuizServiceProvider } from 'contexts/QuizServiceContext'
import { defaultRichTextElements } from 'i18n/utils/formatting'
import { PersistGate } from 'redux-persist/integration/react'
import { configureStore } from 'redux/store'
import { MessageFormatElement } from 'intl-messageformat-parser'

import TagManager from 'react-gtm-module'
import { appConfig, GTM_ZOE_DATALAYER } from 'appConfig'

import { ScreenRoutes } from 'components/versioned-components/v5/ScreenRoutes'

import { CookiesProvider } from 'react-cookie'
import {
  KAMELEOON_GLOBAL_VARIABLE_NAME,
  KAMELEOON_QUEUE_NAME,
  safelyCallKameleoon,
  sendExperimentViewedToGA4,
} from './libs/kameleoon'
import { GlobalStyles } from '@zoe/ds-web'
import { ExperimentContextProvider } from './contexts/ExperimentContext'

const { persistor, store } = configureStore()

TagManager.initialize({ gtmId: appConfig.gtmV2Id, dataLayerName: GTM_ZOE_DATALAYER })

export interface AppProps {
  locale: string
  messages: Record<string, string> | Record<string, MessageFormatElement[]>
}

const App: React.FC<AppProps> = (props) => {
  useEffect(() => {
    window.addEventListener('Kameleoon::ExperimentActivated', function (event: any) {
      sendExperimentViewedToGA4(event.detail.experiment)
    })
  }, [])

  useEffect(() => {
    const kameleoonQueue = window[KAMELEOON_QUEUE_NAME]
    if (kameleoonQueue) {
      kameleoonQueue.push(() => {
        safelyCallKameleoon(window[KAMELEOON_GLOBAL_VARIABLE_NAME]?.API?.Experiments?.getActive)?.forEach(
          sendExperimentViewedToGA4,
        )
      })
    }
  }, [])

  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CookiesProvider>
          <ExperimentContextProvider>
            <BrowserRouter basename={process.env.REACT_APP_ROUTER_BASENAME || '/'}>
              <IntlProvider
                locale={props.locale}
                messages={props.messages}
                defaultRichTextElements={defaultRichTextElements}
              >
                <QuizServiceProvider>
                  <GlobalStyles />
                  <ScreenRoutes />
                </QuizServiceProvider>
              </IntlProvider>
            </BrowserRouter>
          </ExperimentContextProvider>
        </CookiesProvider>
      </PersistGate>
    </ReduxProvider>
  )
}

export default App

// TODO: Find better location
export type AppDispatch = typeof store.dispatch
