import { sha256 } from 'js-sha256'

type GTMDataLayer = { [key: string]: string | number | undefined }
type EmailListType = 'nutrition' | 'quiz' | 'menoscale'
export type VideoAction = 'Play' | 'Pause' | 'Complete' | 'Mute' | 'Unmute'

const getQuizCookies = () => {
  return document.cookie.split('; ')
}

const getQuizUserId = () => {
  return getQuizCookies()
    .find((row) => row.startsWith('cUserId='))
    ?.split('=')[1]
}

const getQuizFlow = () => {
  return getQuizCookies()
    .find((row) => row.startsWith('cQuizFlow='))
    ?.split('=')[1]
}

const PLATFORM_NUTRITION_SITE = 'nutrition_site'
const SECTION_QUIZ = 'quiz'

export const signupEventDataLayer = (
  emailList: EmailListType,
  quizVersion: string,
  email: string,
  marketingConsent: 'denied' | 'granted',
  tag?: string,
): GTMDataLayer => ({
  platform: PLATFORM_NUTRITION_SITE,
  event: 'sign_up',
  section: SECTION_QUIZ,
  user_id: getQuizUserId(),
  quiz_version: quizVersion,
  email_list: emailList,
  hashed_email: email ? sha256(email.toLowerCase().trim()) : undefined,
  marketing_consent: marketingConsent,
  tag,
})

export const questionAnsweredDataLayer = (
  quizVersion: string,
  questionName: string,
  questionAnswer: string | number,
): GTMDataLayer => ({
  event: 'quiz_question_answered',
  platform: PLATFORM_NUTRITION_SITE,
  section: SECTION_QUIZ,
  user_id: getQuizUserId(),
  quiz_branch: getQuizFlow(),
  quiz_version: quizVersion,
  question_name: questionName,
  question_answer: questionAnswer,
  // question_number: '[Question number, only for mandatory questions]',
  // question_mandatory: '[Either "TRUE" or "FALSE" based on whether the question is displayed for every user (e.g. Plants) or not (e.g. Pregnancy)]',
})

export const videoEventDataLayer = (
  videoName: string,
  videoAction: VideoAction,
  videoLength: number,
): GTMDataLayer => ({
  event: 'video_action',
  section: SECTION_QUIZ,
  platform: PLATFORM_NUTRITION_SITE,
  video_action: videoAction,
  video_name: videoName,
  video_length: videoLength,
  user_id: getQuizUserId(),
})

export const quizStartDataLayer = (quizVersion: string): GTMDataLayer => ({
  platform: PLATFORM_NUTRITION_SITE,
  event: 'quiz_start',
  section: SECTION_QUIZ,
  user_id: getQuizUserId(),
  link_position: 'page_body',
  quiz_version: quizVersion,
})

export const ctaClickDataLayer = (quizVersion: string): GTMDataLayer => ({
  platform: PLATFORM_NUTRITION_SITE,
  event: 'user_click',
  click_type: 'cta',
  section: SECTION_QUIZ,
  user_id: getQuizUserId(),
  link_position: 'page_body',
  quiz_version: quizVersion,
})
