import React from 'react'
import { scaleLinear, scaleSqrt } from 'd3-scale'

type Props = {
  completionPercentage: number
  size?: 'small' | 'medium'
  color?: string
  backgroundColor?: string
  scaling?: 'linear' | 'sqrt'
}
export const ProgressBar: React.FC<Props> = (props) => {
  // we will use a linear scale by default, but we can also use a square root scale (which progresses faster at the beginning)
  const scalingFunction = props.scaling === 'sqrt' ? scaleSqrt() : scaleLinear()
  const progress = scalingFunction
    // we will receive values between 0 and 1...
    .domain([0, 1])
    // ... and we map them to the range defined below (2% to 100%)
    // We don't want to return 0% or 1% because it would be hard to see the progress bar.
    .range([2, 100])(props.completionPercentage)

  const heightClass = props.size === 'medium' ? 'h-3' : 'h-1'
  const color = props.color ?? '#000'
  const backgroundColor = props.backgroundColor ?? '#f6f2ec'
  return (
    <div className={`flex flex-row w-full rounded-full ${heightClass}`} style={{ background: backgroundColor }}>
      <div
        className={`${heightClass} rounded-full`}
        style={{
          width: `${progress}%`,
          background: color,
          transition: 'width 0.5s ease-in-out',
        }}
      ></div>
    </div>
  )
}
