import React from 'react'

export interface ButtonProps extends React.ComponentProps<'button'> {
  long?: string
}

export const Button: React.FC<ButtonProps> = React.memo((props) => (
  // had to use inline styles because Tailwind padding with decimals in name doesn't work for some reason
  <button
    className={`
    box-border appearance-none
    bg-button-primary hover:bg-button-secondary text-button-text
    text-1 text-base
    leading-6
    px-2 w-full 
    ${props.long ? 'max-w-button-max-width-long' : 'max-w-button-max-width-mobile'}
    rounded-md
    cursor-pointer focus:outline-none disabled:bg-zoe-disabled disabled:cursor-not-allowed`}
    style={{ paddingTop: '0.875rem', paddingBottom: '0.875rem' }}
    data-testid="continue-button"
    {...props}
  />
))
