import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Quizzes } from '../../../redux/quiz/types'
import { appConfig } from '../../../appConfig'
import { getParasitePrevalence } from '../../../data/parasiteData'

type Props = {
  text?: string
  quiz?: Quizzes
  additionalTokens?: Record<string, any>
}
export const ContextAwareText: React.FC<Props> = ({ text, quiz, additionalTokens }) => {
  const intl = useIntl()

  if (!text) {
    return null
  }

  return (
    <FormattedMessage
      id={text}
      defaultMessage={text}
      values={{
        br: <br />,
        hr: <hr />,
        b: (chunks) => <b>{chunks}</b>,
        gutGuideLink: (
          <a href={`${appConfig.zoeWebsiteBaseUri}/gutguide`} className="text-zoe-primary">
            Gut Guide
          </a>
        ),
        privacyPolicy: (
          <a href={`${appConfig.zoeWebsiteBaseUri}/privacy-policy`} className="text-zoe-primary">
            Privacy Policy
          </a>
        ),
        gender: quiz?.gender
          ? intl
              .formatMessage({
                id: `quiz.gender.${quiz.gender}.display_plural`,
              })
              .toLowerCase()
          : '',
        ageRange: quiz?.age_range
          ? intl.formatMessage({
              id: `quiz.age_range.${quiz.age_range}`,
            })
          : '',
        blastocystisPrevalence:
          quiz?.age_range && quiz?.sex ? getParasitePrevalence(quiz.age_range, quiz.sex)?.toFixed() : '',
        ...(additionalTokens ?? {}),
      }}
    />
  )
}
