import React, { useContext } from 'react'

import { Configuration, UserApi, QuizApi } from 'clients/quiz-service'
import { appConfig } from 'appConfig'

const config = new Configuration()

export const userApi = new UserApi(config, appConfig.quizServiceApiBaseUri)
export const quizApi = new QuizApi(config, appConfig.quizServiceApiBaseUri)

export interface QuizServiceProviderProps {
  children: React.ReactNode
}

type Context = {
  userApi: UserApi
  quizApi: QuizApi
}

export const QuizServiceContext = React.createContext<Context>({ userApi, quizApi })
QuizServiceContext.displayName = 'QuizServiceContext'

export const QuizServiceProvider: React.FC<QuizServiceProviderProps> = (props: QuizServiceProviderProps) => (
  <QuizServiceContext.Provider value={{ userApi, quizApi }}>{props.children}</QuizServiceContext.Provider>
)

export const QuizServiceConsumer = QuizServiceContext.Consumer

export const useQuizService = (): Context => useContext(QuizServiceContext)
