import { appConfig } from 'appConfig'
import { WeightLossData } from 'data/getWeightLossData'
import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

type AverageWeightLossGraphProps = {
  weightLossData: WeightLossData
}

export const AverageWeightLossGraph: React.FC<AverageWeightLossGraphProps> = ({
  weightLossData: { weightLossGraph, averageWeightLoss, highWeightLoss, weightUnit },
}) => {
  const intl = useIntl()
  if (weightLossGraph) {
    const averageLossWithUnit = averageWeightLoss + weightUnit
    const highLossWithUnit = highWeightLoss + weightUnit
    return (
      <div className="max-w-card-width-mobile sm:max-w-card-width-desktop mx-auto pt-6 px-6 pb-8 bg-background-weight-graph rounded-br-2xl rounded-bl-2xl ">
        <img
          className="w-full"
          src={`${appConfig.publicUrl}/img/quiz/weight_graphs${weightLossGraph}`}
          alt={intl.formatMessage({ id: 'quiz.advice.weight_loss.statistic.weight.graph.title' }, { unit: weightUnit })}
        />
        <p className="light mt-6">
          <FormattedMessage
            id="quiz.advice.weight_loss.statistic.weight.graph.text"
            values={{ averageWeightLoss: averageLossWithUnit, highWeightLoss: highLossWithUnit }}
          />
        </p>

        <p className="text-xs leading-4 light mt-8">
          <FormattedMessage
            id="quiz.advice.weight_loss.statistic.weight.graph.footer"
            values={{ averageWeightLoss: averageLossWithUnit, highWeightLoss: highLossWithUnit }}
          />
        </p>
      </div>
    )
  }

  return <></>
}
