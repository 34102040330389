import { useMemo } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Quiz } from 'redux/quiz/types'
import { LegacyQuizScreen } from '../../../config/types'

export interface Navigator {
  previous: () => void
  next: (updatedQuiz?: Quiz) => void
  currentPage: string
  currentPageIndex: number
  hideProgressBar: boolean
}

export const useNavigator = (screens: LegacyQuizScreen[], quiz: Quiz): Navigator => {
  const history = useHistory()
  const location = useLocation()

  const currentPage = useMemo(() => location.pathname, [location])
  const currentPageIndex = screens.findIndex((screen) => screen.path === location.pathname)
  const hideProgressBar = useMemo(() => shouldHideProgressBar(screens[currentPageIndex]), [currentPageIndex])

  const previous = () => {
    const previousScreen: LegacyQuizScreen = getPreviousScreen(quiz, screens, currentPageIndex)
    history.push(previousScreen.path)
    window.scrollTo(0, 0)
  }

  const next = (updatedQuiz?: Quiz) => {
    const latestQuiz = updatedQuiz || quiz
    const nextScreen = getNextScreen(latestQuiz, screens, currentPageIndex)
    history.push(nextScreen.path)
    window.scrollTo(0, 0)
  }

  return { previous, next, currentPage, currentPageIndex, hideProgressBar }
}

export const getNextScreen = (quiz: Quiz, screens: LegacyQuizScreen[], currentPageIndex: number): LegacyQuizScreen => {
  const remainingScreens = screens.slice(currentPageIndex + 1)
  return remainingScreens.find((screen) => shouldShowScreen(screen, quiz)) || screens[currentPageIndex]
}
export const getPreviousScreen = (
  quiz: Quiz,
  screens: LegacyQuizScreen[],
  currentPageIndex: number,
): LegacyQuizScreen => {
  const previousScreens = screens.slice(0, currentPageIndex).reverse()
  return previousScreens.find((screen) => shouldShowScreen(screen, quiz)) || screens[currentPageIndex]
}

const shouldShowScreen = (screen: LegacyQuizScreen, quiz: Quiz) => screen.showIf === undefined || screen.showIf(quiz)

const shouldHideProgressBar = (screen: LegacyQuizScreen) => screen?.hideProgressBar ?? false
