import React from 'react'
import { FormScreen } from 'components/screen-templates/FormScreen'
import * as Yup from 'yup'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { Conditions, Quizzes } from '../../../redux/quiz/types'
import { ContextAwareText } from '../text/ContextAwareText'
import { CardGroup } from '../fields/CardGroup'
import { Card } from '../fields/Card'

type Option = {
  label: string
  value: string
}

export type CardSelectionScreenProps = {
  title: string
  description?: string
  buttonTitle?: string
  questionKey: string
  options: Option[]
  hideBackLink?: boolean
}

type Props = CardSelectionScreenProps & {
  onNextClick: onNextClickType
  onBackClick: onBackClickCallback
  conditionValues: Required<Conditions>
  quiz: Quizzes
}

export const CardSelectionScreen: React.FC<Props> = (props: Props) => {
  const options = props.options.map((option, index) => (
    <Card
      key={index}
      name={props.questionKey}
      id={option.value}
      value={option.value}
      label={option.label}
      icon={option.value}
    />
  ))
  return (
    <FormScreen
      screenTitle={props.title}
      description={props.description ? <ContextAwareText text={props.description} quiz={props.quiz} /> : null}
      buttonText={props.buttonTitle ?? 'Continue'}
      form={{
        body: <CardGroup name={props.questionKey}>{options}</CardGroup>,
        initialValues: {},
        validationSchema: Yup.object({
          [props.questionKey]: Yup.string().required(),
        }),
      }}
      onNextClick={props.onNextClick}
      onBackClick={props.hideBackLink ? undefined : props.onBackClick}
    />
  )
}
