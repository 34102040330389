import { ScreenProps } from 'components/types'
import React from 'react'

export interface CardsScreenProps extends ScreenProps {
  title: React.ReactNode
  subTitle: React.ReactNode
  body: React.ReactNode
  footer: React.ReactNode
}

export const CardsScreen: React.FC<CardsScreenProps> = ({ title, subTitle, body, footer }) => {
  return (
    <div>
      <main className="flex justify-center flex-col bg-decorative-pear">
        {/* Left font sizes and line spacing to be consistent with rest of quiz */}
        <div className="bg-white rounded-b-2xl">
          <div className="mt-10 mb-12 mx-auto max-w-card-width-mobile sm:max-w-card-width-desktop text-center text-base-primary">
            <h1 className="light mb-4 text-2xl leading-8 sm:text-display-2xs">{title}</h1>
            <p className="light text-lg leading-normal sm:text-2xl sm:leading-8">{subTitle}</p>
          </div>
        </div>
        <div className="py-6 sm:py-10">{body}</div>
        <footer className="bg-white rounded-t-2xl">{footer}</footer>
      </main>
    </div>
  )
}
