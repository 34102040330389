import React, { useState } from 'react'
import { FormScreen } from 'components/screen-templates/FormScreen'
import * as Yup from 'yup'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { RadioButton } from 'components/core/fields/RadioButton'
import { RadioButtonGroup } from 'components/core/fields/RadioButtonGroup'
import { useIntl } from 'react-intl'

export type PregnancyScreenProps = {
  title: string
  description: string
  errorMessageWhenPregnant: string
  buttonText?: string
}

type Props = PregnancyScreenProps & {
  onNextClick: onNextClickType
  onBackClick: onBackClickCallback
}

export const PregnancyScreen: React.FC<Props> = ({
  title,
  description,
  errorMessageWhenPregnant,
  buttonText,
  onBackClick,
  onNextClick,
}) => {
  const intl = useIntl()
  const [isPregnant, setIsPregnant] = useState<boolean>(false)

  return (
    <FormScreen
      screenTitle={title}
      description={description}
      buttonText={buttonText ?? 'Continue'}
      showHomepageLink={isPregnant}
      form={{
        body: (
          <RadioButtonGroup name="pregnant">
            <RadioButton
              name="pregnant"
              id="true"
              value="true"
              label={intl.formatMessage({ id: 'yes' })}
              clicked={() => {
                setIsPregnant(true)
              }}
            />
            <RadioButton
              name="pregnant"
              id="false"
              value="false"
              label={intl.formatMessage({ id: 'no' })}
              clicked={() => {
                setIsPregnant(false)
              }}
            />
          </RadioButtonGroup>
        ),
        validationSchema: Yup.object({
          pregnant: Yup.string().required().matches(/false/, errorMessageWhenPregnant),
        }),
      }}
      onNextClick={onNextClick}
      onBackClick={onBackClick}
    />
  )
}
