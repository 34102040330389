import React from 'react'
import { LogoHeader } from 'components/screen-templates/LogoHeader'
import { LegacyQuizScreen, QuizVersion } from '../../config/types'
import { ProgressBar } from './ProgressBar'
import { useExperiments } from '../../contexts/ExperimentContext'

interface HeaderBarProps {
  currentPageIndex: number
  hideProgressBar: boolean
  screens: LegacyQuizScreen[]
  quizVersion: QuizVersion
}

export const HeaderBar: React.FC<HeaderBarProps> = ({ screens, currentPageIndex, hideProgressBar, quizVersion }) => {
  // we ignore conditional screens (showWhen) when calculating the progress bar, to give a better sense of progress - as there
  // are many optional screens that wouldn't be shown to the user and would make the progress bar misleading
  const remainingScreens = screens.slice(currentPageIndex + 1).filter((screen) => screen.isIncludedInProgress).length
  const currentCompletion = currentPageIndex / Math.max(currentPageIndex + remainingScreens, 1)
  const { isExperimentActive } = useExperiments()
  const scaling = quizVersion === 'v5' && isExperimentActive('progress-bar-sqrt') ? 'sqrt' : 'linear'

  // is last page of the menoscale quiz
  const onMenoscaleResultsScreen = quizVersion === 'menoscale' && currentPageIndex === screens.length - 1

  return (
    <>
      <LogoHeader className={onMenoscaleResultsScreen ? 'bg-surface-aubergine-bold md:bg-transparent' : undefined} />
      {hideProgressBar || (
        <div className="flex content-center w-form-width-mobile sm:w-form-width-desktop sm:justify-start mx-auto">
          <div className="mt-4 w-full">
            <ProgressBar completionPercentage={currentCompletion} scaling={scaling} />
          </div>
        </div>
      )}
    </>
  )
}
