import { Answer } from 'clients/quiz-service'
import { InputField } from 'components/core/fields/InputField'
import { onBackClickCallback, onNextClickType } from 'components/types'
import React from 'react'
import * as Yup from 'yup'
import { FormScreen, FormValues } from '../../screen-templates/FormScreen'

export type TextInputScreenProps = {
  questionKey: string
  title: string
  placeholder: string
  buttonText?: string
}

type Props = TextInputScreenProps & {
  onNextClick: onNextClickType
  onBackClick: onBackClickCallback
}

export const TextInputScreen: React.FC<Props> = ({
  title,
  buttonText,
  questionKey,
  onBackClick,
  onNextClick,
  placeholder,
}) => {
  const handleOnNextClick = (questionKey: string, arg1?: FormValues | Answer[] | any) => {
    const args = arg1 || {}

    if (args[questionKey]) {
      onNextClick({ [questionKey]: args[questionKey] })
    } else {
      onNextClick(undefined)
    }
  }

  return (
    <FormScreen
      screenTitle={title}
      buttonText={buttonText ?? 'Continue'}
      form={{
        body: <InputField placeholder={placeholder} name={questionKey} type="text" />,
        validationSchema: Yup.object({
          [questionKey]: Yup.string().optional(),
        }),
        initialValues: { [questionKey]: '' },
      }}
      forceEnableButton={true}
      onNextClick={(value) => handleOnNextClick(questionKey, value)}
      onBackClick={onBackClick}
    />
  )
}
