import React from 'react'

type Props = {
  color: string
}
export const LoadingSpinner: React.FC<Props> = (props) => (
  <div
    className="m-8 inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite]"
    role="status"
    style={{ color: props.color ?? '#FFF' }}
  ></div>
)
