import { QuizConfig } from './types'
import { GB_EXIT_PATH, US_EXIT_PATH } from '../components/constants'
import { GOALS } from '../libs/kameleoon'

const v5: QuizConfig = {
  screens: [
    {
      path: '/v5/plants',
      type: 'SingleQuestionScreen',
      props: {
        title: 'How many plants do you eat?',
        description: 'Plants include fruit, vegetables, grains, beans and nuts.',
        questionKey: 'plants_in_diet',
        hideBackLink: true,
        options: [
          { label: '1-3 plants a day', value: '1_3' },
          { label: '4-6 plants a day', value: '4_6' },
          { label: '7+ plants a day', value: '7+' },
        ],
      },
    },
    {
      path: '/v5/plants-explanation',
      type: 'InfoScreen',
      useAltProps: { plants_in_diet: '7+' },
      props: {
        title: 'It turns out plants matter more than we thought!',
        description:
          'The number of plants you eat has a huge impact on the trillions of “good” and “bad” bacteria in your gut. ' +
          'These bacteria have a big influence on your health & weight.{br}{br}' +
          'Your “good” bacteria have specific plants they like best!{br}{br}' +
          'ZOE will identify the plants uniquely right for you, because your gut bacteria are different from anyone else’s.',
        topImage: '/img/quiz/plants.jpg',
        attribution: 'Published in leading medical journal Nature Medicine in 2021',
      },
      altProps: {
        title: 'Give yourself a hand!',
        description:
          'People who are already eating 7+ plants a day are the most satisfied with the ZOE program.{br}{br}' +
          'ZOE will identify specific plants uniquely right for you, because the gut bacteria you have are different from anyone else’s. And your “good bacteria” have a specific diet they like best!',
        topImage: '/img/quiz/plants.jpg',
        attribution: 'Published in leading medical journal Nature Medicine in 2021',
      },
    },
    {
      path: '/v5/health-goal',
      type: 'CardSelectionScreen',
      props: {
        title: 'What are you most interested in?',
        description: 'Choose the one that matters most now, you can add more later.',
        questionKey: 'health_goal',
        options: [
          { label: 'Improve energy', value: 'energy' },
          { label: 'Improve sleep quality', value: 'sleep' },
          { label: 'Improve mood', value: 'mood' },
          { label: 'Reduced hunger', value: 'hunger' },
          { label: 'Improve digestive health', value: 'digestion' },
          { label: 'Support menopause', value: 'menopause' },
          { label: 'Achieve a healthier weight', value: 'healthy_weight' },
        ],
      },
    },
    {
      path: '/v5/results',
      type: 'ResultsScreen',
      props: {
        buttonText: 'Continue',
        subtitle: 'But that’s <em>not all</em>',
        features: [
          { text: 'Positive changes to their gut health' },
          { text: 'Improvements in blood fat levels' },
          { text: 'Healthy weight changes' },
          {
            text: 'Twice as likely to report improved mood and reduced hunger',
            variations: {
              hunger: 'Twice as likely to report improved mood',
              mood: 'Twice as likely to report reduced hunger',
            },
          },
          {
            text: '4 times more likely to report better sleep and more energy',
            variations: {
              energy: '4 times more likely to report better sleep',
              sleep: '4 times more likely to report more energy',
            },
          },
        ],
        subtitleDescription:
          'In a clinical trial, people following ZOE reported many other benefits than those following general nutrition advice',
        healthGoalContent: {
          energy: {
            intro: 'People eating the ZOE way are',
            title: '4 times more likely to report <em>more energy</em>',
          },
          sleep: {
            intro: 'People eating the ZOE way are',
            title: '4 times more likely to report <em>better sleep</em>',
          },
          mood: {
            intro: 'People eating the ZOE way are',
            title: '2 times more likely to report <em>better mood</em>',
          },
          hunger: {
            intro: 'People eating the ZOE way are',
            title: '4 times more likely to report <em>reduced hunger</em>',
          },
          healthy_weight: {
            intro: 'People eating the ZOE way saw',
            title: '<em>Reduction</em> in their waist circumference',
            footerNotes: {
              title: 'We believe in improving your health <em>from the inside</em> first',
              description:
                'Your membership is personalised to improved your health with diet and lifestyle. ' +
                'ZOE can help you achieve your healthy weight in the long term.',
            },
          },
          digestion: {
            intro: 'People eating the ZOE way report',
            title: '<em>Positive changes</em> in their gut microbiome',
          },
          menopause: {
            intro: 'People eating the ZOE way report',
            title: '<em>Positive changes</em> in how they feel during menopause',
          },
        },
      },
    },
    {
      path: '/v5/height',
      type: 'HeightScreen',
      useAltProps: { quiz_country: 'US' },
      props: {
        title: 'What is your height?',
        subtitle:
          'We use your height to help compare you with thousands of people who have taken part in ZOE’s clinical studies.',
        defaultUnit: 'ft',
      },
      altProps: {
        title: 'What is your height?',
        subtitle:
          'We use your height to help compare you with thousands of people who have taken part in ZOE’s clinical studies.',
        defaultUnit: 'cm',
      },
    },
    {
      path: '/v5/weight',
      type: 'WeightScreen',
      useAltProps: { quiz_country: 'US' },
      props: {
        title: 'What is your weight?',
        description:
          'We ask this question, regardless of your interest in weight loss, to provide you with our most accurate calculations for your heart health.',
        defaultWeightUnit: 'stone',
      },
      altProps: {
        title: 'What is your weight?',
        description:
          'We ask this question, regardless of your interest in weight loss, to provide you with our most accurate calculations for your heart health.',
        defaultWeightUnit: 'kg',
      },
    },
    {
      path: '/v5/weight-goal',
      type: 'SingleQuestionScreen',
      props: {
        title: 'Do you have a weight goal?',
        description: 'We ask this question to understand what motivates you.',
        questionKey: 'has_weight_goal',
        options: [
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ],
      },
    },
    {
      path: '/v5/target-weight',
      type: 'TargetWeightScreen',
      showWhen: { has_weight_goal: 'true' },
      props: {
        title: 'What weight would you like to reach?',
        descriptionLbs:
          'Although restricting calories may drive quick weight loss at first, studies show that those who are most successful at maintaining weight loss are those who lose it gradually and steadily (about 1–2 lbs per week).',
        descriptionKg:
          'Although restricting calories may drive quick weight loss at first, studies show that those who are most successful at maintaining weight loss are those who lose it gradually and steadily (about 450-900g per week).',
      },
    },
    {
      path: '/v5/target-event-date-weight-loss',
      type: 'SingleQuestionScreen',
      showWhen: { weight_plan: 'weight_loss' },
      props: {
        title: 'Is there an event or date you aim to lose weight by?',
        questionKey: 'target_event_date_weight_loss',
        options: [
          { label: 'Vacation', value: 'vacation' },
          { label: 'Wedding', value: 'wedding' },
          { label: 'Holiday', value: 'holiday' },
          { label: 'Other target date', value: 'other' },
          { label: "No, I don't have a target date", value: 'none' },
        ],
      },
    },
    {
      path: '/v5/flexible-timeline',
      type: 'SingleQuestionScreen',
      showWhen: { has_target_date_weight_loss: 'true' },
      props: {
        title: 'Are you flexible on your timeline?',
        description:
          "We don't believe calorie counting is sustainable, so losing weight with ZOE may be slower than more restrictive programs. But 76% of surveyed ZOE members say it's more sustainable than diets they've tried.",
        questionKey: 'has_flexible_timeline',
        options: [
          { label: 'Yes, my priority is long-term health', value: 'true' },
          { label: 'No, I’ll find a quicker fix elsewhere', value: 'false' },
        ],
      },
    },
    {
      path: '/v5/sorry-to-see-you-go',
      type: 'SorryToSeeYouGoScreen',
      hideProgressBar: true,
      showWhen: { has_flexible_timeline: 'false' },
      props: {
        title: 'We’re sorry to see you go. But we wish you luck. 🍀',
        zoeContactEmail: 'hello@joinzoe.com',
        description:
          'We’ll be right for you if you decide to change your mind. If you have questions you can contact us on {zoeContactEmail}',
        image: '/img/quiz/why-difficulty-losing-weight-card.png',
        imageLink: '/learn/difficulty-losing-weight',
        imageAlt: 'Woman runner resting',
      },
    },
    {
      path: '/v5/sex',
      type: 'SingleQuestionScreen',
      props: {
        title: 'What sex were you assigned at birth?',
        description: 'We ask this because our research shows that women and men metabolize foods very differently.',
        questionKey: 'sex',
        // legacy logic for submitting more answers when this question is submitted.
        includeWeightGoalAnswers: true,
        options: [
          { label: 'Female', value: 'female' },
          { label: 'Male', value: 'male' },
        ],
      },
    },
    {
      path: '/v5/gender',
      type: 'SingleQuestionScreen',
      props: {
        title: 'What gender do you identify with?',
        description:
          'Some people don’t identify with the sex they were assigned at birth. So we ask for your gender so that we can address you correctly.',
        questionKey: 'gender',
        options: [
          { label: 'Female', value: 'female' },
          { label: 'Male', value: 'male' },
          { label: 'Other (please specify)', value: 'other' },
          { label: 'Prefer not to say', value: 'pnts' },
        ],
        showTextInputWhen: 'other',
        textInputPlaceholder: 'Please specify',
        textInputQuestionKey: 'gender_other',
      },
    },
    {
      path: '/v5/pregnancy',
      type: 'PregnancyScreen',
      showWhen: { sex: 'female' },
      props: {
        title: 'Are you pregnant?',
        description:
          'We ask because ZOE is not currently able to support people who are pregnant. We hope to carry out future scientific studies to understand nutrition during pregnancy.',
        errorMessageWhenPregnant: 'Unfortunately, ZOE is not currently able to support people who are pregnant.',
      },
    },
    {
      path: '/v5/age-range',
      type: 'SingleQuestionScreen',
      props: {
        title: 'What is your age range?',
        description: 'If you feel your body responds to food differently than when you were younger, you are right!',
        questionKey: 'age_range',
        options: [
          { label: 'Under 20', value: 'under_20' },
          { label: '20s', value: '20s' },
          { label: '30s', value: '30s' },
          { label: '40s', value: '40s' },
          { label: '50s', value: '50s' },
          { label: '60s', value: '60s' },
          { label: '70s', value: '70s' },
          { label: '80 and above', value: 'above_80' },
        ],
      },
    },
    {
      path: '/v5/age-consent',
      type: 'InfoScreen',
      useAltProps: { quiz_country: 'US' },
      showWhen: { age_range: 'under_20' },
      props: {
        title: 'What is your age range?',
        showHomePageLink: true,
        buttonText: 'I confirm',
        description: 'Please confirm you are at least 18 years old. We are currently unable to support anyone younger.',
      },
      altProps: {
        title: 'What is your age range?',
        showHomePageLink: true,
        buttonText: 'I confirm',
        description:
          'Please confirm you are at least 18 years of age (or 19 if you live in Alabama or Nebraska). We are currently unable to support anyone younger.',
      },
    },
    {
      path: '/v5/antibiotics',
      type: 'SingleQuestionScreen',
      props: {
        title: 'Antibiotics',
        description:
          'Frequent antibiotic usage can affect which bacteria you have in your gut. Have you taken antibiotic medication more than once in the last three years (not on the skin)?',
        questionKey: 'taken_antibiotics',
        options: [
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ],
      },
    },
    {
      path: '/v5/parasite-fact',
      type: 'InfoScreen',
      props: {
        title: 'Your gut microbes change how your body responds to food.',
        descriptionBelowTopImage:
          'Based on your answers you have a <b>{blastocystisPrevalence}%</b> chance the <b>“friendly parasite” Blastocystis</b> is in your gut.{br}{br}People with the “parasite” have 20% less internal fat.',
        footerBody:
          'Discovered by a team of scientists from <b>ZOE, Harvard, King’s, Massachusetts General Hospital & Stanford.</b>{br}{br}' +
          'To know for sure you will need to take ZOE’s poop test!',
        topImage: '/img/quiz/parasite-card-v4.png',
      },
    },
    {
      path: '/v5/hunger-levels',
      type: 'SingleQuestionScreen',
      showWhen: { weight_plan: 'weight_loss' },
      props: {
        title: 'Your hunger levels',
        description:
          '{gender} in their {ageRange} often feel hungry during the day. When you are “trying to eat well” how hungry are you?',
        questionKey: 'hunger_level',
        options: [
          { label: 'I find I am hungry all the time', value: 'all_the_time' },
          { label: 'I am often hungry', value: 'often' },
          { label: 'I am not hungry', value: 'not' },
        ],
      },
    },
    {
      path: '/v5/hunger-explanation',
      type: 'InfoScreen',
      hideProgressBar: true,
      showWhen: { is_frequently_hungry: 'true' },
      props: {
        title: "You don't need to be hungry",
        footerBody:
          'Your answers so far suggest you are prone to blood sugar dips 2-3 hours after certain meals. {br}{br}' +
          'ZOE’s research has discovered that these blood sugar dips actually make you hungry! These dips may cause people to gain many pounds a year, and over time can lead to serious health issues like diabetes and cardiovascular disease.{br}{br}' +
          'Your personalized program will identify thousands of meals that work with your body, reduce your blood sugar dips and do not trigger hunger.',
        topImage: '/img/quiz/dont-need-to-be-hungry.png',
        attribution: 'Published in leading medical journal Nature Medicine in 2021',
        attributionImage: '/img/quiz/nature-metabolism-logo.png',
      },
    },
    {
      path: '/v5/energy',
      type: 'SingleQuestionScreen',
      props: {
        title: 'Your energy',
        questionKey: 'energy',
        description:
          'Our research shows poor blood sugar control often affects {gender} in their {ageRange}. How often do you experience energy slumps during the day?',
        options: [
          { label: "Rarely or never. I'm almost always energetic", value: 'never' },
          { label: 'Sometimes I’m tired and need an energy boost', value: 'sometimes' },
          { label: 'I’m often tired and sluggish', value: 'often' },
        ],
      },
    },
    {
      path: '/v5/energy-explanation',
      type: 'InfoScreen',
      hideProgressBar: true,
      showWhen: { energy: 'often' },
      props: {
        title: 'Feeling tired is a drag.',
        description:
          'Our research has discovered how individual foods can affect how tired you feel, and even how well you sleep.',
        topImage: '/img/quiz/feeling-tired-is-a-drag.png',
        healthStat: 'strength',
      },
    },
    {
      path: '/v5/testimonial',
      type: 'TestimonialScreen',
      hideProgressBar: true,
      props: {
        title: 'ZOE helps you understand what works for <em>your body.</em>',
        testimonies: [
          {
            text: 'Zoe gives you the power to make positive change.',
            author: 'Christine Y.',
          },
          {
            text:
              '3 months in using Zoe and feeling the difference. It’s so informative, interesting and so easy to adapt to.',
            author: 'Kim B.',
          },
          {
            text:
              'If you are looking for a science-based nutrition approach that helps you optimise your health, ZOE is great.',
            author: 'GD',
          },
        ],
      },
    },
    {
      path: '/v5/location',
      type: 'SingleQuestionScreen',
      showWhen: { is_country_supported: 'true' },
      useAltProps: { quiz_country: 'US' },
      props: {
        questionKey: 'location',
        title: 'Which area do you live in?',
        description: 'We ask this to confirm availability in your area.',
        options: [
          { label: 'Jersey or Guernsey', value: 'false' },
          { label: 'Anywhere else in the UK', value: 'true' },
        ],
      },
      altProps: {
        questionKey: 'location',
        title: 'Which state do you live in?',
        options: [
          { label: 'New York', value: 'false' },
          { label: 'Any other State in the USA', value: 'true' },
        ],
      },
    },
    {
      path: '/v5/location-signup',
      type: 'LocationSignupScreen',
      hideProgressBar: true,
      showWhen: { available_in_location: 'false' },
      useAltProps: { quiz_country: 'US' },
      props: {
        title: 'ZOE is not available in your area yet',
        description:
          'The ZOE program is not currently available in Jersey or Guernsey. We’re actively working on this so enter your email to be the first to know when we launch in your area:',
        crmTag: 'gb_waitlist',
        descriptionBelowForm:
          'Thank you for your interest in ZOE — we’re excited that you’re excited about making smarter food choices for your body.' +
          '{br}{br}' +
          'We’ll also share occasional updates on our latest scientific discoveries, expert nutrition tips, and exclusive offers. ZOE don’t share any of your personal information. View our {privacyPolicy}',
        buttonText: 'Notify me',
        kameleoonGoals: {
          emailSubmitted: GOALS.QUIZ_EMAIL_SUBMITTED,
          emailSubmittedWithMarketingOptOut: GOALS.QUIZ_EMAIL_SUBMITTED_WITH_MARKETING_OPT_OUT,
          emailSubmittedWithMarketingConsent: GOALS.QUIZ_EMAIL_SUBMITTED_WITH_MARKETING_CONSENT,
        },
      },
      altProps: {
        title: 'Be the first to access ZOE in New York',
        description:
          'Thank you for your interest in ZOE — we’re excited that you’re excited about making smarter food choices for your body. ' +
          '{br}{br}' +
          'We’re still working on opening it up to New York customers. Enter your email to be first to know when ZOE is available in New York.',
        crmTag: 'us_waitlist',
        descriptionBelowForm:
          'In the meantime, we’ll also share updates on our latest scientific discoveries, expert nutrition tips and exclusive offers. ZOE don’t share your personal information. View our {privacyPolicy}',
        buttonText: 'Join the waitlist',
        kameleoonGoals: {
          emailSubmitted: GOALS.QUIZ_EMAIL_SUBMITTED,
          emailSubmittedWithMarketingOptOut: GOALS.QUIZ_EMAIL_SUBMITTED_WITH_MARKETING_OPT_OUT,
          emailSubmittedWithMarketingConsent: GOALS.QUIZ_EMAIL_SUBMITTED_WITH_MARKETING_CONSENT,
        },
      },
    },
    {
      path: '/v5/signup-confirmation',
      type: 'InfoScreen',
      hideProgressBar: true,
      showWhen: { available_in_location: 'false' },
      useAltProps: { quiz_country: 'US' },
      props: {
        title: 'We’ll be in touch!',
        description: 'As soon as ZOE is available in other locations we’ll send you an e-mail',
        hideContinueButton: true,
        showHomePageLink: true,
        topImage: '/img/quiz/location-explanation.png',
      },
      altProps: {
        title: 'You’ve made it on the waitlist!',
        description:
          'As soon as ZOE is available in New York, we’ll send you an email to let you know before we go public.{br}{br}In the meantime, why not download our free {gutGuideLink}.',
        hideContinueButton: true,
        showHomePageLink: true,
        topImage: '/img/quiz/location-explanation.png',
      },
    },
    {
      path: '/v5/we-can-help',
      type: 'ZoeCanHelpScreen',
      hideProgressBar: true,
      props: {
        title: 'We can help!',
        topImage: '/img/quiz/we-can-help-v5.png',
        description: 'Let’s create your personalized program.',
        buttonText: "Let's go!",
      },
    },
    {
      path: '/v5/science',
      type: 'ProgressScreen',
      hideProgressBar: true,
      props: {
        title: 'Based on what you’ve told us, ZOE can help you',
        titleAboveMedia: 'Understand your body',
        description:
          'Your gut health, blood sugar and blood fat responses to food are unique. So the right foods for you are not the same as for your friends.',
        image: '/img/quiz/food-scores-new.jpg',
        imageAlt: 'Food Scores diagram',
        footnote: 'ZOE’s breakthrough science was published in these leading medical journals in 2020 and 2021.',
        otherImages: [
          '/img/logos/nature-medicine.svg',
          '/img/logos/science-magazine.svg',
          '/img/logos/lancet-magazine.svg',
        ],
        buttonText: 'Get my results',
      },
    },
    {
      path: '/v5/email',
      type: 'EmailScreen',
      props: {
        title: 'Enter your email to get your report and our free health guide',
        buttonText: 'Continue',
        kameleoonGoals: {
          emailSubmitted: GOALS.QUIZ_EMAIL_SUBMITTED,
          emailSubmittedWithMarketingOptOut: GOALS.QUIZ_EMAIL_SUBMITTED_WITH_MARKETING_OPT_OUT,
          emailSubmittedWithMarketingConsent: GOALS.QUIZ_EMAIL_SUBMITTED_WITH_MARKETING_CONSENT,
        },
        footerBody:
          "We'll also send you ongoing science and nutrition emails, news and offers. You can {optOutLink} if you only want the free guide.{br}{br}" +
          'See our <b>{privacyPolicy}</b> to learn how we protect your data under strict European privacy laws.',
        showMarketingConsent: true,
        defaultMarketingOptIn: true,
      },
      altProps: {
        title: 'Enter your email to get your report and our free health guide',
        buttonText: 'Continue',
        kameleoonGoals: {
          emailSubmitted: GOALS.QUIZ_EMAIL_SUBMITTED,
          emailSubmittedWithMarketingOptOut: GOALS.QUIZ_EMAIL_SUBMITTED_WITH_MARKETING_OPT_OUT,
          emailSubmittedWithMarketingConsent: GOALS.QUIZ_EMAIL_SUBMITTED_WITH_MARKETING_CONSENT,
        },
        footerBody:
          'You will also receive ongoing science and nutrition emails, discounts, and offers.{br}{br}' +
          'See our <b>{privacyPolicy}</b> to learn how we protect your data.',
        showMarketingConsent: false,
        defaultMarketingOptIn: true,
        copyright: '©2024 ZOE US Inc.',
      },
      useAltProps: { quiz_country: 'US' },
    },
    {
      path: '/v5/advice-1',
      type: 'Advice1Screen',
      props: {
        buttonText: 'Continue',
      },
    },
    {
      path: '/v5/half-way',
      type: 'InfoScreen',
      props: {
        title: 'Let’s further customize your evaluation!',
        description:
          'ZOE uses Artifical Intelligence to compare your results with thousands of people who are taking part in ZOE’s clinical studies.{br}{br}Every six months these results are updated to take into account the very latest discoveries.',
      },
    },
    {
      path: '/v5/exercise',
      type: 'SingleQuestionScreen',
      props: {
        title: 'Your exercise',
        questionKey: 'exercise',
        description:
          'Exercise changes the metabolism of {gender} in their {ageRange}. How often do you do 20 minutes or more of exercise that raises your heart rate?',
        options: [
          { label: 'Less than once a week', value: 'less_than_once_a_week' },
          { label: '1-4 times per week', value: '1_4_times_a_week' },
          { label: '5 or more times per week', value: '5_or_more_times_a_week' },
        ],
      },
    },
    {
      path: '/v5/menopause',
      type: 'SingleQuestionScreen',
      showWhen: { sex: 'female' },
      props: {
        title: 'Have you gone through menopause (choose only one)?',
        questionKey: 'menopause',
        description:
          'Our researchers have found significant changes in how we metabolize food during and after menopause.',
        options: [
          { label: 'Pre-menopausal (have not started menopause)', value: 'premenopausal' },
          { label: 'Peri-menopausal (going through menopause)', value: 'perimenopausal' },
          {
            label:
              'Post-menopausal (this means that your menstrual periods have stopped for at least one year and have not restarted)',
            value: 'postmenopausal',
          },
          { label: "Don't know", value: 'do_not_know' },
          { label: 'Prefer not to say', value: 'prefer_not_to_say' },
        ],
      },
    },
    {
      path: '/v5/health-conditions',
      type: 'SingleQuestionScreen',
      props: {
        title: 'Health conditions',
        questionKey: 'health_condition',
        description: 'Do you have any of the following?',
        allowMultipleSelection: true,
        options: [
          {
            label: 'Eating disorder (e.g. anorexia nervosa or bulimia nervosa)',
            value: 'eating_disorder',
          },
          {
            label: "Gut disease (e.g. Crohn's disease, ulcerative colitis or celiac disease)",
            value: 'gut_disease',
          },
          {
            label: 'Sensitive gut (e.g. irritable bowel syndrome)',
            value: 'sensitive_gut',
          },
          {
            label:
              'You have had a heart attack (myocardial infarction), stroke/transient ischemic attack (TIA), or major surgery in the last two months.',
            value: 'heart_attack',
          },
          {
            label: 'None of the above',
            value: 'none',
          },
        ],
      },
    },
    {
      type: 'InfoScreen',
      path: '/v5/heart-attack',
      hideProgressBar: true,
      showWhen: { health_condition: 'heart_attack' },
      props: {
        title: 'Unfortunately, ZOE is not able to provide the product to you right now',
        description:
          'If you have had a major surgery in the last two months, or have had a heart attack (myocardial infarction), stroke, or transient ischemic attack, ZOE is not appropriate for you at this time.',
        hideContinueButton: true,
        showHomePageLink: true,
      },
    },
    {
      type: 'InfoScreen',
      path: '/v5/gut-disease',
      hideProgressBar: true,
      showWhen: { health_condition: 'gut_disease' },
      useAltProps: { quiz_country: 'US' },
      props: {
        title: 'Unfortunately, ZOE is not able to provide advice that factors in your nutritional needs yet.',
        description:
          'Our services currently support general wellness and cannot diagnose or treat metabolic, inflammatory or other diseases.{br}{br}' +
          'While we give advice to improve gut health and reduce dietary inflammation which is suitable for most people – following our advice may make your condition worse.{br}{br}' +
          'Some dietary changes we recommend such as increasing fiber and diversifying your plant intake have been shown to trigger adverse responses in people with your condition.{br}{br}' +
          'We hope as our research progresses it will help support more people living with gut conditions in the future.{br}{br}' +
          '<b>If you need help living with your condition, please consult a specialist organization such as:</b>{br}',
        showHomePageLink: true,
        hideContinueButton: true,
        externalLinks: [
          {
            url:
              'https://www.nhs.uk/conditions/inflammatory-bowel-disease/#:~:text=Inflammatory%20bowel%20disease%20(IBD)%20is,the%20colon%20(large%20intestine)',
            text: 'NHS',
          },
          {
            url: 'https://www.crohnsandcolitis.org.uk/',
            text: 'Crohn’s & Colitis UK',
          },
          {
            url: 'https://www.coeliac.org.uk/home/',
            text: 'Coeliac UK',
          },
        ],
      },
      altProps: {
        title: 'Unfortunately, ZOE is not able to provide advice that factors in your nutritional needs yet.',
        description:
          'Our services currently support general wellness and cannot diagnose or treat metabolic, inflammatory or other diseases.{br}{br}' +
          'While we give advice to improve gut health and reduce dietary inflammation which is suitable for most people – following our advice may make your condition worse.{br}{br}' +
          'Some dietary changes we recommend such as increasing fiber and diversifying your plant intake have been shown to trigger adverse responses in people with your condition.{br}{br}' +
          'We hope as our research progresses it will help support more people living with gut conditions in the future.{br}{br}' +
          '<b>If you need help living with your condition, please consult a specialist organization such as:</b>{br}',
        showHomePageLink: true,
        hideContinueButton: true,
        externalLinks: [
          {
            url: 'https://gastro.org/',
            text: 'American Gastroenterological Association',
          },
          {
            url: 'https://www.crohnscolitisfoundation.org/',
            text: 'Crohn’s & Colitis Foundation',
          },
          {
            url: 'https://celiac.org/',
            text: 'Celiac Disease Foundation',
          },
        ],
      },
    },
    {
      type: 'InfoScreen',
      path: '/v5/eating-disorder',
      hideProgressBar: true,
      showWhen: { health_condition: 'eating_disorder' },
      useAltProps: { quiz_country: 'US' },
      props: {
        title: 'Unfortunately, ZOE is not able to provide advice that factors in your nutritional needs yet.',
        description: 'If you need help living with your condition please consult a specialist organization such as:',
        showHomePageLink: true,
        hideContinueButton: true,
        externalLinks: [
          {
            url: 'https://www.nhs.uk/mental-health/feelings-symptoms-behaviours/behaviours/eating-disorders/overview/',
            text: 'NHS',
          },
          {
            url: 'https://www.beateatingdisorders.org.uk/',
            text: 'Beat',
          },
        ],
      },
      altProps: {
        title: 'Unfortunately, ZOE is not able to provide advice that factors in your nutritional needs yet.',
        description: 'If you need help living with your condition please consult a specialist organization such as:',
        showHomePageLink: true,
        hideContinueButton: true,
        externalLinks: [
          {
            url: 'https://www.nationaleatingdisorders.org/',
            text: 'National Eating Disorders Association',
          },
          {
            url: 'https://anad.org/',
            text: 'National Association of Anorexia Nervosa and Associated Disorders',
          },
        ],
      },
    },
    {
      type: 'InfoScreen',
      path: '/v5/sensitive-gut',
      hideProgressBar: true,
      showWhen: { health_condition: 'sensitive_gut' },
      props: {
        title: 'Sensitive gut is an area of focus for ZOE.',
        buttonText: 'Confirm & continue',
        description:
          'The current version of our dietary advice is not adjusted specifically for people living with irritable bowel syndrome (IBS), though we are working on this. {br}{br}"' +
          'We give advice to improve gut health and reduce dietary inflammation which is suitable for most people - but it is possible you may struggle to adapt to a diet with much higher levels of fiber and more plant diversity. {br}{br}' +
          '{hr}{br}<footnote>By clicking "confirm & continue" you confirm you have read the above statement and are happy to continue.</footnote>',
      },
    },
    {
      path: '/v5/diabetes',
      type: 'MultipleQuestionsScreen',
      props: {
        title: 'Diabetes',
        dividerSectionText:
          'Our research shows we all have very different blood sugar control. Diabetes affects this. Blood sugar spikes can lead to increased hunger and dietary inflammation.',
        questions: [
          {
            question: 'Has a doctor ever told you that you had diabetes or pre-diabetes?',
            questionKey: 'diabetes_or_prediabetes',
            options: [
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ],
          },
          {
            question: 'Have any of your parents or siblings had diabetes?',
            questionKey: 'diabetes_in_family',
            options: [
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ],
          },
        ],
      },
    },
    {
      path: '/v5/cardiovascular',
      type: 'MultipleQuestionsScreen',
      props: {
        title: 'Cardiovascular health',
        dividerSectionText:
          'Our data shows we all metabolize fats very differently. Your body may struggle with high levels of blood fat if you have cardiovascular health issues.',
        questions: [
          {
            question:
              'Have you ever been diagnosed with cardiovascular disease (e.g. heart attack, heart disease or stroke) or a high level of cholesterol?',
            questionKey: 'cardiovascular_disease_diagnosed_or_high_cholesterol',
            options: [
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ],
          },
          {
            question: 'Have any of your parents or siblings had cardiovascular disease?',
            questionKey: 'cardiovascular_disease_in_family',
            options: [
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ],
          },
        ],
      },
    },
    {
      path: '/v5/blood-pressure',
      type: 'MultipleQuestionsScreen',
      props: {
        title: 'Blood pressure',
        dividerSectionText:
          'Blood pressure affects your cardiovascular risks. Your body may struggle with high levels of blood fat if you have cardiovascular health issues.',
        questions: [
          {
            question: 'Has a doctor ever told you that you have high blood pressure?',
            questionKey: 'blood_pressure_diagnosed',
            options: [
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ],
          },
          {
            question: 'Do you take prescribed medication to treat high blood pressure?',
            questionKey: 'blood_pressure_medication',
            options: [
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ],
          },
        ],
      },
    },
    {
      path: '/v5/health-summary',
      type: 'HealthSummaryScreen',
      hideProgressBar: true,
      showWhen: { has_blood_sugar_or_blood_fast_risk: 'true' },
      props: {
        buttonText: 'Next',
        image: '/img/quiz/dietary-inflammation-3b.png',
        descriptionAbovePrediction: 'Comparing your answers so far with our clinical studies, we predict:',
        descriptionAboveImage:
          'Repeated rises can cause dietary inflammation. Eventually this may lead to negative health outcomes, from diabetes and heart disease to weight gain.',
        descriptionBelowImage:
          "These are just predictions. To know your blood sugar and blood fat responses for sure, <b>you would have to do ZOE's at-home test.</b>",
        bloodSugarAndFatRiskSummary: {
          text: 'Your blood sugar and blood fat may rise more strongly after meals than average.',
          images: [
            { url: '/img/quiz/blood-sugar-3b.png', alt: 'Blood sugar and blood fat icons' },
            { url: '/img/quiz/blood-fat-3b.png', alt: 'Blood sugar and blood fat icons' },
          ],
        },
        diabetesRiskSummary: {
          text: 'Your blood sugar may rise more strongly after meals than average.',
          images: [{ url: '/img/quiz/blood-sugar-3b.png', alt: 'Blood sugar icon' }],
        },
        bloodFatRiskSummary: {
          text: 'Your blood fat may rise more strongly after meals than average.',
          images: [{ url: '/img/quiz/blood-fat-3b.png', alt: 'Blood fat icon' }],
        },
      },
    },
    {
      path: '/v5/length-of-time',
      type: 'SingleQuestionScreen',
      props: {
        title: 'How long have you known about ZOE?',
        questionKey: 'length_of_time',
        options: [
          { label: 'Less than 1 month', value: 'less_than_one_month' },
          { label: '1-2 months', value: 'one_to_two_months' },
          { label: '3-6 months', value: 'three_to_six_months' },
          { label: '7-12 months', value: 'seven_to_twelve_months' },
          { label: 'More than 1 year', value: 'more_than_one_year' },
        ],
      },
    },
    {
      path: '/v5/hear-about-us',
      type: 'SingleQuestionScreen',
      props: {
        title: 'Where did you first hear about ZOE?',
        questionKey: 'hear_about_us',
        order: 'random',
        options: [
          { label: 'Online ad (e.g. TikTok, Instagram)', value: 'online' },
          { label: 'From an influencer / celebrity', value: 'influencer' },
          { label: 'ZOE Health Study (formerly ZOE COVID study)', value: 'zoe_study' },
          { label: 'News or magazine article', value: 'publication' },
          { label: 'Search engine (e.g. Google)', value: 'search_engine' },
          { label: 'TV / Radio', value: 'tv_radio' },
          {
            label: 'M&S & ZOE Gut Shot Partnership',
            value: 'ms_gut_shot_partnership',
            showWhen: { quiz_country: 'GB' },
          },
          { label: 'Friend / family referral', value: 'referral' },
          { label: 'Daily30+', value: 'daily_30' },
          { label: 'Waitrose', value: 'waitrose' },
          { label: 'Food for Life Cookbook', value: 'food_for_life_cookbook' },
          { fixed: true, label: "ZOE's podcast", value: 'zoe_podcast' },
          { fixed: true, label: 'Other podcasts', value: 'podcast' },
          { fixed: true, label: 'Other', value: 'other' },
        ],
      },
    },
    {
      path: '/v5/hear-about-us-influencer',
      type: 'TextInputScreen',
      showWhen: { hear_about_us: 'influencer' },
      props: {
        title: 'From which influencer / celebrity did you first hear about ZOE?',
        questionKey: 'hear_about_us_influencer',
        placeholder: 'Influencer name',
      },
    },
    {
      path: '/v5/hear-about-us-other',
      type: 'TextInputScreen',
      showWhen: { hear_about_us: 'other' },
      props: {
        title: 'Please describe how you first heard about ZOE',
        questionKey: 'hear_about_us_other',
        placeholder: 'Description',
      },
    },
    {
      path: '/v5/hear-about-us-podcast',
      type: 'TextInputScreen',
      showWhen: { hear_about_us: 'podcast' },
      props: {
        title: 'In which podcast did you first hear about ZOE?',
        questionKey: 'hear_about_us_podcast',
        placeholder: 'Podcast name',
      },
    },
    {
      path: '/v5/hear-about-us-publication',
      type: 'TextInputScreen',
      showWhen: { hear_about_us: 'publication' },
      props: {
        title: 'In which publication did you first hear about ZOE?',
        questionKey: 'hear_about_us_publication',
        placeholder: 'Publication name',
      },
    },
    {
      path: '/v5/hear-about-us-online',
      type: 'SingleQuestionScreen',
      showWhen: { hear_about_us: 'online' },
      props: {
        title: 'Where did you see the advert?',
        questionKey: 'hear_about_us_online',
        order: 'random',
        options: [
          { label: 'YouTube', value: 'youtube' },
          { label: 'Facebook', value: 'facebook' },
          { label: 'TikTok', value: 'tiktok' },
          { label: 'Instagram', value: 'instagram' },
          { fixed: true, label: 'Other', value: 'other' },
        ],
      },
    },
    {
      path: '/v5/evaluating',
      type: 'VideoScreen',
      hideProgressBar: true,
      props: {
        loadingMessage: "Building your fully personalized program...{br}It won't take too long!",
        videoVersion: 'kirstinShort',
        buttonText: 'Continue',
      },
    },
    {
      path: '/v5/done',
      type: 'ExitScreen',
      hideProgressBar: true,
      useAltProps: { quiz_country: 'US' },
      props: {
        exitPath: GB_EXIT_PATH,
      },
      altProps: {
        exitPath: US_EXIT_PATH,
      },
    },
  ],
}
export default v5
