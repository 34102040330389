import { QuizScreen, ScreenType } from './config/types'

export enum ActionType {
  ADD = 'add',
  REMOVE = 'remove',
  UPDATE = 'update',
}

export type QuizOverrideEvent =
  | { action: ActionType.ADD; screen: QuizScreen<unknown>; before?: string; after?: string }
  | { action: ActionType.REMOVE; path: string }
  | { action: ActionType.UPDATE; path: string; screen: QuizScreen<unknown> }

export const updateScreens = (source: ScreenType[], overrides: QuizOverrideEvent[]): ScreenType[] => {
  let screens = [...source]
  for (const action of overrides)
    switch (action.action) {
      case ActionType.ADD:
        const referenceIndex = screens.findIndex((screen) => screen.path === (action.before || action.after))
        if (referenceIndex === -1) continue

        const copy: (ScreenType | ScreenType[])[] = [...screens]
        copy[referenceIndex] = action.before
          ? [action.screen as ScreenType, screens[referenceIndex] as ScreenType]
          : [screens[referenceIndex] as ScreenType, action.screen as ScreenType]

        screens = copy.flat()
        continue
      case ActionType.REMOVE:
        screens = screens.filter((screen) => screen.path !== action.path)
        continue
      case ActionType.UPDATE:
        screens = screens.map((screen) => (screen.path === action.path ? (action.screen as ScreenType) : screen))
        continue
    }

  return screens.flat()
}
