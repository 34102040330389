import React from 'react'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { Menopause, Menoscale, Quizzes } from '../../redux/quiz/types'
import { DSButton, DSFlex, DSSpacer, DSText } from '@zoe/ds-web'
import {
  calculateMenoscaleResults,
  getSymptomLabel,
  MenopauseAgeRange,
  MenopauseAverageData,
} from '../../libs/menoscale/utils'
import { LoadingSpinner } from '../core/spinner/LoadingSpinner'
import { MenoscaleScoreBar } from '../core/bars/MenoscaleScoreBar'
import { BigFeatureText } from '../core/text/BigFeatureText'
import { BackgroundCard } from '../core/cards/BackgroundCard'
import { ProgressBar } from '../screen-components/ProgressBar'
import { SupportedCountries } from '../../libs/geoLocation'
import { useSelector } from 'react-redux'
import { selectQuizCountry } from '../../redux/quiz/slice'
import { DSModal } from 'components/core/modal/Modal'
import TagManager from 'react-gtm-module'
import { GTM_ZOE_DATALAYER } from 'appConfig'
import { ctaClickDataLayer } from 'components/gtmV2'

const COLOURS = {
  backgroundPrimary: 'rgba(83, 45, 60, 1)',
  primary: '#FFD100',
  backgroundSecondary: '#F1F7F6',
}

export type MenoScaleResultsScreenProps = {
  title: string
  description: string
}

type Props = MenoScaleResultsScreenProps & {
  onNextClick: onNextClickType
  onBackClick: onBackClickCallback
  quiz: Quizzes
}

type Symptom = {
  name: string
  averageScore: number
  score: number
}
const LegendItem = ({ color, text }: { color: string; text: string }) => (
  <>
    <div style={{ borderRadius: '50%', width: '12px', height: '12px', background: color }} />
    <DSSpacer size={4} direction="horizontal" />
    <DSText variant="fluid-label-100" weight="lotaLight">
      {text}
    </DSText>
  </>
)
const resultMessage = (score: number, menopauseAverageData: MenopauseAverageData | null) => {
  if (menopauseAverageData) {
    const averages = Object.values(menopauseAverageData).filter((average) => average !== null) as number[]
    const lowestAverage = Math.min(...averages)
    const highestAverage = Math.max(...averages)

    // we compute the greatest difference between the user's score and the average
    const diff = Math.max(Math.abs(score - lowestAverage), Math.abs(score - highestAverage))
    console.log({ lowestAverage, highestAverage, averages, diff })
    if (diff < 10) {
      return 'Your score is about average'
    } else if (score < lowestAverage) {
      return 'Your score is lower than average'
    } else if (score > highestAverage) {
      return 'Your score is higher than average'
    } else if (score > lowestAverage && score < highestAverage) {
      return 'Your score is between averages'
    }
  } else {
    return 'Your MenoScale score'
  }
}

const calculatePostScoreMessage = (
  averages: MenopauseAverageData,
  ageRange: MenopauseAgeRange,
  menopause: Menopause,
) => {
  switch (menopause) {
    case 'perimenopausal':
    case 'postmenopausal':
      return `Other ${menopause} women aged ${ageRange} have an average score of ${averages[menopause]}`
    case 'premenopausal':
    case 'do_not_know':
    case 'prefer_not_to_say':
      const perimenopausalMessage = averages.perimenopausal ? `${averages.perimenopausal} if perimenopausal` : null
      const postMenopausalMessage = averages.postmenopausal ? `${averages.postmenopausal} if postmenopausal` : null
      const message = [perimenopausalMessage, postMenopausalMessage].filter((message) => !!message).join(', and ')
      return `Other women aged ${ageRange} had an average score of ${message}`
  }
}

const ResultsCard = ({
  loading,
  score,
  averages,
  ageRange,
  menopause,
}: {
  loading: boolean
  score: number
  averages: MenopauseAverageData | null
  ageRange: MenopauseAgeRange | null
  menopause: Menopause
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  return (
    <BackgroundCard backgroundColor={COLOURS.backgroundPrimary}>
      <DSText variant="fluid-paragraph-200" weight="lotaSemiBold" colour="secondary-inverse">
        Your MenoScale score
      </DSText>
      {loading && <LoadingSpinner color={COLOURS.primary} />}
      {!loading && (
        <>
          <DSSpacer size={8} direction="vertical" />
          <BigFeatureText color={COLOURS.primary}>{score}</BigFeatureText>
        </>
      )}
      <DSSpacer size={24} direction="vertical" />
      <DSText variant="fluid-heading-600" weight="nantes" colour="secondary-inverse">
        {loading ? 'Calculating...' : resultMessage(score, averages)}
      </DSText>
      {!loading && (
        <>
          <DSSpacer size={24} direction="vertical" />
          <MenoscaleScoreBar score={score} averages={averages} menopauseStatus={menopause} />
        </>
      )}
      {!loading && averages && ageRange && (
        <>
          <DSSpacer size={24} direction="vertical" />
          <div className="md:w-3/5">
            <DSSpacer size={24} direction="vertical" />
            <DSText variant="fluid-paragraph-200" weight="lotaLight" colour="secondary-inverse">
              {calculatePostScoreMessage(averages, ageRange, menopause)}
            </DSText>
          </div>
        </>
      )}
      {!loading && (
        <>
          <DSSpacer size={24} direction="vertical" />
          <a
            href=""
            onClick={(evt) => {
              evt.preventDefault()
              setIsModalOpen(true)
            }}
          >
            <DSFlex direction="row" alignItems="center">
              <DSText variant="fluid-paragraph-200" weight="lotaSemiBold" colour="secondary-inverse">
                How is my score calculated?
              </DSText>
              <DSSpacer size={4} direction="horizontal" />

              {/* todo - use DSIcon when fixed */}
              <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.00024 7.87862V7.29641C7.00024 7.23295 7.04702 7.1792 7.10965 7.169C7.28925 7.13972 7.64433 7.07256 7.82183 6.97519C8.33797 6.69156 8.68774 6.15017 8.68774 5.52818C8.68774 4.61264 7.93242 3.87081 7.00024 3.87081C6.06806 3.87081 5.31274 4.61264 5.31274 5.52818M7 12.125C10.1066 12.125 12.625 9.6066 12.625 6.5C12.625 3.3934 10.1066 0.875 7 0.875C3.8934 0.875 1.375 3.3934 1.375 6.5C1.375 9.6066 3.8934 12.125 7 12.125Z"
                  stroke="white"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.0008 8.76379C7.3236 8.76379 7.58558 9.0254 7.58558 9.34858C7.58558 9.67175 7.3236 9.93336 7.0008 9.93336C6.67761 9.93336 6.41602 9.67175 6.41602 9.34858C6.41602 9.0254 6.67761 8.76379 7.0008 8.76379Z"
                  fill="white"
                />
              </svg>
            </DSFlex>
          </a>
          <DSModal variant="primary" isOpen={isModalOpen} onCloseButtonClick={() => setIsModalOpen(false)}>
            <div className="max-w-[90%]">
              <DSText variant="fluid-heading-600" weight="nantes">
                How is my score calculated?!
              </DSText>
            </div>
            <DSSpacer size={8} direction="vertical" />
            <DSText variant="fluid-paragraph-200" weight="lotaLight">
              The MenoScale asks 20 questions about common symptoms of menopause. For each symptom, you will rate how
              much it impacts your life: (0=not at all, 1=a little, 3=quite a bit and 5=extremely). This provides a
              total score out of 100.
            </DSText>
            <DSSpacer size={8} direction="vertical" />
          </DSModal>
          )
        </>
      )}
    </BackgroundCard>
  )
}

const MainSymptomsCard = ({ symptoms, country }: { symptoms: Symptom[]; country?: SupportedCountries }) => (
  <BackgroundCard className="md:bg-surface-teal-subtle" padding="32px">
    <DSSpacer size={12} direction="vertical" />

    <div className="text-left md:text-center">
      <DSText variant="fluid-heading-600">You&rsquo;re not alone</DSText>
      <DSSpacer size={12} direction="vertical" />
      <DSText variant="fluid-paragraph-300" weight="lotaLight">
        Here&apos;s how you compare to women your age
      </DSText>
    </div>
    <DSSpacer size={32} direction="vertical" />

    <div className="bg-surface-teal-subtle py-6 px-4 md:px-0 md:py-0 w-full rounded-lg flex flex-col justify-center items-center ">
      <div className="w-full md:w-1/2 text-left">
        <DSText variant="fluid-paragraph-200" weight="lotaSemiBold">
          Your 3 main symptoms
        </DSText>
      </div>

      <div className="w-full text-left items-center flex md:hidden">
        <LegendItem color={COLOURS.primary} text="You" />
        <DSSpacer size={24} direction="horizontal" />
        <LegendItem color={COLOURS.backgroundPrimary} text="Average for similar women" />
      </div>

      {symptoms.map((symptom) => (
        <div key={symptom.name} className="w-full md:w-1/2 text-left">
          <DSSpacer size={24} direction="vertical" />
          <DSText variant="fluid-heading-500" weight="nantes">
            {getSymptomLabel(symptom.name, country)}
          </DSText>
          <DSSpacer size={16} direction="vertical" />
          <ProgressBar
            size="medium"
            completionPercentage={symptom.score / 5}
            color={COLOURS.primary}
            backgroundColor="hsla(229, 15%, 15%, 0.05)"
          />
          <DSSpacer size={8} direction="vertical" />
          <ProgressBar
            size="medium"
            completionPercentage={symptom.averageScore / 5}
            color={COLOURS.backgroundPrimary}
            backgroundColor="hsla(229, 15%, 15%, 0.05)"
          />
          <DSSpacer size={24} direction="vertical" />
        </div>
      ))}
      <div className="w-full md:w-1/2 text-left items-center hidden md:flex">
        <LegendItem color={COLOURS.primary} text="You" />
        <DSSpacer size={24} direction="horizontal" />
        <LegendItem color={COLOURS.backgroundPrimary} text="Average for similar women" />
      </div>
    </div>
  </BackgroundCard>
)

const WhatsNextSection = () => {
  const breakConfig = {
    maxViewportDirection: 'row',
    breakAt: {
      breakPoint: 'm',
      direction: 'column',
    },
  }
  return (
    <BackgroundCard className="bg-cream-muted md:bg-white" padding="0">
      <DSFlex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        // design system is not recognizing the breakConfig type
        breakConfig={breakConfig as any}
        width="100%"
      >
        <div className="bg-cream-muted w-full overflow-hidden md:aspect-[1.26]" style={{ borderRadius: '24px' }}>
          <img
            src="/img/menoscale/menopause-nutrition-guide.png"
            alt="Menopause nutrition guide"
            className="mt-8 m-auto h-full"
          />
        </div>
        <DSSpacer size={24} direction="horizontal" />
        <div className="w-full text-center md:text-left p-8">
          <DSSpacer size={24} direction="vertical" />
          <DSText variant="fluid-heading-600" weight="nantes">
            What&rsquo;s next?
          </DSText>
          <DSSpacer size={12} direction="vertical" />
          <DSText variant="fluid-paragraph-300" weight="lotaLight">
            Thrive at every stage of life.
          </DSText>
          <DSText variant="fluid-paragraph-300" weight="lotaLight">
            Look out for our free Menopause Nutrition Guide in your inbox.
          </DSText>
        </div>
      </DSFlex>
    </BackgroundCard>
  )
}
const CTASection = () => {
  const breakConfig = {
    maxViewportDirection: 'row',
    breakAt: {
      breakPoint: 'm',
      direction: 'column-reverse',
    },
  }

  const handleCTAClick = () => {
    TagManager.dataLayer({
      dataLayer: ctaClickDataLayer('menoscale'),
      dataLayerName: GTM_ZOE_DATALAYER,
    })
    window.location.href = 'https://zoe.com/quiz'
  }

  return (
    <BackgroundCard backgroundColor="#fff" padding="16px">
      <DSFlex
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        // design system is not recognizing the breakConfig type
        breakConfig={breakConfig as any}
        width="100%"
      >
        <div className="w-full lg:w-1/2 p-8 text-center md:text-left">
          <DSSpacer size={24} direction="vertical" />
          <DSText variant="fluid-heading-600" weight="nantes">
            Curious for more?
          </DSText>
          <DSSpacer size={12} direction="vertical" />
          <DSText variant="fluid-paragraph-300" weight="lotaLight">
            ZOE Membership is a personalized nutrition program that helps you make smarter food choices.
          </DSText>
          <DSSpacer size={24} direction="vertical" />
          <DSButton colourScheme="default" onPress={handleCTAClick} size="large" variant="secondary" width="auto">
            Become a ZOE member
          </DSButton>
        </div>
        <div className="w-full md:w-1/2 overflow-hidden" style={{ borderRadius: '24px' }}>
          <img
            src="/img/menoscale/women-eating-outdoors.png"
            alt="Women eating outdoors"
            className="rounded-2xl shadow-blog-card w-full"
          />
        </div>
      </DSFlex>
    </BackgroundCard>
  )
}

export const MenoScaleResultsScreen: React.FC<Props> = (props: Props) => {
  const menoscale = props.quiz as Menoscale
  const [loading, setLoading] = React.useState(true)
  const results = calculateMenoscaleResults(menoscale)
  const quizCountry = useSelector(selectQuizCountry)
  React.useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 3500)
  }, [])

  return (
    <main className="flex flex-col justify-center items-center">
      <ResultsCard
        loading={loading}
        score={results.score}
        averages={results.averages}
        menopause={results.menopauseStatus}
        ageRange={results.ageRange}
      />
      <DSSpacer size={72} direction="vertical" />
      {!loading && results.mainSymptoms.length > 0 && (
        <MainSymptomsCard symptoms={results.mainSymptoms} country={quizCountry} />
      )}
      <DSSpacer size={72} direction="vertical" />
      {!loading && <WhatsNextSection />}
      <DSSpacer size={72} direction="vertical" />
      {!loading && <CTASection />}
      <DSSpacer size={72} direction="vertical" />
    </main>
  )
}
