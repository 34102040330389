import React, { useMemo } from 'react'
import { BeWeightHeading } from './BeWeightHeading'
import { Quizzes } from 'redux/quiz/types'
import { getWeightGoal } from 'libs/weight/weightCalculations'
import { addDays } from 'date-fns'
import { ADVICE2_2X_RISK_DAYS_OFFSET, ADVICE3_DEFAULT_DAYS_OFFSET_FROM_ADVICE2 } from 'components/constants'
import { FormattedMessage } from 'react-intl'

export interface WeightByDateHeadingProps {
  quiz: Quizzes
  daysOffset: number
  startDateId?: string
}

// TODO: Is any the right return type?
export const WeightByDateHeading: React.FC<WeightByDateHeadingProps> = ({ quiz, daysOffset, startDateId }): any => {
  const weightGoal = useMemo(() => quiz && getWeightGoal(quiz, daysOffset), [quiz])
  const weightPlanType = useMemo(() => weightGoal?.type || 'weight_loss', [weightGoal])

  const daysOffsetForStartDate =
    daysOffset > ADVICE2_2X_RISK_DAYS_OFFSET ? ADVICE3_DEFAULT_DAYS_OFFSET_FROM_ADVICE2 : daysOffset

  const programStartDateId = startDateId || 'quiz.advice.program_start_date'

  const beWeightHeading = useMemo(
    () =>
      weightGoal && (
        <>
          <BeWeightHeading
            weight={weightPlanType === 'weight_loss' ? weightGoal.endWeight : undefined}
            startDate={addDays(weightGoal.endDate, daysOffsetForStartDate)}
            endDate={weightGoal.endDate}
          />
          <div className="mt-4 text-center light text-zoe-secondary text-footnote">
            <FormattedMessage
              id={programStartDateId}
              values={{
                startDate: new Intl.DateTimeFormat('en', {
                  day: 'numeric',
                  month: 'short',
                }).format(weightGoal.startDate),
              }}
            />
          </div>
        </>
      ),
    [weightPlanType, weightGoal],
  )

  return beWeightHeading
}
