import { Height, Weight, WeightUnit } from './types'

const BMI_CONVERSION_FACTOR = 703
export const LOWER_HEALTHY_BMI = 18
export const MIN_TEST_BMI = 16.5

const heightInches = (height: Height): number => {
  if (height.unit === 'ft') {
    return height.value.feet * 12 + height.value.inches
  } else {
    return height.value * 0.393701
  }
}

const heightM = (height: Height): number => {
  if (height.unit === 'ft') {
    return height.value.feet * 0.3048 + height.value.inches * 0.0254
  } else {
    return height.value * 0.01
  }
}

export const lowerHealthyBMIWeightForHeight = (
  height: Height,
  weightUnit: WeightUnit,
  minBMI: number = LOWER_HEALTHY_BMI,
): Weight => ({
  value:
    weightUnit === 'lbs'
      ? (minBMI * Math.pow(heightInches(height), 2)) / BMI_CONVERSION_FACTOR
      : minBMI * Math.pow(heightM(height), 2),
  unit: weightUnit,
})

export const bmi = (height: Height, weight: Weight): number => {
  if (weight.unit === 'lbs') {
    return (weight.value * BMI_CONVERSION_FACTOR) / Math.pow(heightInches(height), 2)
  } else {
    return weight.value / Math.pow(heightM(height), 2)
  }
}
