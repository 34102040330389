import React from 'react'
import { onNextClickType, ScreenProps } from '../types'
import { Button } from '../core/buttons/Button'
import { appConfig } from '../../appConfig'
import { BackButton } from '../core/buttons/BackButton'

type CTAStyle = 'button' | 'link'

type Link = {
  url: string
  text: string
}

export interface ExplanationTemplateProps extends ScreenProps {
  headerBar?: React.ReactNode
  image?: string
  descriptionBelowImage?: React.ReactNode
  imageAlt?: string
  title: React.ReactNode
  subTitle?: React.ReactNode
  body?: React.ReactNode
  footerTitle?: React.ReactNode
  footerBody?: React.ReactNode
  healthStat?: React.ReactNode
  attribution?: React.ReactNode
  attributionImage?: string
  attributionImageAlt?: string
  showHomepageLink?: boolean
  hideContinueButton?: boolean
  cta?: React.ReactNode
  ctaStyle?: CTAStyle
  externalLinks?: Link[]
}

export const ExplanationTemplate: React.FC<ExplanationTemplateProps> = ({
  headerBar,
  image,
  descriptionBelowImage,
  imageAlt,
  title,
  subTitle,
  body,
  footerTitle,
  footerBody,
  healthStat,
  attribution,
  attributionImage,
  attributionImageAlt,
  cta,
  ctaStyle = 'button',
  showHomepageLink,
  hideContinueButton,
  onNextClick,
  onBackClick,
  externalLinks,
}) => {
  const showFooter = !!(footerBody || footerTitle || attribution || attributionImage)
  return (
    <div>
      {headerBar}
      <div className="flex items-center justify-center align-center">
        {onBackClick && <BackButton onBackClick={onBackClick} />}
      </div>
      <main className="grid-cols-1">
        <div className="flex justify-center">
          <div className="mt-10 mb-2 mx-6 max-w-info-width-mobile sm:max-w-info-width-desktop">
            {title && <h2 className="mb-10 text-4xl font-medium">{title}</h2>}
            {image && <img src={`${image}`} alt={imageAlt} className="block mx-auto" />}
            {descriptionBelowImage && (
              <>
                <span className="block mx-auto text-l light">{descriptionBelowImage}</span>
                <div className="w-100 h-px bg-gray-200 my-10"></div>
              </>
            )}
            {subTitle && <h4 className="text-base-secondary font-light mt-6">{subTitle}</h4>}
            {body && <p className="font-light text-base mt-6">{body}</p>}
            {!showFooter && !healthStat && !hideContinueButton && (
              <ExplanationCTA cta={cta} ctaStyle={ctaStyle} onNextClick={onNextClick} />
            )}
            {externalLinks && (
              <div>
                {externalLinks.map((link) => (
                  <a
                    href={link.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-zoe-primary mt-8 arrow-bullet"
                    key={link.text}
                  >
                    {link.text}
                  </a>
                ))}
                <br />
              </div>
            )}
            {showHomepageLink && (
              <a
                href={`${appConfig.zoeWebsiteBaseUri}`}
                className="mt-4 block text-zoe-primary"
                data-testid="homepage-link"
              >
                Return to homepage
              </a>
            )}
          </div>
        </div>
        {showFooter && (
          <div className="flex justify-center">
            <div className="mx-6 max-w-info-width-mobile sm:max-w-info-width-desktop" style={{ width: '468px' }}>
              {footerTitle && <h2 className="text-2xl mb-4 font-medium">{footerTitle}</h2>}
              {footerBody && <h4 className="text-base font-light">{footerBody}</h4>}
              {!hideContinueButton && <ExplanationCTA cta={cta} ctaStyle={ctaStyle} onNextClick={onNextClick} />}
              {attribution && <span className="font-light text-sm mb-4 mt-8 pb-8">{attribution}</span>}
              {attributionImage && (
                <img
                  src={`${appConfig.publicUrl}${attributionImage}`}
                  alt={attributionImageAlt}
                  className="max-w-button-max-width-mobile mx-auto"
                />
              )}
            </div>
          </div>
        )}
        {healthStat && (
          <div style={{ maxWidth: '468px', margin: '0 auto' }}>
            {healthStat}
            {!hideContinueButton && <ExplanationCTA cta={cta} ctaStyle={ctaStyle} onNextClick={onNextClick} />}
          </div>
        )}
      </main>
    </div>
  )
}

type ExplanationScreenCTAProps = {
  cta: React.ReactNode
  ctaStyle: CTAStyle
  onNextClick: onNextClickType
}
const ExplanationCTA: React.FC<ExplanationScreenCTAProps> = ({ cta, ctaStyle, onNextClick }) => {
  return (
    <div className="mt-10 mb-12 max-w-info-width-mobile sm:max-w-info-width-desktop mx-auto">
      {ctaStyle === 'button' ? <Button onClick={onNextClick}>{cta}</Button> : <HomePageLink linkText={cta} />}
    </div>
  )
}

type HomePageLinkProps = {
  linkText: React.ReactNode
}
const HomePageLink: React.FC<HomePageLinkProps> = ({ linkText }) => {
  return (
    <a href={appConfig.zoeWebsiteBaseUri} className="text-zoe-primary" data-testid="homepage-link">
      {linkText}
    </a>
  )
}
