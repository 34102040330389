import React from 'react'
import { appConfig } from '../../../appConfig'

type Props = {
  onBackClick: () => void
}

export const BackButton: React.FC<Props> = React.memo((props) => {
  const { onBackClick, ...rest } = props
  return (
    <div className="flex items-center w-form-width-mobile sm:w-form-width-desktop sm:justify-start mt-4">
      <button
        className="appearance-none flex items-center w-5 h-full border-0 bg-transparent no-underline cursor-pointer focus:outline-none"
        {...rest}
        data-testid="back-icon-button"
      >
        <img src={`${appConfig.publicUrl}/img/icons/back-zoe-primary.svg`} alt="Back" />
      </button>
      <p className="text-zoe-primary" onClick={onBackClick} style={{ cursor: 'pointer' }}>
        Back
      </p>
    </div>
  )
})
