import React from 'react'
import { Quizzes } from 'redux/quiz/types'
import { AverageWeightLossGraph } from './AverageWeightLossGraph'
import { WeightProgressBar } from './WeightProgessBar'
import { WeightUnitEnum } from 'clients/quiz-service'
import { getWeightLossData } from 'data/getWeightLossData'
import { FormattedMessage } from 'react-intl'

type WeightStatisticCardProps = {
  quiz: Quizzes
}

export const WeightStatisticCard: React.FC<WeightStatisticCardProps> = ({ quiz }) => {
  const weightUnit = quiz.weight_unit
  const startWeight = getWeightFromUnit(weightUnit, quiz.weight_kg, quiz.weight_lbs)
  const idealWeight = getWeightFromUnit(weightUnit, quiz.ideal_weight_kg, quiz.ideal_weight_lbs)
  const weightLossData = getWeightLossData(quiz)

  if (weightLossData) {
    const weightLossPercentage = calculateWeightLossPercentage(
      startWeight,
      idealWeight,
      weightLossData.averageWeightLoss,
    )
    return (
      <>
        <div className="max-w-card-width-mobile sm:max-w-card-width-desktop mx-auto pt-6 px-6 pb-8 bg-white rounded-2xl w-full">
          <div className="py-2 px-6 bg-highlight-surface rounded-full inline-block">
            <p className="text-lg">
              <FormattedMessage id="weight" />
            </p>
          </div>
          <p className="text-xl leading-relaxed font-light mt-6">
            <FormattedMessage id="quiz.advice.weight_loss.statistic.weight.stapline" />
          </p>
          <WeightProgressBar
            startWeight={startWeight}
            idealWeight={idealWeight}
            weightUnit={weightUnit}
            percentageOfGoal={weightLossPercentage}
          />
        </div>
        {weightLossData.weightLossGraph && (
          <div className="-mt-8">
            <AverageWeightLossGraph weightLossData={weightLossData} />
          </div>
        )}
      </>
    )
  }
  return <></>
}

const getWeightFromUnit = (weightUnit: string, kgWeight: number | undefined, lbsWeight: number | undefined) => {
  return weightUnit === WeightUnitEnum.kg ? getWeight(kgWeight) : getWeight(lbsWeight)
}

const getWeight = (weight: number | undefined) => {
  return weight || 0
}

const calculateWeightLossPercentage = (startWeight: number, idealWeight: number, averageWeightLoss: number): number => {
  const weightLoss = startWeight - idealWeight
  const percentageLoss = Math.round((averageWeightLoss / weightLoss) * 100)
  return percentageLoss < 100 ? percentageLoss : 100
}
