import React from 'react'
import { useField, useFormikContext } from 'formik'

export interface RadioButtonProps extends React.ComponentProps<'input'> {
  label?: React.ReactNode
  name: string
  clicked?: (value: any) => void
}

export const RadioButton: React.FC<RadioButtonProps> = ({ clicked, ...props }) => {
  const [field, , helpers] = useField(props)
  const context = useFormikContext()
  const { setValue } = helpers

  const handleChange = async () => {
    setValue(props.value)

    await context.validateForm({
      ...(context.values as any),
      [props.name]: props.value,
    })

    if (clicked) clicked(props.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLLabelElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault()
      handleChange()
    }
  }

  return (
    <div className={`flex-1 highlight-label-selected mb-4 ${props.className ?? ''}`}>
      <input
        type="radio"
        tabIndex={-1}
        {...field}
        {...props}
        checked={field.value === props.value}
        className={`opacity-0 absolute ${!field.value && field.value !== props.value ? 'indeterminate' : ''}`}
        onChange={handleChange}
        data-testid={`${props.name}-${props.value}-btn`}
      />

      <label
        className="block p-4 border-solid border-2 border-background-secondary bg-background-secondary hover:border-zoe-primary focus:border-zoe-primary"
        htmlFor={props.id || `${props.name}-${props.value}`}
        onClick={handleChange}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        data-testid={`${props.name}-${props.value}-label`}
      >
        {props.label || props.value}
      </label>
    </div>
  )
}
