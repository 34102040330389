import React from 'react'
import { useFormikContext } from 'formik'
import { FieldError } from './FieldError'

export interface RadioButtonGroupProps extends React.ComponentProps<'input'> {
  children: React.ReactNode
  name: string
}

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({ ...props }) => {
  const context = useFormikContext()

  return (
    <fieldset id={props.name} className={`-mb-4 ${props.className ?? ''}`} data-testid={`${props.name}-button-group`}>
      {props.children}
      {context.errors && <FieldError>{Object.values(context.errors)}</FieldError>}
    </fieldset>
  )
}
