import { Quizzes, weightGoalAnswers } from '../redux/quiz/types'
import { getWeightGoal } from 'libs/weight/weightCalculations'
import { Answer } from 'redux/quiz/slice'

export const goalAnswersToBeSubmitted = (quiz: Quizzes, daysOffset: number): Answer[] | undefined => {
  const weightGoal = quiz && getWeightGoal(quiz, daysOffset)

  if (weightGoal) {
    return weightGoalAnswers(weightGoal)
  }
}
