import React, { ReactNode } from 'react'

type Props = {
  color: string
  children: ReactNode
}
export const BigFeatureText: React.FC<Props> = (props) => (
  <h1
    style={{
      fontSize: '120px',
      lineHeight: '126px',
      fontWeight: 'normal',
      fontFamily: '"LotaGrotesqueAlt1", sans-serif',
      textAlign: 'center',
      color: props.color,
      margin: '0',
    }}
  >
    {props.children}
  </h1>
)
