import { Button } from 'components/core/buttons/Button'
import { onBackClickCallback, onNextClickType } from 'components/types'
import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import * as Yup from 'yup'
import { ContextAwareText } from '../core/text/ContextAwareText'
import { BackButton } from '../core/buttons/BackButton'
import { DSInput, DSSpacer, DSText } from '@zoe/ds-web'
import { FieldError } from '../core/fields/FieldError'

export type KameleoonEmailGoalsIds = {
  emailSubmitted: number
  emailSubmittedWithMarketingConsent: number
  emailSubmittedWithMarketingOptOut: number
}

export type EmailScreenProps = {
  title: string
  description?: string
  buttonText: string
  optInCopy?: string
  footerBody: string
  crmTag?: string
  topImage?: string
  topImageAlt?: string
  kameleoonGoals: KameleoonEmailGoalsIds
  showMarketingConsent?: boolean
  defaultMarketingOptIn?: boolean
  copyright?: string
}

type Props = EmailScreenProps & {
  onNextClick: onNextClickType
  onBackClick?: onBackClickCallback
}

export const EmailScreenMarketingConsent: React.FC<Props> = ({
  title,
  optInCopy,
  topImage,
  topImageAlt,
  description,
  buttonText,
  footerBody,
  onNextClick,
  onBackClick,
  crmTag,
  copyright,
  showMarketingConsent = true,
  defaultMarketingOptIn,
}) => {
  const [showOptOutBox, setShowOptOutBox] = useState(false)

  const handleOptOutClick = () => {
    setShowOptOutBox(!showOptOutBox)
  }
  const optOutLink = (
    <a className={`opt-out-link ${!showOptOutBox && 'text-zoe-primary'}`} onClick={handleOptOutClick}>
      opt out
    </a>
  )

  return (
    <>
      <div className="flex items-center justify-center align-center">
        {onBackClick && <BackButton onBackClick={onBackClick} />}
      </div>
      <main className="flex flex-row flex-1 w-full justify-center bg-background">
        <div
          className="flex w-form-width-mobile flex-col p-6 sm:w-form-width-desktop sm:justify-start"
          style={{ boxSizing: 'initial' }}
        >
          {topImage && <img src={`${topImage}`} alt={topImageAlt} className="block mx-auto w-full mb-6" />}
          <h2 className="text-left">{title}</h2>
          {description && <p className="light text-base-secondary mt-4 sm:mt-6">{description}</p>}
          <br />
          <Formik
            initialValues={{
              email: '',
              marketingOptIn: defaultMarketingOptIn,
              ...(crmTag && { crmTag }),
            }}
            validationSchema={Yup.object({
              email: Yup.string()
                .email('Please enter a valid email address')
                .required('Please enter a valid email address'),
              marketingOptIn: Yup.bool().required(),
            })}
            onSubmit={(values, { setSubmitting }) => {
              onNextClick(values)
              setSubmitting(true)
            }}
          >
            {({ isSubmitting, isValid, dirty, errors, touched }) => (
              <Form>
                <DSInput name="email" type="email" placeholder="Your email address" />
                {touched.email && errors.email && <FieldError>{errors.email}</FieldError>}
                <DSSpacer size={16} direction="vertical" />
                {optInCopy && (
                  <div className="opt-in-radio-container">
                    <label htmlFor="marketingOptIn" className="font-light">
                      {optInCopy}
                    </label>

                    {showMarketingConsent && (
                      <div role="group" className="opt-in-radio-group">
                        <label className="opt-in-radio-label light">
                          <Field name="marketingOptIn">
                            {({ field, form }: { field: any; form: any }) => (
                              <input
                                {...field}
                                type="radio"
                                value="true"
                                checked={field.value === true}
                                onChange={() => form.setFieldValue('marketingOptIn', true)}
                                className="opt-in-radio-input"
                              />
                            )}
                          </Field>
                          Yes
                        </label>
                        <label className="opt-in-radio-label light">
                          <Field name="marketingOptIn">
                            {({ field, form }: { field: any; form: any }) => (
                              <input
                                {...field}
                                type="radio"
                                value="false"
                                checked={field.value === false}
                                onChange={() => form.setFieldValue('marketingOptIn', false)}
                                className="opt-in-radio-input"
                              />
                            )}
                          </Field>
                          No
                        </label>
                      </div>
                    )}
                  </div>
                )}
                <DSSpacer size={16} direction="vertical" />
                <div className="mb-4mt-10 mb-4 text-center">
                  <Button type="submit" disabled={!(isValid && dirty) || isSubmitting}>
                    {buttonText}
                  </Button>
                </div>

                <p className="light text-base-secondary">
                  <ContextAwareText text={footerBody} additionalTokens={{ optOutLink }} />
                </p>

                {showOptOutBox && (
                  <div className="opt-out-checkbox-container">
                    <Field type="checkbox" name="marketingOptOut" id="marketingOptOut" className="opt-out-checkbox" />
                    <label htmlFor="marketingOptOut"> I don&apos;t want ongoing emails including discounts</label>
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </main>

      {copyright && (
        <footer className="text-center py-4">
          <DSText variant="fluid-label-100">{copyright}</DSText>
        </footer>
      )}
    </>
  )
}
